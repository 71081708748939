import firebase from 'firebase';

export default async ({ callback }) => {
  const defaultFirestore = firebase.app();
  return defaultFirestore
    .firestore()
    .collection('orders')
    .where('status.S3', '==', true)
    .orderBy('created_at', 'desc')
    .limitToLast(20)
    .onSnapshot({}, (snapshot) => callback(snapshot));
};
