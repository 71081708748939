import React from 'react';
import { Field } from 'react-final-form';
import { Box } from '@material-ui/core';
import MainScreen from 'src/layout/Screen/Main';
import ChoiceTemplate from './component';

export default () => (
  <Box flex={0.95} display="flex" height="100%" width="100%">
    <MainScreen
      flex={1}
      boxShadow={null}
      title="Les templates"
      subtitle="Choisissez le modèle de carte qui vous convient."
    >
      <Field
        name="template.name"
        render={({ input }) => (
          <ChoiceTemplate onChange={input.onChange} {...input} />
        )}
      />
    </MainScreen>
  </Box>
);
