/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React from 'react';
import {
  FormWithRedirect, sanitizeEmptyValues, useTranslate,
} from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useFormState, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
  TextAreaInputField,
  NumberInputField,
  ImagesField,
  SelectInputField,
  ArrayProductOffer,
} from 'src/form/components/field';

import { PriceWithoutTax, PriceInEuro } from '../../../products/components/form/PriceWithoutTax'
import ListProduct from 'src/cards/edit/components/form/steps/cardEdit/components/editBoxComponent/commons/product';
import { Number } from 'src/form/components/input/number';
import { productsResource } from 'src/App';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p="0 1em">{children}</Box>}
    </Typography>
  );
}

const options = [
  { value: '0.10', label: 'TVA 10%' },
  { value: '0.20', label: 'TVA 20%' },
  { value: '0.055', label: 'TVA 5.5%' },
];


export default ({ Toolbar, ...props }) => {
  const translate = useTranslate();
  const submit = (values) => {
    props.save(sanitizeEmptyValues(props.record, values));
  };
  const [value, setValue] = React.useState('one');
  const optionsResource = productsResource.map((r) => ({ value: r, label: translate(`pos.menu.product.${r}`) }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormWithRedirect
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      onSubmit={submit}
      key={props.version}
      initialValues={props.initialValues || props.record}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        form,
      }) => (
        <>
          <MainScreen
            data_cy_title="title-create-offer"
            data_cy_subtitle="subtitle-create-offer"
            title="Offre"
            subtitle="Renseignez les informations de votre offre"
            flex={0.95}
          >
            <Box
              display="flex"
              flexDirection="column"
              padding="0 1em"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  value="one"
                  label="Informations générales"
                />
                <Tab
                  value="two"
                  label="Produits de l'offre"
                />
              </Tabs>
              <TabPanel value={value} index="one">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Box flex="0.4">
                    <TextInputField
                      required
                      name="reference"
                      label="Titre"
                      data_cy_input="input-name-offer"
                    />

                    <TextAreaInputField
                      required
                      name="description"
                      label="Description"
                      data_cy_input="input-description-offer"
                    />
                    <NumberInputField
                      required
                      name="price"
                      label="Prix en centimes (TTC )"
                      data_cy_number="input-price-offer"
                    />
                    <PriceInEuro />
                    <SelectInputField
                      data_cy_select="select-tva-offer"
                      label="TVA"
                      name="tva"
                      options={options}
                      required
                    />
                    <PriceWithoutTax />
                  </Box>
                  <Box flex="0.4">
                    <ImagesField
                      type="rectangle"
                      name="picture"
                      title="Image du produit"
                      subtitle="Sélectionnez une image pour votre produit"
                      onAccept={(picture) => form.mutators.setValue('picture', picture)}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index="two">
                <Box
                  flex="1"
                >
                  <ArrayProductOffer
                    name="settingFields"
                    label="Ajouter les categories de produit"
                  />
                </Box>
              </TabPanel>
            </Box>
          </MainScreen>
          <Box
            bgcolor="primary.main"
            flex={0.05}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar
              resource={props.resource}
              id={props.id}
              basePath={props.basePath}
            />
          </Box>
        </>
      )}
    />
  );
};