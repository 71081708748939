import React from 'react';
import styled from 'styled-components';
import ListProduct from '../commons/product';

const EditWindow = () => (
  <FlexContainer>
    <ListProduct
      name="productId"
      title="Configurer votre produits"
      subtitle="Sélectionnez le produit que vous souhaitez afficher dans votre composant"
    />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
