import React from 'react';
import { Layout, Sidebar, usePermissions } from 'react-admin';
import { get } from 'lodash';
import { createMuiTheme } from '@material-ui/core/styles';
import { ADMIN } from 'src/helpers/role';
import AppBar from './AppBar';
import Menu from './SideBar/Menu';

// 0171b29
// 01f263e

const theme: any = createMuiTheme;
const myTheme = (role) => theme({
  palette: {
    primary: {
      main: '#222428',
      mainLight: '#AAACB3',
      light: '#F2F2F2',
    },
    primaryLight: {
      main: '#AAACB3',
    },
    error: {
      main: '#f44336',
      secondary: '#ff1100',
    },
    success: {
      main: '#5bfcc2',
    },
    secondary: {
      main: '#5bfcc2',
      light: '#F2F2F2',
    },
    terciary: {
      main: '#f44336',
      light: '#f44336',
    },
    borderRadius: '5px',
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    '@global': {
      '.MuiBox-root': {
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '0px',
        marginRight: '0px',
      },
    },
    MuiFab: {
      root: {
        height: '30px',
        width: '35px',
      },
    },
    MuiSpeedDial: {
      fab: {
        background: '#222428 !important',
        color: '#5bfcc2 !important',
      },
    },
    RaCreate: {
      main: {
        width: '100% !important',
      },
    },
    RaLayout: {
      appFrame: {
        marginTop: `${role === ADMIN ? '48px !important' : '0 !important'}`,
      },
      content: {
        padding: `${role !== ADMIN ? '0px !important' : '24px !important'}`,
        paddingTop: '0px !important',
      },
    },
    RaLogout: {
      icon: {
        color: '#222428',
      },
    },
    RaEdit: {
      noActions: {
        marginTop: '0 !important',
      },
    },
    RaMenuItemLink: {
      root: {
        color: 'white',
        paddingTop: '.5em',
        paddingBottom: '.5em',
      },
      active: {
        color: 'white',
        // background: '#AAACB3',
      },
      icon: {
        color: '#5bfcc2',
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: '#222428',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: 'white',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'white',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          background: '#AAACB3 !important',
        },
      },
    },
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        color: 'rgba(173, 173, 173, 0.87)',
      },
    },
  },
});

export const AuthorizeComponent = ({ role, children }) => {
  const claims = usePermissions();
  if (get(claims, 'permissions.role') === role) {
    return children;
  }
  return null;
};

const AdminSidebar = (props: any) => (
  <AuthorizeComponent role={ADMIN}>
    <Sidebar {...props} style={{ height: '100vh', zIndex: 1000, overflow: 'scroll' }} size={200} />
  </AuthorizeComponent>
);
const AdminMenu = (props: any) => (
  <AuthorizeComponent role={ADMIN}>
    <Menu {...props} />
  </AuthorizeComponent>
);
const AdminAppBar = (props: any) => (
  <AuthorizeComponent role={ADMIN}>
    <AppBar {...props} />
  </AuthorizeComponent>
);


export default (props: any) => {
  const claims = usePermissions();
  const role = get(claims, 'permissions.role');
  return (
    <Layout
      {...props}
      theme={myTheme(role)}
      appBar={AdminAppBar}
      sidebar={AdminSidebar}
      menu={AdminMenu}
    />
  );
};
