import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const simpleList = {
  icone: '/img/components/subcategory.svg',
  explain: '/img/components/explain/subcategory.svg',
  description:
    'Ce composant permet de faire une selection de produits en forme de liste',
  nameId: 'Liste de produits',
  init: { name: '', products: [] },
  type: 'simpleList',
  renderEdit,
};

export default simpleList;
