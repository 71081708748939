import React, { useContext } from 'react';
import { get } from 'lodash';
import {
  IonFooter, IonToolbar, IonButton, IonText, IonRow,
} from '@ionic/react';
import styled from 'styled-components';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

const ButtonWrapper = styled(IonButton)`
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  --box-shadow: none !important;
`;

const ToolbarWrapper = styled(IonToolbar)`
  background-color: ${(props) => props.color};
  height: 6.8vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled(IonFooter)`
  box-shadow: 0px -7px 4px -5px rgba(0,0,0,.15);
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0;
`;

const IonRowWrapper = styled(IonRow)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Footer = () => {
  const {
    theme,
  } = useContext(ThemeContext);
  return (
    <StyledFooter>
      <ToolbarWrapper color={get(theme, 'color.backgroundFooter')}>
        <IonRowWrapper className="ion-justify-content-center ion-align-items-center">
          <ButtonWrapper
            expand="full"
            color={get(theme, 'color.backgroundFooter')}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonText style={{ fontSize: '1em', fontWeight: 'bold' }} color={get(theme, 'color.buttonOrder')}>
                Commander
              </IonText>
            </div>
          </ButtonWrapper>
        </IonRowWrapper>
      </ToolbarWrapper>
    </StyledFooter>
  );
};

export default Footer;
