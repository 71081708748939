/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import Product from '@bit/kards.kards-components.product';

import { ThemeContext } from 'src/cards/edit/components/context/theme';
import { usePopulateProduct } from 'src/data/hooks';

export default ({
  data: {
    id,
    value: { productId },
  },
}) => {
  const {
    theme,
  } = useContext(ThemeContext);
  const { data, error } = usePopulateProduct({ id, product: productId });
  if (!productId || Object.keys(data).length === 0) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  return (
    <Product.component
      theme={theme}
      key={id}
      name={data.reference}
      picture={data.picture}
      description={data.description}
      price={data.price}
    />
  );
};
