import React, { useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default ({ setUploadData }) => {
  const classes = useStyles();
  const formState = useFormState();
  useEffect(() => {
    const original = get(formState.values, 'edit.original');
    setUploadData({ image: original, name: 'files[0].name' });
    // eslint-disable-next-line
  }, []);

  const onChangeImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setUploadData({ image: reader.result, name: files[0].name });
    };
    if (files[0] && typeof files[0].name === 'string') reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component="label"
        className={classes.button}
        data-cy="btn-upload-file"
      >
        Charger une nouvelle image
        <input
          type="file"
          data-cy="file-input"
          style={{ display: 'none' }}
          onChange={onChangeImage}
        />
      </Button>
    </div>
  );
};
