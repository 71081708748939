/* eslint-disable max-len */
import React from 'react';
import { Filter, List, CreateButton } from 'react-admin';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import uuid from 'uuid/v4';
import Toolbar from '@material-ui/core/Toolbar';
import ProductIcon from '@material-ui/icons/Collections';
import { RowOffer } from 'src/layout/List/RowOffer';
import { LoadingRowList } from 'src/layout/List/Loading';
import SearchInput from 'src/layout/Search/Bar';
import EmptyComponent from 'src/layout/Empty';

const useStyles: any = makeStyles(() => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
}));

const OfferTitle = () => <span>Formules</span>;

export const OfferFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="reference" alwaysOn />
    </Filter>
  );
};

export const Action = ({ basePath }) => (
  <Toolbar>
    <CreateButton basePath={basePath} data-cy="create-offer" />
  </Toolbar>
);

export const Empty = ({ basePath, resource, location }) => (
  <EmptyComponent
    basePath={basePath}
    resource={resource}
    location={location}
    titleText="Vous n'avez pas encore créé d'offre"
    explainText="Créer votre premiere offre."
    Image={ProductIcon}
  />
);

const duplicateData = (data) => ({
  createdate: data.createdate,
  createdby: data.createdby,
  description: data.description,
  id: uuid(),
  instant: data.instant,
  lastupdate: data.lastupdate,
  picture: data.picture ? data.picture : null,
  price: data.price,
  reference: `${data.reference} Copie`,
  settingFields: data.settingFields ? data.settingFields : null,
  tva: data.tva,
  updatedby: data.updatedby,
});

const FlexH = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  max-height: 75vh;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7em;
  width: 100%;
`;

const GridList = (props) => {
  const {
    data, basePath, ids, resource,
  } = props;

  return (
    <FlexH data-cy="list-offer">
      {ids
        && ids.length > 0
        ? ids.map((id) => (
          <RowOffer
            key={id}
            data={data[id]}
            basePath={basePath}
            duplicateData={() => duplicateData(data[id])}
            resource={resource}
          />
        ))
        : (
          <NoData>
            Aucun produit
          </NoData>
        )}
    </FlexH>
  );
};

const OfferLoadableList = ({ loading, ...props }) => (!loading ? <GridList {...props} /> : <LoadingRowList {...props} />);

const OfferList = (props) => (
  <List
    filters={<OfferFilter />}
    actions={<Action {...props} />}
    empty={<Empty {...props} />}
    title={<OfferTitle />}
    perPage={25}
    sort={{ field: 'reference', order: 'ASC' }}
    {...props}
  >
    <OfferLoadableList {...props} />
  </List>
);

export default OfferList;
