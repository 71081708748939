
import firebase from 'firebase';
import get from 'lodash/get';
import map from 'lodash/map';
import { parseFireStoreDocument } from '../../helpers/function';

export const getOneTable = async (id, setSearch) => {
  const defaultFirestore = firebase.app().firestore();
  defaultFirestore
    .collection('orders')
    .where('parameters.delivery', '==', 'onplace')
    .where('parameters.onplace_number', '==', id)
    .get()
    .then((res) => {
      const result = [];
      if (get(res, 'docs') && get(res, 'docs').length > 0) {
        map(get(res, 'docs'), (doc) => {
          const docParsed = parseFireStoreDocument(doc);
          if (docParsed) result.push(docParsed);
        });
      }
      setSearch(result);
    })
    .catch((error) => {
      console.log('error', error);
    });
};
