import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';

import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { getBox } from './getBox';

import Form from './Form';

const HeaderEdit = styled.div`
  text-align: center;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
`;
const TextHeaderEdit = styled.p`
  font-weight: 400;
`;

export default ({ item }) => {
  const {
    action: { resetCurrentItem, updateComponent },
  } = useContext(ContributionContext);
  const EditBox = getBox(item.type);
  const updateItems = (value) => {
    updateComponent(value, item.id);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <HeaderEdit>
          <TextHeaderEdit>Édition</TextHeaderEdit>
        </HeaderEdit>
      </AppBar>
      <Form
        close={resetCurrentItem}
        onChange={updateItems}
        value={item.value}
        key={item.id}
        id={item.id}
      >
        <EditBox id={item.id} />
      </Form>
    </>
  );
};
