import React from 'react';
import EditButton from 'src/data/injector/EditButtonApplication';

export default function ({ resource }) {
  return (
    <>
      <div />
      <EditButton resource={resource} modal id="pwa" />
      <div />
    </>
  );
}
