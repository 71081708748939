import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

export function TabPanel({
  children, value, index, ...rest
}: any) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...rest}
    >
      {value === index && <Box p="0 1em">{children}</Box>}
    </Typography>
  );
}
