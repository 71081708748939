import React, { useState, useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import { Box } from '@material-ui/core';
import Cropper from 'react-cropper';

export default ({
  type,
  value,
  onChange,
  original,
}) => {
  const [uploadData] = useState(original);
  const cropper = useRef(null);

  const getAspectRation = (type) => {
    switch (type) {
      case 'original':
        return 'free';
      case 'card':
        return 16 / 9;
      case 'category':
        return 1 / 1;
      case 'screenOff':
        return 2 / 3;
      case 'categoryInline':
        return 16 / 5;
      default:
        break;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',

        }}
      >
        <div style={{ flex: 0.5, margin: '0 1em' }}>
          <br />
          <Cropper
            style={{ height: '50vh', width: '50vh' }}
            aspectRatio={getAspectRation(type)}
            initialAspectRatio="16/9"
            viewMode={2}
            preview=".img-preview"
            autoCrop
            ready={() => {
              if (value) {
                cropper.current.setData(value);
              }
            }}
            cropend={() => {
              const cropData = cropper.current.getData();
              onChange(cropData);
            }}
            guides={false}
            checkCrossOrigin={false}
            src={uploadData}
            ref={cropper}
          />
        </div>
        <Box style={{ flex: 0.5, flexDirection: 'column', margin: '0 1em' }}>
          <div className="box" style={{ overflow: 'hidden' }}>
            <div
              className="img-preview"
              style={
            type === 'card'
              ? { width: '400px', height: '500px' }
              : { width: '200px', height: '200px' }
          }
            />
          </div>
        </Box>
      </div>
    </div>

  );
};
