import firebase from 'firebase';
import get from 'lodash/get';
import { configuration } from '../../App';

export default async ({
  cardId,
}) => {
  const { currentUser } = firebase.auth();
  const idToken = currentUser && await currentUser.getIdToken();
  const secureHeader = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
  });
  const requestConfig: any = {
    method: 'POST',
    headers: secureHeader,
    body: JSON.stringify({
      data: {
        cardId,
      },
    }),
    mode: 'cors',
  };

  const request = new Request(
    `${configuration.cloudFunction}publishCard`,
    requestConfig,
  );
  fetch(request).then((response) => {
    response.json().then((res) => {
      const result = get(res, 'result');
      if (result && result.error) {
        throw result.error;
      } else {
        return result;
      }
    });
  });
};
