import React from 'react';
import styled from 'styled-components';
import { useLogout } from 'react-admin';
import { power } from 'ionicons/icons';
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  IonButton,
  IonButtons,
  IonTitle,
  IonIcon,
} from '@ionic/react';
import RunnerView from 'src/data/injector/Runner';
import LoadingList from 'src/layout/List/Loading';
import { LogoutButtonConfirme } from 'src/layout/Buttons';
import SearchOrderRunner from '../components/search/SearchOrdersRunner';
import ListOrders from '../components/list/ListOrders';

const IonSegmentButtonWrapper = styled(IonSegmentButton)`
  font-size: 1.1em;
  font-family: sans-serif;
  --color: #5bfcc2 !important;
  --color-checked: black !important;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
`;
const Header = styled.header`
  width: 100%;
  height: 7%;
  background-color: #222428;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  height: 86%;
  background-color: #222428;
`;

const Footer = styled.footer`
  width: 100%;
  height: 7%;
  background-color: #222428;
`;

const IonSegmentWrapper = styled(IonSegment)`
  height: 100%;
`;

const Logout = () => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <LogoutButtonConfirme
      onAccept={handleClick}
      mobile
      OpenButton={(props) => (
        <IonButton
          size="medium"
          color="light"
          mode="ios"
          onClick={props.handleOpen}
        >
          <IonIcon slot="icon-only" icon={power} />
        </IonButton>
      )}
    />
  );
};


const List = (props) => {
  const [index, setIndex] = React.useState('ready');
  const handleChange = (event) => {
    setIndex(event.detail.value);
  };
  return (
    <MainContainer>
      <Header>
        <IonToolbar mode="ios" color="dark">
          <IonTitle>
            <img alt="plus" src="/img/kards/logoWhite.svg" style={{ width: '5em' }} />
          </IonTitle>
          <IonButtons slot="end">
            <Logout />
          </IonButtons>
        </IonToolbar>
      </Header>
      <MainContent>
        {
          index === 'search'
          && <SearchOrderRunner type={index} />
        }
        {
          index === 'ready'
          && (
          <ListWrapper data-cy="list-orders-to-delivery">
            <RunnerView {...props} entitieWatch="S4" numberEntitieWatch={6}>
              <WrapperToDelivery type={index} />
            </RunnerView>
          </ListWrapper>
          )
        }
        {
          index === 'delivered'
          && (
          <ListWrapper data-cy="list-orders-delivered">
            <RunnerView {...props} entitieWatch="S5" numberEntitieWatch={20}>
              <WrapperToDelivery type={index} />
            </RunnerView>
          </ListWrapper>
          )
        }
      </MainContent>
      <Footer>
        <IonSegmentWrapper mode="ios" onIonChange={handleChange} value={index}>
          <IonSegmentButtonWrapper data-cy="btn-tab-search" value="search">
            <IonLabel>Recherche</IonLabel>
          </IonSegmentButtonWrapper>
          <IonSegmentButtonWrapper data-cy="btn-tab-to-delivery" value="ready">
            <IonLabel>En cours</IonLabel>
          </IonSegmentButtonWrapper>
          <IonSegmentButtonWrapper data-cy="btn-tab-delivered" value="delivered">
            <IonLabel>Livré</IonLabel>
          </IonSegmentButtonWrapper>
        </IonSegmentWrapper>
      </Footer>
    </MainContainer>
  );
};

const WrapperToDelivery = ({ data, type }) => <ListOrders data={data} type={type} />;

const OrderLoadableList = ({ loading, ...props }) => (
  <div>{!loading ? <List {...props} /> : <LoadingList {...props} />}</div>
);

const RunnerList = (props) => <OrderLoadableList {...props} />;

export default RunnerList;
