import firebase from 'firebase';

export const orderDelivered = async () => {
  const defaultFirestore = firebase.app();

  const data = await defaultFirestore
    .firestore()
    .collection('orders')
    .orderBy('created_at', 'desc')
    .get();

  return { docs: data.docs };
};

export const newCustomer = async () => {
  const defaultFirestore = firebase.app();
  return defaultFirestore
    .firestore()
    .collection('stripe_customer')
    .orderBy('customerDetails.created', 'desc')
    .get();
};
