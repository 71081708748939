import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => (
  <Edit data={data.value} />
);

const social = {
  icone: '/img/components/social.svg',
  explain: '/img/components/explain/social.png',
  description:
    "Ce composant permet d'ajouter vos réseaux sociaux",
  nameId: 'Social',
  type: 'social',
  renderEdit,
};

export default social;
