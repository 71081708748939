import MenuBookIcon from '@material-ui/icons/MenuBook';
import OfferList from './list';
import OfferEdit from './edit';
import OfferCreate from './create';

export default {
  list: OfferList,
  create: OfferCreate,
  edit: OfferEdit,
  icon: MenuBookIcon,
};
