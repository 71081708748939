/* eslint-disable no-unused-vars */
import React, { createContext } from 'react';

export const ThemeContext = createContext({});

function ThemeProvider({ children, theme }) {
  const value = { theme };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

export default ThemeProvider;
