import React from 'react';
import { CategoryListWithText } from '../../cardComponents/CategoryHeader';
import { Components } from '../../wrapperCardComponents/items';

export default {
  image: '/img/template/TopWithText.png',
  name: 'Vitrine texte',
  id: 'TopWithText',
  imageType: 'square',
  header: {
    title: true,
  },
  structure: () => (
    <>
      <CategoryListWithText />
      <Components />
    </>
  ),
};
