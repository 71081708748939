/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import Cropper from '../../../form/components/input/cropper';
import Modal from './Modal';
import { Tooltip } from '../../Tooltip';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '50%',
    marginBottom: theme.spacing(1),
    fontSize: '0.8em',
  },
}));

const ButtonStyled = styled(Button)`
  font-size: '0.8em';
  cursor: 'pointer';
`;

export const CropImage = ({
  label,
  input: { value, onChange },
  subComponent,
  type,
  upload,
  description,
  name,
}) => {
  const classes = useStyles();
  const formState = useFormState();
  const [open, setOpen] = React.useState(false);
  const [traitedOriginal, setTraitedOriginal] = React.useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const convertFileToBase64 = (file) => new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', file);
      xhr.responseType = 'blob';
      xhr.send();
    });

    const original = get(formState.values, 'edit.original');

    if (original && original.includes('https://')) {
      convertFileToBase64(original).then((base64) => {
        setTraitedOriginal(base64);
      });
    } else {
      setTraitedOriginal(original);
    }
  }, [formState.values]);
  console.log(`btn-image-${type}`);
  return (
    <>
      <Box
        padding="5px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box flex=".3" display="flex" alignItems="center" justifyContent="flex-start">
          <ButtonStyled
            size="small"
            variant="contained"
            color={get(formState.values, name) ? 'primary' : 'secondary'}
            data-cy={`btn-image-${type}`}
            onClick={handleOpen}
          >
            Recadrage
          </ButtonStyled>
          {
            get(formState.values, name)
            && (
              <Box p=".2em">
                <CheckIcon style={{ color: 'green' }} />
              </Box>
            )
          }
        </Box>
        <Box flex=".7" display="flex" alignItems="center" justifyContent="flex-start">
          {label}
          <Tooltip text={description} />
        </Box>
      </Box>
      <Modal
        handleClose={handleClose}
        open={open}
        title="Ajouter une image"
        subtitle="Sélectionnez une image à ajouter à votre galerie d'image"
      >
        {subComponent && subComponent()}
        <Box
          flex={1}
          padding="10px 30px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Cropper
            formState={formState}
            flex={0.8}
            type={type}
            onChange={onChange}
            upload={upload}
            value={value}
            original={traitedOriginal}
          />
          <Box
            flex={0.2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              data-cy="btn-validate-image"
              className={classes.button}
              onClick={handleClose}
            >
              Valider
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
