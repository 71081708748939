import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Box, Switch as MaterialSwitch } from '@material-ui/core';

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
`;
const MaterialSwitchCustom = withStyles({
  switchBase: {
    color: 'rgb(240, 83, 83)',
    '&$checked': {
      color: 'rgb(122, 210, 157)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgb(122, 210, 157)',
    },
  },
  checked: {},
  track: {},
})(MaterialSwitch);

export const Switch = ({ ...props }) => (
  <Box display="flex" flexDirection="column" height="3.5em" padding="0px">
    <Box>
      <MaterialSwitchCustom
        checked={props.input.checked}
        onChange={props.input.onChange}
        data-cy={props.data_cy_switch}
      />
    </Box>
    {props.meta.error && <ErrorField>{props.meta.error}</ErrorField>}
  </Box>
);
