import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data.value} />;

const separator = {
  icone: '/img/components/picture.svg',
  explain: '/img/components/explain/picture.png',
  description:
    "Ce composant permet d'ajouter une image",
  nameId: 'Image',
  type: 'image',
  renderEdit,
};

export default separator;
