import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styled from 'styled-components';
import { get } from 'lodash';

import { EditButtonWithCheck } from 'src/data/injector/CardEditButton';
import { CreateButtonWithCheck } from 'src/data/injector/CardCreateButton';
import { EditLiveButtonWithCheck } from 'src/data/injector/CardEditLiveButton';
import { UnPublishButton } from 'src/data/injector/CardUnPublishButton';
import { TransparentBtn } from 'src/layout/Buttons';

const useStyles: any = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContainerButton = styled.div`
  justify-content: ${(props) => props.justifyContent};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export function Toolbar({ step, setStep, ...props }) {
  const classes = useStyles();
  const formState = useFormState();
  const currentSlots = get(formState, 'values.slots');
  const unpublishCard = currentSlots && currentSlots.length === 0 && props.record.status === 'PUBLISH';
  return (
    <>
      {step === 1 && (
        <>
          <ContainerButton />
          <ContainerButton justifyContent="flex-start">
            <TransparentBtn
              variant="extended"
              color="secondary"
              onClick={() => setStep(step + 1)}
              data-cy="btn-step-edit"
            >
              Carte
              <NavigateNextIcon className={classes.extendedIcon} />
            </TransparentBtn>
          </ContainerButton>
        </>
      )}
      {step === 2 && (
        <>
          <ContainerButton justifyContent="flex-start">
            <TransparentBtn
              variant="extended"
              color="secondary"
              onClick={() => setStep(step - 1)}
              data-cy="btn-step-template"
            >
              <NavigateBeforeIcon className={classes.extendedIcon} />
              Template
            </TransparentBtn>
          </ContainerButton>
          <>
            {props.record.status !== 'PUBLISH' && props.record.id && (
              <EditButtonWithCheck id={props.record.id} {...props} />
            )}
            {props.record.status === 'PUBLISH' && props.record.id && (
              <EditLiveButtonWithCheck {...props} />
            )}
            {!props.record.id && <CreateButtonWithCheck {...props} />}

          </>
          <ContainerButton justifyContent="flex-end">
            <TransparentBtn
              variant="extended"
              color="secondary"
              onClick={() => setStep(step + 1)}
              data-cy="btn-step-parameters"
            >
              Paramètres
              <NavigateNextIcon className={classes.extendedIcon} />
            </TransparentBtn>
          </ContainerButton>
        </>
      )}
      {step === 3 && (
        <>
          <ContainerButton justifyContent="flex-end">
            <TransparentBtn
              variant="extended"
              color="secondary"
              onClick={() => setStep(step - 1)}
              data-cy="btn-step-edit"
            >
              <NavigateBeforeIcon className={classes.extendedIcon} />
              Carte
            </TransparentBtn>
          </ContainerButton>
          {!props.record.id && <CreateButtonWithCheck {...props} />}
          {props.record.id
            && (props.record.status !== 'PUBLISH' ? (
              <>
                <EditButtonWithCheck id={props.record.id} {...props} />
              </>
            ) : unpublishCard ? (
              <UnPublishButton {...props} />
            ) : (
              <EditLiveButtonWithCheck {...props} />
            ))}
          <ContainerButton />
        </>
      )}
    </>
  );
}
