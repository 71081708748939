/* eslint-disable no-undef */

import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const BoxWrapper = styled(Box)`
  border-radius: 5px;
  overflow: scroll;
  :focus {
    outline-width: 0;
  }
`;

const StyledTitle = styled.h2`
  color: #222428;
  font-size: 1.3em;
  margin: 0;
  padding: 10px;
`;

const StyledSubTitle = styled.h2`
  color: #bbbbbb;
  font-size: 1em;
  margin: 0;
  padding-bottom: 10px;
`;

const Modal = ({
  title, subtitle, children, handleClose, open,
}) => (
  <MaterialModal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={open}
    onClose={handleClose}
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    }}
  >
    <BoxWrapper
      width="80vw"
      height="80vh"
      bgcolor="#fff"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flex={0.1}
        padding="10px 10px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {!!title && <StyledTitle>{title}</StyledTitle>}
        {!!subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
      </Box>
      <Box
        display="flex"
        flex={0.9}
        padding="0.5em"
        flexDirection="column"
        overflow="hidden"
      >
        {children}
      </Box>
    </BoxWrapper>
  </MaterialModal>
);

export default Modal;
