import React from 'react';
import { Edit } from 'react-admin';
import { Back } from 'src/layout/Buttons';
import { editToolbar } from './components/bottomBar';
import CardForm from './components/form';

const CardTitle = ({ record = null }) => (
  <span>
    Carte /
    {' '}
    {record.title}
  </span>
);

const CardLayout = (props) => (
  <>
    <Back resource="/cards" />
    <Edit title={<CardTitle />} {...props}>
      <CardForm {...props} Toolbar={editToolbar} initialStep={2} />
    </Edit>
  </>
);

export default CardLayout;
