import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data.value} />;

const separator = {
  icone: '/img/components/youtube.svg',
  explain: '/img/components/explain/youtube.png',
  description:
    "Ce composant permet d'ajouter une vidéo",
  nameId: 'Vidéo',
  type: 'video',
  renderEdit,
};

export default separator;
