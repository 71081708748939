import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: ${(props) => (props.mobile ? '80%' : '40%')};
  border-radius: 5px;
  outline: none;
`;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  success: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '70%',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Confirm = ({
  onClose,
  onAccept,
  children = null,
  OpenButton = null,
  mobile = false,
  textButton,
  openFromParent,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    return setOpen(false);
  };

  const handleAccept = () => {
    if (onAccept) onAccept();
    return setOpen(false);
  };

  return (
    <>
      {OpenButton && React.cloneElement(OpenButton, { handleOpen })}
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEnforceFocus
        open={OpenButton ? open : openFromParent}
        onClose={handleClose}
        className={classes.modal}
      >
        <ModalWrapper mobile={mobile}>
          <Box>
            <img alt="question" src="/img/utils/question.svg" width="80px" />
          </Box>
          <Box
            margin="20px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {children}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleAccept}
              color="secondary"
              className={classes.success}
              data-cy="btn-confirm"
            >
              {textButton}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleClose}
              data-cy="btn-close"
            >
              Fermer
            </Button>
          </Box>
        </ModalWrapper>
      </MaterialModal>
    </>
  );
};
