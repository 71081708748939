import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const carousel = {
  icone: '/img/components/carousel.svg',
  explain: '/img/components/explain/carousel.svg',
  description: "Ce composant permet d'ajouter un carrousel de produits",
  nameId: 'Carrousel de produits',
  type: 'carousel',
  init: { products: [] },
  renderEdit,
};

export default carousel;
