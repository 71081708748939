import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button } from '@material-ui/core';
import { DeleteButton as DeleteButtonConfirme } from 'src/layout/Buttons';
import { ImagesField } from 'src/form/components/field';

const SortableItems = SortableContainer(({ children }) => (
  <ListImageWrapper>
    {children}
  </ListImageWrapper>
));

const OpenBtn = ({ handleOpen }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={handleOpen}
  >
    Selectionner des images
  </Button>
);

const SliderImageItem = SortableHandle(({
  position, onRemove, name,
}) => (
  <Field
    name={name}
    render={({ input }) => (
      <PictureZone image={input.value}>
        <DeleteButtonConfirme
          onAccept={() => onRemove({ position })}
          OpenButton={(props) => <DeleteButton onClick={props.handleOpen} alt="delete" width="10px" src="/img/utils/close.svg" />}
        />
      </PictureZone>
    )}
  />
));

const SortableItem = SortableElement(({
  position, name, itemsLength, onRemove,
}) => (
  <SliderImageItem
    itemsLength={itemsLength}
    name={name}
    onRemove={onRemove}
    position={position}
  />
));

const EditWindow = () => {
  const formState = useFormState();
  const form = useForm();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const array = arrayMove(formState.values.pictures, oldIndex, newIndex);
      form.mutators.setValue('pictures', array);
    }
  };

  const onRemove = ({ position }) => {
    const array = formState.values.pictures;
    array.splice(position, 1);
    form.mutators.setValue('pictures', array);
  };

  return (
    <>
      <FlexContainer>
        <ImagesField
          many
          name="pictures"
          type="rectangle"
          title="Ajouter une image"
          subtitle="Sélectionnez les image que vous souhaitez afficher dans votre slide d'images"
          onAccept={(picture) => form.mutators.setValue('pictures', picture)}
        >
          <OpenBtn />
        </ImagesField>
        <FieldArray name="pictures">
          {({ fields }) => (
            <SortableItems
              useDragHandle
              pressDelay={150}
              helperClass="sortableHelper"
              axis="xy"
              onSortEnd={onSortEnd}
            >
              {fields.map((name, index) => (
                <SortableItem
                  itemsLength={fields.length}
                  index={name}
                  name={name}
                  position={index}
                  key={name}
                  onRemove={onRemove}
                />
              ))}
            </SortableItems>
          )}
        </FieldArray>
      </FlexContainer>
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;

const ListImageWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0;
`;

const DeleteButton = styled.img`
    position: absolute;
    top: -7px;
    right: -7px;
    width: 1.2em;
    height: 1.2em;
    cursor: pointer;
`;

const PictureZone = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: 50%;
  opacity: 1;
  height: 4em;
  width: 4em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .4em;
  position: relative;
  cursor: grab;
  :active {
    cursor: grabbing;
  }
`;
