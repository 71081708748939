import VisitorIcon from '@material-ui/icons/People';

import VisitorList from './list';
import VisitorCreate from './create';

export default {
  list: VisitorList,
  create: VisitorCreate,
  icon: VisitorIcon,
};
