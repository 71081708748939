import React, { useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import watchRunnerOrder from '../accessor/watchRunnerOrders';

const RunnerView = ({ entitieWatch, numberEntitieWatch, children }) => {
  const [data, setData] = useState([]);
  const callbackOrder = (snapshot = null) => {
    snapshot.docChanges().forEach(async (change) => {
      const doc = await change.doc.data();
      if (doc && change.type === 'added') {
        data.push(doc);
        setData([...data]);
      }
      if (doc && change.type === 'removed') {
        const id = findIndex(data, (o) => o.key === doc.key);
        data.splice(id, 1);
        setData([...data]);
      }
      if (doc && change.type === 'modified') {
        const id = findIndex(data, (o) => o.key === doc.key);
        data[id] = doc;
        setData([...data]);
      }
    });
  };

  useEffect(() => {
    const watchProductorOrderFn = watchRunnerOrder({
      entitieWatch,
      numberEntitieWatch,
      callback: callbackOrder,
    });
    return () => watchProductorOrderFn;
    // eslint-disable-next-line
  }, [entitieWatch, numberEntitieWatch]);

  return React.cloneElement(children, { data });
};

export default RunnerView;
