import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import {
  Prompt,
} from 'react-router-dom';
import ChoiceTemplate from './steps/choiceTemplate';
import CardEdit from './steps/cardEdit';
import Parameters from './steps/parameters';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const clear = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};

const setValue = ([name, value], state, { changeValue }) => {
  changeValue(state, name, () => value);
};

export default ({
  basePath,
  record,
  // save,
  Toolbar,
  initialData,
  initialStep,
}) => {
  const [step, setStep] = useState(initialStep);
  const isBlocking = true;

  const submit = () => {
    // save(sanitizeEmptyValues(record, values));
  };

  return (
    <Form
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      onSubmit={submit}
      mutators={{ ...arrayMutators, clear, setValue }}
      initialValues={initialData || record}
      render={() => (
        <form style={{ height: '89vh' }}>
          <Prompt
            when={isBlocking}
            message={() => 'Attention a bien enregistrer vos modifications'}
            // message={(location) => 'Attention a bien enregistrer vos modifications'}
          />
          <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
            <Box flex={0.95} display="flex" height="100%" width="100%" flexDirection="column" alignItems="center">
              {step === 1 && <ChoiceTemplate />}
              {step === 2 && <CardEdit record={record} />}
              {step === 3 && <Parameters record={record} />}
            </Box>
            <Box
              bgcolor="primary.main"
              flex={0.05}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Toolbar
                record={record}
                step={step}
                basePath={basePath}
                setStep={setStep}
              />
            </Box>
          </Box>
        </form>
      )}
    />
  );
};
