export function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    // eslint-disable-next-line
    for (const index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }
    str += `${line}\r\n`;
  }
  return str;
}

export const formatDate = (start, end) => `PERIODE: ${start.toDateString()} (${start.getHours()}H${start.getMinutes()})  ${end.toDateString()} (${end.getHours()}H${end.getMinutes()})`;

export function exportCSVFile(items, fileTitle) {
  const jsonObjectStripe = JSON.stringify(items.stripe);
  const jsonObjectContractors = JSON.stringify(items.contractors);

  const csvStripe = convertToCSV(jsonObjectStripe);
  const csvContractors = convertToCSV(jsonObjectContractors);

  const exportedStripeFilename = `${fileTitle}_stripe.csv` || 'export.csv';
  const blobStripe = new Blob([csvStripe, csvContractors], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blobStripe, exportedStripeFilename);
  } else {
    const linkStripe = document.createElement('a');
    if (linkStripe.download !== undefined) { // feature detection
      const url = URL.createObjectURL(blobStripe);
      linkStripe.setAttribute('href', url);
      linkStripe.setAttribute('download', exportedStripeFilename);
      linkStripe.style.visibility = 'hidden';
      document.body.appendChild(linkStripe);
      linkStripe.click();
      document.body.removeChild(linkStripe);
    }
  }
}
