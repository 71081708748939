
import firebase from 'firebase';
import get from 'lodash/get';
import { parseFireStoreDocument } from 'src/helpers/function';

export const getOneOrder = async (id, setSearch, arrayMode) => {
  const defaultFirestore = firebase.app().firestore();
  defaultFirestore
    .collection('orders')
    .where('key', '>=', id)
    .where('key', '<=', `${id}\uf8ff`)
    .get()
    .then((res) => {
      let doc = null;
      if (get(res, 'docs') && get(res, 'docs').length > 0) {
        doc = parseFireStoreDocument(res.docs[0]);
        // eslint-disable-next-line
        arrayMode ? setSearch([doc]) : setSearch(doc);
      }
    })
    .catch((error) => {
      console.log('error', error);
    });
};
