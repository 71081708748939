/* eslint-disable no-unused-vars */
import React from 'react';
import CreateButton from 'src/data/injector/CreateButton';
import EditButton from 'src/data/injector/EditButton';

export function EditToolbar({ id, resource }) {
  return (
    <>
      <div />
      <EditButton id={id} resource={resource} />
      <div />
    </>
  );
}
export function CreateToolbar({ basePath, resource }) {
  return (
    <>
      <div />
      <CreateButton resource={resource} basePath={basePath} />
      <div />
    </>
  );
}
