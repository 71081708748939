import React from 'react';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const clear = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};

export default ({
  close, onChange, children, value,
}) => (
  <Form
    initialValues={value}
    subscription={defaultSubscription}
    keepDirtyOnReinitialize
    mutators={{
      ...arrayMutators,
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
      clear,
    }}
    onSubmit={async (values) => {
      onChange(values);
    }}
    render={({
      handleSubmit,
      form: {
        mutators: { setValue },
      },
    }) => (
      <FormView onSubmit={handleSubmit}>
        <Main>
          {React.cloneElement(children, { setFieldValue: setValue })}
        </Main>
        <Footer>
          <Button variant="outlined" color="primary" type="submit" data-cy="btn-confirm-component">
            Confirmer
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={close}
            variant="outlined"
            color="primary"
          >
            Retour
          </Button>
        </Footer>
      </FormView>
    )}
  />
);

const FormView = styled.form`
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Main = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  display: flex;
`;
