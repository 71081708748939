import ImageRounded from '@material-ui/icons/ImageRounded';
import ProductList from './list';
import ProductEdit from './edit';
import ProductCreate from './create';

export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  icon: ImageRounded,
};
