import React from 'react';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import { useNotify, useDataProvider } from 'react-admin';
import firebase from 'firebase';
import { UnPublishButtonConfirme, TransparentBtn } from 'src/layout/Buttons';

const ContainerButton = styled.div`
  justify-content: ${(props) => props.justifyContent};
  display: flex;
  align-items: center;
  flex-direction: row;
`;


export const unPublish = async (values) => {
  console.log('--- start unpublish ---', values);
  const update = await firebase
    .firestore()
    .collection('application')
    .doc('pwa')
    .update(values);

  console.log(update);
};


export const UnPublishButton = ({ ...props }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const formState = useFormState();

  const handleClick = async () => {
    if (!formState.valid) {
      if (Object.keys(formState.errors).length > 1) {
        return;
      }
    }

    return dataProvider
      .update('cards', {
        id: props.record.id,
        data: { ...formState.values, status: 'DRAFT' },
      })
      .then(() => unPublish({ active: false, lastupdate: Date.now() }))
      .then(() => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  return (
    <UnPublishButtonConfirme
      onAccept={handleClick}
      OpenButton={(props) => (
        <ContainerButton justifyContent="center">
          <TransparentBtn variant="extended" onClick={props.handleOpen}>
            Désactiver votre carte
          </TransparentBtn>
        </ContainerButton>
      )}
    />
  );
};
