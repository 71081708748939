/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  values, reduce, map, filter as filterld, get, forEach,
} from 'lodash';
import Dinero from 'dinero.js';

import Graph from './Graph';
import GraphWrapper from './GraphWrapper';
import { getQuantity } from './formatExportData';

const ContainerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: .5em;
  margin-right: .5em;
`;

const ContentInfo = styled.div`
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  flex: .7;
  padding-top: 1em;
  overflow: scroll;
  background-color: white;
`;
const ContainerTotal = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: .25;
`;
const ContentTotal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
`;

const Item = styled.div`
  border-bottom: solid 1px lightgrey;
  padding: 12px;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.color};
`;

const Label = styled.div`
  font-size: .9em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  color:white;
`;

const Title = styled.div`
  font-size: 1em;
  width: 90%;
  padding: .5em;
  font-weight: 400;
`;

const Number = styled.div`
  font-size: 3em;
  height: 70%;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  display: flex;
`;

const ReduceXY = (arr) => values(reduce(arr, (result, obj) => {
  const keys = Object.keys(obj);
  const product = filterld(keys, (k) => k !== 'date')[0];
  if (!result[obj.date]) {
    result[obj.date] = {
      date: obj.date,
      [product]: obj[product] + (result[obj.date] && result[obj.date][product] ? result[obj.date][product] : 0),
    };
  } else {
    result[obj.date][product] = obj[product] + (result[obj.date] && result[obj.date][product] ? result[obj.date][product] : 0);
  }
  return result;
}, {}));

const byProductMode = (data, unit) => {
  const numberValues = {};
  let numberProduct = 0;
  let numberCmd = 0;

  const Cards = {
    data: [],
  };

  forEach(data, (item) => {
    const created_at = new Date(item.created_at);
    created_at.setMinutes(0);
    created_at.setSeconds(0);
    created_at.setMilliseconds(0);
    numberCmd++;
    forEach(item.items, (i) => {
      let totalProduct = 0;
      if (unit === 'euro') totalProduct = getQuantity(i) * i.price;
      if (unit === 'number') totalProduct = getQuantity(i);
      numberProduct++;
      // eslint-disable-next-line
      numberValues[i.reference] != null ? numberValues[i.reference] += totalProduct : numberValues[i.reference] = totalProduct;

      Cards.data.push({ date: created_at.getTime(), [i.reference]: totalProduct });
    });
  });

  const reduce = ReduceXY(Cards.data);
  return { data: reduce, numberValues, average: Math.round(numberProduct ? numberProduct / numberCmd : 0) };
};

const Informations = ({ colorsGraph, total: { numberValues, average }, unit }) => {
  let count = -1;
  // let totalProduct = 0;
  return (
    <ContainerInfo>
      <ContentInfo id="legend">
        {map(numberValues, (product, i) => {
          // totalProduct += product
          count++;
          const color = colorsGraph.filter((c) => c.name === Object.keys(numberValues)[count]);

          return (
            <Item key={i} color={get(color, '0.color')}>
              <Label>
                {`${Object.keys(numberValues)[count]} : `}
                <b>
                  {unit === 'euro' && numberValues ? `${Dinero({ amount: parseInt(product, 10) }).toFormat('0.00')} €` : product}
                </b>
              </Label>
            </Item>
          );
        })}
      </ContentInfo>
      <ContainerTotal>
        <ContentTotal>
          <Title>
            {'Nb d\'articles moyen par commande'}
          </Title>
          <Number>
            {average}
          </Number>
        </ContentTotal>
      </ContainerTotal>
    </ContainerInfo>
  );
};

const GraphComponent = (props) => props.data && <Graph setColors={props.setColors} legendActive={props.legendActive} unit={props.unit} type="products" data={props.data} filterKey={props.filterKey} />;

const ProductOrder = ({ orders, filterKey }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({
    numberValues: {},
    average: 0,
  });

  useEffect(() => {
    if (orders) {
      const { data, numberValues, average } = byProductMode(orders, filterKey.unit);
      setData(data);
      setTotal({ numberValues, average });
    }
  }, [orders, filterKey]);

  return (
    <GraphWrapper
      unit={filterKey.unit}
      title="Résumé de vos commandes par produit"
      graph={GraphComponent}
      data={data}
      legendActive={false}
      total={total}
      filterKey={filterKey}
      informations={Informations}
      informationPosition="right"
    />
  );
};

export default ProductOrder;
