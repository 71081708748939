import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data.value} />;

const separator = {
  icone: '/img/components/line.svg',
  description:
    "Ce composant permet d'ajouter une séparation visuelle entre vos différents composants",
  nameId: 'Séparateur',
  init: { color: 'light' },
  type: 'separator',
  renderEdit,
};

export default separator;
