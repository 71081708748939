import React from 'react';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import styled from 'styled-components';
import { useNotify, useDataProvider, useRedirect } from 'react-admin';
import { TransparentBtn } from 'src/layout/Buttons';
import { checkSlotUnicity } from '../accessor/checkCardSlotsUnicity';

const ContainerButton = styled.div`
  justify-content: ${(props) => props.justifyContent};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const CreateButtonWithCheck = ({ ...props }) => {
  const redirectTo = useRedirect();
  const { basePath } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const formState = useFormState();

  const handleClick = async (autosave = false) => {
    if (!formState.valid) {
      return;
    }
    if (autosave) {
      notify('autosave_in_progress', 'info', {
        smart_count: 1,
      });
    }
    const currentSlots = get(formState, 'values.slots');
    const isUnique = await checkSlotUnicity({
      currentSlots,
      currentId: props.record.id,
    });
    if (!isUnique) {
      return notify('card.form.errors.unicity', 'warning', {
        smart_count: 1,
      });
    }

    return dataProvider
      .create('cards', { data: formState.values })
      .then(({ data: newRecord }) => {
        notify('ra.notification.created', 'info', {
          smart_count: 1,
        });

        redirectTo('edit', basePath, newRecord.id, newRecord);
      })
      .catch(() => { });
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     handleClick(true);
  //   }, 20000);
  //   return () => clearTimeout(timeout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ContainerButton justifyContent="center">
      <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-create-card">
        Créer
      </TransparentBtn>
    </ContainerButton>
  );
};
