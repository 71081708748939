import React from 'react';
import MuiGridList from '@material-ui/core/GridList';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const useStyles: any = makeStyles(() => ({
  root: {
    margin: '-2px',
  },
  gridList: {
    width: '100%',
    margin: 0,
  },
  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    borderRadius: '5px',
    height: '263px !important',
    width: '240px !important',
    margin: '20px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  placeholderRow: {
    height: '4em !important',
    width: '100% !important',
    margin: '.5em !important',
    marginLeft: '2em !important',
    marginRignt: '2em !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 4;
  if (width === 'lg') return 5;
  return 6;
};

const times = (nbChildren, fn) => Array.from({ length: nbChildren }, (_, key) => fn(key));

export const LoadingRowList = ({ nbItems = 8 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiGridList
        className={classes.gridList}
      >
        {' '}
        {times(nbItems, (key) => (
          <div key={key} className={classes.placeholderRow}>
            <div style={{
              flex: '.4',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            >
              <Skeleton variant="rect" width="3em" height="3em" />
              <div style={{ flex: '.7', marginLeft: '2em' }}>
                <Skeleton width="60%" />
                <Skeleton width="80%" />
              </div>
            </div>
            <div style={{
              flex: '.3',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <Skeleton width="50%" />
              <Skeleton variant="rect" width="6em" height="2em" />
            </div>
            <Skeleton variant="rect" width="3em" height="3em" />
          </div>
        ))}
      </MuiGridList>
    </div>
  );
};

const LoadingGridList = ({ width, nbItems = 10 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiGridList
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {' '}
        {times(nbItems, (key) => (
          <div key={key} className={classes.placeholder}>
            <Skeleton variant="rect" width="100%" height="60%" />
            <div style={{
              height: '40%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            >
              <Skeleton width="50%" />
              <Skeleton width="50%" />
              <Skeleton width="60%" />
            </div>
          </div>
        ))}
      </MuiGridList>
    </div>
  );
};


const LoadingAdminList = ({ width, nbItems = 10 }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ display: 'flex' }}>
      <div style={{ flex: 0.9 }}>
        <Skeleton variant="rect" width="100%" height="10vh" />
        <MuiGridList
          cols={getColsForWidth(width)}
          className={classes.gridList}
        >
          {' '}
          {times(nbItems, (key) => (
            <div key={key} className={classes.placeholder}>
              <Skeleton variant="rect" width="100%" height="60%" />
              <div style={{
                height: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              >
                <Skeleton width="50%" />
                <Skeleton width="50%" />
                <Skeleton width="60%" />
              </div>
            </div>
          ))}
        </MuiGridList>
      </div>
      <div style={{ flex: 0.1 }}>
        <Skeleton variant="rect" height="100vh" />
      </div>
    </div>
  );
};

export const LoadingPreparator = withWidth()(LoadingAdminList);


export default withWidth()(LoadingGridList);
