/* eslint-disable max-len */
import React, { useState } from 'react';
import List from '@material-ui/core/List';
import {
  ListItem, ListItemIcon, ListItemText, ListItemAvatar, Box,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useRedirect } from 'react-admin';
import { map } from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';
import GetListOffer from 'src/data/injector/GetListOffer';


const SelectWrapper = styled(Select)`
  width: 40%;
`;

const ListWrapper = styled(List)`
  overflow: scroll;
  width: 70%;
`;

const ListItemWrapper = styled(ListItem)`
  flex-direction: column;
  align-items: flex-end !important;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ListItemIconWrapper = styled(ListItemIcon)`
  min-width: 0 !important;
`;

const ListOfferItem = ({
  // eslint-disable-next-line
  handleToggle, offer, selected
}) => map(offer,

  (o) => {
    const labelId = `checkbox-list-label-${o.id}`;
    return (
      <ListItemWrapper data-cy="item-product-to-add" key={o.id} button onClick={() => handleToggle(o.id)}>
        <Row>
          <ListItemAvatar>
            <Avatar alt="image" src={o.picture} />
          </ListItemAvatar>
          <ListItemText id={labelId} primary={o.reference} />
          <ListItemIconWrapper>
            {o.id && selected && selected.indexOf(o.id) !== -1 ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankIcon color="disabled" />
            )}
          </ListItemIconWrapper>
        </Row>
        <Divider light width="93%" />
      </ListItemWrapper>
    );
  });

export const ListOffer = ({
  many, initialData, selected, setSelected,
}) => {
  const redirectTo = useRedirect();
  // eslint-disable-next-line
  const [error, setError] = useState();
  const handleToggle = (value) => {
    setSelected(value);
  };

  return (
    <>
      <Box
        overflow="scroll"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flex=".9"
      >
        <ListWrapper data-cy="list-products-to-add">
          <GetListOffer initialData={initialData} setError={setError}>
            <ListOfferItem handleToggle={handleToggle} selected={selected} many={many} redirectTo={redirectTo} />
          </GetListOffer>
        </ListWrapper>
      </Box>
    </>
  );
};
