import firebase from 'firebase';
import { map } from 'lodash';

export const upload = async ({ imageId = null, imagetoUpload }) => {
  try {
    const data = JSON.parse(JSON.stringify({ ...imagetoUpload.crop }));
    const cropped = [];
    map(data, (key, val:any) => {
      cropped.push({
        type: val,
        size: `${key.width}x${key.height}^`,
        crop: `${key.width}x${key.height}+${key.x}+${key.y}`,
      });
    });

    const needUpdateMetadata = !imagetoUpload.base64.includes('base64');

    if (needUpdateMetadata) {
      console.log('NEED UPDATE METADA');
      await firebase
        .storage()
        .ref()
        .child(`images/${imageId}/original`)
        .updateMetadata({
          contentType: 'image/jpeg',
          cacheControl: 'public,max-age=300',
          customMetadata: { crop: JSON.stringify(imagetoUpload.crop), id: imageId },
        });
      return;
    }
    await firebase
      .storage()
      .ref()
      .child(`images/${imageId}/original`)
      .putString(imagetoUpload.base64, 'data_url', {
        contentType: 'image/jpeg',
        cacheControl: 'public,max-age=300',
        customMetadata: { crop: JSON.stringify(imagetoUpload.crop), id: imageId },
      });
  } catch (e) {
    console.log('--- error ---', e);
  }
};
