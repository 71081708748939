import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import { useLogout } from 'react-admin';
import ProductorView from 'src/data/injector/Productor';
import LoadingList from 'src/layout/List/Loading';
import { LogoutButtonConfirme } from 'src/layout/Buttons';
import ListProducts from '../components/list/ItemProducts';

const MainPoductor = styled.div`
  display: flex;
  height: 100vh;
`;

const Logo = styled.img`
  padding: 1em 1em 0em 1em;
  width: 6em;
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`;

const MainScreen = styled.div`
  flex: 0.9;
  background: #222428;
  padding: 15px;
`;

const SideBar = styled.div`
  flex: 0.1;
  display: flex;
  background: #5bfcc2;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ItemSideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  logout: {
    background: '#6da993',
    color: 'white',
    boxShadow: 'none',
    width: '4em',
    height: '4em',
  },
}));

const RenderLogo = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Logo src="/img/kards/logo.svg" alt="kards" />
    <Text color="black" size=".6em" weight="400" textAlign="center" width="60%" padding=".3em" lettreSpacing="1px">
      PRODUCTEUR
    </Text>
  </Box>
);

const RenderLogout = () => {
  const logout = useLogout();
  const classes = useStyles();
  const handleClick = () => logout();
  return (
    <LogoutButtonConfirme
      onAccept={handleClick}
      OpenButton={(props) => (
        <ItemSideBar>
          <Fab
            className={classes.logout}
            onClick={props.handleOpen}
          >
            <PowerSettingsNewIcon style={{ width: '1.3em', height: '1.3em' }} />
          </Fab>
        </ItemSideBar>
      )}
    />
  );
};

const List = (props) => (
  <MainPoductor>
    <MainScreen>
      <ListWrapper data-cy="list-products">
        <ProductorView {...props}>
          <WrapperToDo />
        </ProductorView>
      </ListWrapper>
    </MainScreen>
    <SideBar>
      <Box display="flex" flex=".45" justifyContent="space-between" alignItems="center" flexDirection="column">
        <RenderLogo />
      </Box>
      <Box display="flex" flex=".1" justifyContent="center" alignItems="center">
        <RenderLogout />
      </Box>
    </SideBar>
  </MainPoductor>
);

const WrapperToDo = ({ data }) => <ListProducts data={data} />;


const OrderLoadableList = ({ loading, ...props }) => (
  <div>{!loading ? <List {...props} /> : <LoadingList {...props} />}</div>
);

const ProductorList = (props) => <OrderLoadableList {...props} />;

export default ProductorList;
