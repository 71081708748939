import React from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Box from '@material-ui/core/Box';

export const Tooltip = ({ text, secondary = false }) => (
  <MaterialTooltip TransitionComponent={Zoom} title={<Box fontSize="1.5em" fontWeight="400" p=".5em">{text}</Box>} placement="top">
    <Box p=".5em">
      {
        secondary
          ? <img alt="plus" src="/img/icon/infoGreen.svg" width="20px" />
          : <img alt="plus" src="/img/icon/infoBlack.svg" width="20px" />
      }
    </Box>
  </MaterialTooltip>
);
