import React from 'react';
import styled from 'styled-components';
import { Box, RadioGroup, Radio } from '@material-ui/core';

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1em;
  font-weight: bold;
`;

const Image = styled.img`
  width: 65px;
  height: 65px;
`;

const Description = styled.p`
  text-align: left;
`;

export function ModeNoOrder(props) {
  return (
    <Box display="flex" flexDirection="column">
      <RadioGroup name={props.name} value={props.input.value} onChange={props.input.onChange}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">

          <Box flex=".48" padding="1em">

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                padding=".5em"
              >
                <ImageWrapper onClick={() => { }}>
                  <Image src="/img/utils/modeNoOrder.svg" />
                </ImageWrapper>
              </Box>
              <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
                <Box>
                  <Radio
                    color="secondary"
                    value="noOrder"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box>
                  <Label>Sans commande</Label>
                  <Description>
                    Vos clients ne peuvent pas commander mais ont accès à la carte
                  </Description>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flex=".48" padding="1em">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/modeNormal.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Radio
                  color="secondary"
                  value="instant"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Commande en direct</Label>
                <Description>
                  Vos clients peuvent commander en instantané.
                </Description>
              </Box>
            </Box>
          </Box>
          <Box flex=".48" padding="1em">

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/modePreorder.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Radio
                  color="secondary"
                  value="preorder"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Précommande</Label>
                <Description>
                  Vos clients peuvent précommander pour le jour et l'heure de leur choix.
                </Description>
              </Box>
            </Box>
          </Box>
        </Box>
      </RadioGroup>
    </Box>
  );
}
