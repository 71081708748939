/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import {
  forEach,
} from 'lodash';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import DatePicker from 'react-datepicker';
import { orderDelivered } from 'src/data/accessor/dashboard';
import { getProducts } from 'src/data/accessor/getProducts';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate, exportCSVFile } from 'src/helpers/csv';
import { formatExportData } from './formatExportData';
import ProductOrders from './ProductsOrders';
import NbOrders from './NbOrders';
// import PaymentOrders from './PaymentOrders';
// import TimeOrders from './TimeOrders';

const ContainerDashboard = styled.div`
  display: grid;
  flex-direction: column;
  overflow: scroll;
  height: 87vh;
  margin-top: 8vh;
`;

const ContainerItemsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContainerDatePicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContainerField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

const ContainerGraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1em 0;
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: fixed;
  width: 85%;
  height: 5em;
`;

const DatePickersStart = ({
  onChange, value, maxDate, minDate,
}) => {
  const [dateInput, setDate] = useState(value);
  return (
    <DatePicker
      id="scheduledAt"
      name="scheduledAt"
      todayButton="Aujourd'hui"
      label="logo.create.timeTitle"
      locale="fr-FR"
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      calendarClassName="datePickerTime"
      dateFormat="dd/MM/yyyy HH:mm"
      onCalendarClose={() => onChange(dateInput)}
      onChange={(date) => setDate(date)}
      timeCaption="heure"
      selected={dateInput}
      maxDate={maxDate}
      minDate={minDate || null}
      placeholder="backgroundImage.form.scheduledAt.placeholder"
      customInput={<BootstrapInput />}
    />
  );
};

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#222428',
    },
  },
}))(InputBase);

const FilterComponent = ({
  handleChange, value, value: {
    start, end, format, unit,
  },
  exportData,
}) => (
  <ContainerHeader>
    <ContainerItemsHeader>
      <ContainerDatePicker>
        <ContainerField>
          <DatePickersStart
            maxDate={start}
            value={end}
            onChange={(v) => {
              if ((start - v) / 60 / 60 / 1000 >= 48) {
                format = 'day';
              } else {
                format = 'hour';
              }
              handleChange({ ...value, format, end: v });
            }}
          />
        </ContainerField>
        <Image src="/img/utils/controls.svg" />
        <ContainerField>
          <DatePickersStart
            minDate={end}
            value={start}
            onChange={(v) => {
              if ((v - end) / 60 / 60 / 1000 >= 48) {
                format = 'day';
              } else {
                format = 'hour';
              }
              handleChange({ ...value, format, start: v });
            }}
          />
        </ContainerField>
      </ContainerDatePicker>
      <ContainerField>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={unit}
          onChange={(v) => handleChange({ ...value, unit: v.target.value })}
          input={<BootstrapInput />}
        >
          <MenuItem value="euro">euro</MenuItem>
          <MenuItem value="number"> nombre</MenuItem>
        </Select>
      </ContainerField>
      <div
        style={{
          padding: '10px',
          background: '#222428',
          color: 'white',
          borderRadius: '20px',
          cursor: 'pointer',
        }}
        onClick={() => exportCSVFile(exportData, `${formatDate(start, end)}`)}
      >
        {' '}
        Exporter
      </div>
    </ContainerItemsHeader>
  </ContainerHeader>
);

const filter = ({
  start, end, data, format,
}) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const newData = forEach(data, (order) => {
    if (format === 'hour') {
      const createdAt = new Date(order.created_at);
      createdAt.setMinutes(0);
      createdAt.setSeconds(0);
      createdAt.setMilliseconds(0);
      order.created_at = createdAt;
    }
    if (format === 'day') {
      const createdAt = new Date(order.created_at);
      createdAt.setHours(0);
      createdAt.setMinutes(0);
      createdAt.setSeconds(0);
      createdAt.setMilliseconds(0);
      order.created_at = createdAt;
    }
  });
  return newData.filter((order) => order.created_at < startDate && order.created_at > endDate);
};

// export data


const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [filterKey, setFilterKey] = useState({
    start: new Date(),
    end: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
    format: 'hour',
    unit: 'euro',
  });
  const [exportData, setExportData] = useState([]);

  const fetchOrders = useCallback(async () => {
    const { docs } = await orderDelivered();
    const products = await getProducts();

    const orderDeliveredData = docs.map((doc) => doc.data());
    const data = filter({ ...filterKey, data: orderDeliveredData });
    setOrders(data);
    setExportData(formatExportData(data, products));
  }, [filterKey]);


  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [filterKey]);

  return (
    <>
      {exportData && <FilterComponent exportData={exportData} handleChange={setFilterKey} value={filterKey} />}
      <ContainerDashboard>
        <ContainerGraph>
          <NbOrders filterKey={filterKey} orders={[...orders]} value={orders.length} />
        </ContainerGraph>
        {/* <ContainerGraph informationPosition="left">
          <PaymentOrders filterKey={filterKey} orders={[...orders]} value={orders.length} />
        </ContainerGraph> */}
        <ContainerGraph>
          <ProductOrders filterKey={filterKey} orders={[...orders]} value={orders.length} />
        </ContainerGraph>
        {/* <ContainerGraph>
          <TimeOrders filterKey={filterKey} orders={[...orders]} value={orders.length} />
        </ContainerGraph> */}
        <ContainerGraph style={{ height: '9em' }} />
      </ContainerDashboard>
    </>
  );
};

export default Dashboard;
