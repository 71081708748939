import React from 'react';
import styled from 'styled-components';
import ListOffer from '../commons/offer';

const EditWindow = () => (
  <>
    <FlexContainer>
      <ListOffer
        name="offer"
        title="Configurer votre offre"
        subtitle="Sélectionnez l'offre que vous souhaitez ajouter"
      />
    </FlexContainer>
  </>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
