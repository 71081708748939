import React from 'react';
import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const product = {
  icone: '/img/components/product.svg',
  description: "Ce composant permet d'ajouter un produit et sa photo",
  explain: '/img/components/explain/product.svg',
  nameId: 'Produit',
  type: 'product',
  renderEdit,
};

export default product;
