import firebase from 'firebase';

export default async ({ entitieWatch, numberEntitieWatch, callback }) => {
  const defaultFirestore = firebase.app();
  return defaultFirestore
    .firestore()
    .collection('orders')
    .where(`status.${entitieWatch}`, '==', true)
    .where('parameters.delivery', '==', 'onplace')
    .orderBy('created_at', 'desc')
    .limitToLast(numberEntitieWatch)
    .onSnapshot({}, (snapshot) => callback(snapshot));
};
