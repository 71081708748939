/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import TopEdit from '../../cardComponents/CategoryHeader';
import { Components } from '../../wrapperCardComponents/items';

export default {
  image: '/img/template/Top.png',
  name: 'Vitrine',
  id: 'Top',
  imageType: 'square',
  header: {
    title: true,
  },
  structure: () => (
    <>
      <TopEdit />
      <Components />
    </>
  ),
};
