/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@material-ui/core/Box';
import Circle from 'react-color/lib/Circle';
import { Field } from 'react-final-form';
import tinycolor from 'tinycolor2';
import { map, findKey } from 'lodash';
import { colors } from '@bit/kards.kards-components.helpers';

export default function EditWindow({ value, setFieldValue, ...props }) {
  return (
    <Box
      flex={1}
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      padding="10px"
    >
      <Field
        name="color"
        render={({ input }) => (
          <div
            style={{
              cursor: 'pointer',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '4px',
                margin: '5px',
                backgroundColor: colors[input.value],
                position: 'relative',
              }}
            />
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '1em',
                position: 'absolute',
                color: tinycolor(colors[input.value]).isLight()
                  ? 'black'
                  : 'white',
              }}
            >
              Couleur
            </div>
          </div>
        )}
      />
      <Field
        name="color"
        render={({ input }) => (
          <Box
            padding={1}
            bgcolor="primary.light"
            borderRadius="borderRadius"
          >
            <Circle
              colors={map(colors, (value, key) => value)}
              onChange={(c) => setFieldValue(
                'color',
                findKey(colors, (color) => color === c.hex),
              )}
            />
          </Box>
        )}
      />
    </Box>
  );
}
