import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1em;
  font-weight: bold;
`;

const Image = styled.img`
  width: 65px;
  height: 65px;
`;

// const Lydia = styled.img`
//   width: 50px;
// `;

const Description = styled.p`
  text-align: left;
`;

const ErrorField = styled.span`
  color: red;
  font-size: 0.9em;
`;
export function Payment(props) {
  const [state, setState] = React.useState(props.input.value);
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    props.input.onChange({ ...state, [name]: event.target.checked });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box flex=".48" padding="1em">

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/pay.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Checkbox
                  color="secondary"
                  checked={state.card}
                  disabled
                  onChange={handleChange('card')}
                  value="card"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Carte bleue</Label>
                <Description>
                  Sélectionnez ce mode pour que les utilisateurs puissent payer en carte bleue.
                </Description>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>
      {props.meta.error && <ErrorField>Sélectionnez au moins un de ces éléments.</ErrorField>}
    </Box>
  );
}

// eslint-disable-next-line no-lone-blocks
{ /* <Box flex=".33" padding="1em">
  <Box>
    <Checkbox
      color="primary"
      checked={state.cash}
      onChange={handleChange('cash')}
      value="cash"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding=".5em"
    >
      <ImageWrapper onClick={() => { }}>
        <Image src="/img/utils/euro.svg" />
      </ImageWrapper>
      <Label>Espèces</Label>
    </Box>
    <Description>
      Sélectionnez ce mode pour que les utilisateurs puissent payer en espèces.
    </Description>
  </Box>
</Box> */ }
// eslint-disable-next-line no-lone-blocks
{ /* <Box flex=".33" padding="1em">
  <Box>
    <Checkbox
      color="primary"
      checked={state.lydia}
      onChange={handleChange('lydia')}
      value="lydia"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding=".5em"
    >
      <ImageWrapper onClick={() => { }}>
        <Lydia src="/img/utils/lydia.png" />
      </ImageWrapper>
      <Label>Lydia</Label>
    </Box>
    <Description>
      Sélectionnez ce mode pour que les utilisateurs puissent payer avec Lydia.
    </Description>
  </Box>
</Box> */ }
