export function createUID() {
  const num = `${Date.now()}`;
  return num.substring(7);
}

export const parseFireStoreDocument = (doc) => {
  const data = doc.data();

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value && value.toDate && value.toDate instanceof Function) {
      data[key] = value.toDate();
    }
  });
  return { id: doc.id, ...data };
};
