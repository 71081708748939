import { componentType } from '@bit/kards.kards-components.helpers';
import List from './SimpleList';
import Product from './Product';
import Separator from './Separator';
import Carousel from './Carousel';
import Image from './Image';
import Video from './Video';
import Wheel from './Wheel';
import Slide from './ImageSlides';
import Social from './Social';
import Offer from './Offer';

export const getComponent = (type) => {
  switch (type) {
    case componentType.Product:
      return Product;
    case componentType.Offer:
      return Offer;
    case componentType.List:
      return List;
    case componentType.Separator:
      return Separator;
    case componentType.Carousel:
      return Carousel;
    case componentType.Image:
      return Image;
    case componentType.Video:
      return Video;
    case componentType.Wheel:
      return Wheel;
    case componentType.Slide:
      return Slide;
    case componentType.Social:
      return Social;
    default:
  }
};
