import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { TextInputField, CheckboxField } from 'src/form/components/field/index';
import { ImagesField } from 'src/form/components/field';

const OpenBtn = ({ handleOpen }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={handleOpen}
  >
    Selectionner une image
  </Button>
);

const EditWindow = ({ setFieldValue }) => (
  <>
    <FlexContainer>
      <ImagesField
        name="picture"
        type="rectangle"
        title="Ajouter une image"
        subtitle="Sélectionnez l'image que vous souhaitez afficher"
        onAccept={(picture) => setFieldValue('picture', picture)}
      >
        <OpenBtn />
      </ImagesField>
      <TextInputField label="Titre" name="title" />
      <TextInputField label="Description" name="description" />
      <TextInputField label="Lien" name="link" />
      <CheckboxField label="Image sans bordures" name="full" />
    </FlexContainer>
  </>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
