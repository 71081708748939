import React from 'react';
import { FunctionField } from 'react-admin';
import { forEach } from 'lodash';

const TotalField = ({ record, label }) => {
  let calculeTotal = 0;
  forEach(record.items, (order) => {
    const number = order.number || order.quantity;
    calculeTotal += parseFloat(order.price) * number;
  });
  return record ? (
    <FunctionField label={label} render={() => calculeTotal} />
  ) : null;
};

export default TotalField;
