/* eslint-disable no-unused-vars */
import uuid from 'uuid/v4';
import { get, findIndex } from 'lodash';

const createComponent = (item) => ({
  type: item.type,
  id: uuid(),
  value: item.initValue || {},
});

// CORE FUNCTION
export const deepUpdate = (components, id, value) => {
  for (let i = 0; i < components.length; i++) {
    const item = components[i];
    if (get(item, 'id') === id) {
      item.value = value;
    }
  }
  return components;
};

export const deepRemove = (components, id) => {
  const index = findIndex(components, (c) => c.id === id);
  components.splice(index, 1);
};

export const deepMove = ({ components, id, position }) => {
  for (let i = 0; i < components.length; i++) {
    const item = components[i];
    if (get(item, 'id') === id) {
      if (components[position]) {
        const tempCompo = components[i];
        components[i] = components[position];
        components[position] = tempCompo;
      }
    }
  }

  return components;
};

export const deepDrop = ({ components, item, newPosition }) => {
  let tempComponent;
  const oldPosition = {};
  const { id } = item;
  let newUuid;
  if (id) {
    // find old position and remember the component
    components.forEach((component, mainIndex) => {
      if (component.id === id) {
        tempComponent = component;
        oldPosition.index = mainIndex;
      }
    });
  } else {
    tempComponent = createComponent(item);
    newUuid = tempComponent.id;
  }
  // remove
  if (oldPosition.index > -1) {
    components.splice(oldPosition.index, 1);
  }
  if (newPosition.index > -1) {
    components.splice(newPosition.index, 0, tempComponent);
  }
  return { newUuid };
};
