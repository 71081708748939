/* eslint-disable max-len */
import React from 'react';
import { Filter, List, CreateButton, SelectInput, useTranslate } from 'react-admin';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import uuid from 'uuid/v4';
import { Toolbar } from '@material-ui/core';
import ProductIcon from '@material-ui/icons/Collections';
import { RowProduct } from 'src/layout/List/RowProduct';
import { LoadingRowList } from 'src/layout/List/Loading';
import SearchInput from 'src/layout/Search/Bar';
import { productsResource } from 'src/App';

import EmptyComponent from 'src/layout/Empty';
// import { AskForCashRegister } from 'src/cash-register';

const useStyles: any = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
  input: {
    '& .MuiFilledInput-root': {
      borderRadius: 20,
      position: 'relative',
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      boxShadow: 'rgba(0,0,0,0.12) 0px 4px 16px',
      fontSize: 16,
      marginRight: '.5em',
      width: '18em'
    },
    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 20,
      backgroundColor: 'white',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 13px) scale(1)',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 2px) scale(0.75)',
    },
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '16px'
    },
}
}));

const ProductsTitle = () => <span>Produits</span>;

export const ProductFilter = (props) => {
  const classes = useStyles();
  const translate = useTranslate()
  const optionsResource = productsResource.map((r) => ({ id: r, name: translate(`pos.menu.product.${r}`) }));

  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="reference" alwaysOn />
      <SelectInput
        source="tag"
        label="Catégorie"
        choices={optionsResource}
        className={classes.input}
        alwaysOn
      />
    </Filter>
  );
};


export const Action = ({ basePath }) => (
  <Toolbar>
    <CreateButton basePath={basePath} data-cy="create-product" />
  </Toolbar>
);

export const Empty = ({ basePath, resource, location }) => (
  <EmptyComponent
    basePath={basePath}
    resource={resource}
    location={location}
    titleText="Vous n'avez pas encore de produit."
    explainText="Créer votre premier produit."
    Image={ProductIcon}
  />
);

const duplicateData = (data) => ({
  createdate: data.createdate,
  createdby: data.createdby,
  description: data.description,
  id: uuid(),
  instant: data.instant ? data.instant : null,
  lastupdate: data.lastupdate,
  picture: data.picture ? data.picture : null,
  price: data.price,
  preorder: data.preorder ? data.preorder : null,
  reference: `${data.reference} Copie`,
  settingFields: data.settingFields ? data.settingFields : null,
  stock: data.stock,
  tva: data.tva,
  tag: data.tag,
});

const FlexH = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  max-height: 75vh;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7em;
  width: 100%;
`;

const GridList = (props) => {
  const {
    data, basePath, ids, resource,
  } = props;

  return (
    <FlexH data-cy="list-products">
      {ids && ids.length > 0 ? (
        ids.map((id) => (
          <RowProduct
            key={id}
            data={data[id]}
            basePath={basePath}
            duplicateData={() => duplicateData(data[id])}
            resource={resource}
          />
        ))
      ) : (
          <NoData>Aucun produit</NoData>
        )}
    </FlexH>
  );
};

const ProductLoadableList = ({ loading, ...props }) => (!loading ? <GridList {...props} /> : <LoadingRowList {...props} />);

const ProductList = (props) => {
  return (
    // <AskForCashRegister>
    <List
      filters={<ProductFilter />}
      actions={<Action {...props} />}
      empty={<Empty {...props} />}
      title={<ProductsTitle />}
      perPage={25}
      sort={{ field: 'reference', order: 'ASC' }}
      {...props}
    >
      <ProductLoadableList {...props} />
    </List>
    // </AskForCashRegister>
  );
};

export default ProductList;
