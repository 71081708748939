import React from 'react';
import styled from 'styled-components';
import TimeField from 'react-simple-timefield';
import { Box } from '@material-ui/core';
import { Field } from 'react-final-form';
import moment from 'moment';
import { composeValidators, required } from '../../validator';

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
  position: absolute;
  bottom: -25px;
  /* right: 0; */
  white-space: nowrap;
  left: 0;
`;

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: rgba(31, 38, 62, 0.2) 0px 0px 4px;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  margin: 3px;
  border-radius: 3px;
  box-shadow: rgba(31, 38, 62, 0.15) 0px 0px 4px;
  color: rgb(31, 38, 62);
  display: flex;
  height: 15px;
  justify-content: center;
  width: 15px;
`;

const ListItem = styled.li`
  align-items: center;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 3em;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
`;

const StyledInputField = styled(TimeField)`
  border: none;
  font-weight: 400;
  width: 4em !important;
  font-size: 1em;
  padding: 5px 10px;
  text-align: center;
`;

const Label = styled.label`
  font-size: 0.8em;
  margin-right: 10px;
`;

const FieldWrapper = styled(Box)`
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 4px;
`;

const InputWithSideEffect = ({
  input, meta, minValue, ...props
}) => {
  const addHours = () => {
    const newVal = moment(input.value, 'HH:mm').add(1, 'hours');
    let hours = newVal.get('hour');
    const minutes = newVal.get('minute');
    if (hours < 10) {
      hours = `0${hours}`;
    }
    input.onChange(`${hours}:${minutes}`);
  };

  const removeHours = () => {
    const newVal = moment(input.value, 'HH:mm').subtract(1, 'hours');
    let hours = newVal.get('hour');
    const minutes = newVal.get('minute');
    if (hours < 10) {
      hours = `0${hours}`;
    }
    input.onChange(`${hours}:${minutes}`);
  };

  const addMinutes = () => {
    const newVal = moment(input.value, 'HH:mm').add(1, 'minutes');
    let hours = newVal.get('hour');
    let minutes = newVal.get('minute');
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    input.onChange(`${hours}:${minutes}`);
  };

  const removeMinutes = () => {
    const newVal = moment(input.value, 'HH:mm').subtract(1, 'minutes');
    let hours = newVal.get('hour');
    let minutes = newVal.get('minute');
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    input.onChange(`${hours}:${minutes}`);
  };
  if (
    minValue && moment(input.value, 'HH:mm').isBefore(
      moment(minValue, 'HH:mm').add(1, 'hours'),
    )
  ) {
    const newVal = moment(minValue, 'HH:mm').add(1, 'hours');
    let hours = newVal.get('hour');
    const minutes = newVal.get('minute');
    if (hours < 10) {
      hours = `0${hours}`;
    }
    input.onChange(`${hours}:${minutes}`);
  }
  return (
    <>
      <FieldWrapper display="flex" alignItems="center">
        <Box display="flex" alignItems="center" flexDirection="column">
          <ButtonWrapper onClick={() => addHours()}>
            <div>+</div>
          </ButtonWrapper>
          <ButtonWrapper onClick={() => removeHours()}>
            <div>-</div>
          </ButtonWrapper>
        </Box>
        <StyledInputField {...input} disabled {...props} />
        <Box display="flex" alignItems="center" flexDirection="column">
          <ButtonWrapper onClick={() => addMinutes()}>
            <div>+</div>
          </ButtonWrapper>
          <ButtonWrapper onClick={() => removeMinutes()}>
            <div>-</div>
          </ButtonWrapper>
        </Box>
      </FieldWrapper>
    </>
  );
};

export const InputTime = ({
  name, index = '0', fields = null, labelStart = 'Début :', labelEnd = 'Fin :', remove = true, validate = true, flex = '1',
}) => {
  const keys = [];

  return (
    <Box display="flex" flex={flex} key={keys[index]} alignItems="center" justifyContent="center">
      <ListItem>
        <Box flex="0.5" display="flex" position="relative" alignItems="center" justifyContent="center" flexDirection="row">
          <Label>{labelStart}</Label>
          <Field
            name={`${name}.start`}
            placeholder="heure:minute"
            validate={validate && composeValidators(required)}
            render={({ input, meta }) => (
              <Box alignItems="center" display="flex" flexDirection="column">
                <InputWithSideEffect input={input} meta={meta} />
                {meta.error && <ErrorField>{meta.error}</ErrorField>}
              </Box>
            )}
          />
        </Box>
        <Box flex="0.5" display="flex" position="relative" alignItems="center" justifyContent="center" flexDirection="row">
          <Label>{labelEnd}</Label>
          <Field
            name={`${name}.end`}
            placeholder="heure:minute"
            validate={validate && composeValidators(required)}
            render={({ input, meta }) => (
              <Box display="flex" alignItems="center" flexDirection="column">
                <InputWithSideEffect
                  input={input}
                  meta={meta}
                // minValue={form.values.slots[index].start}
                />
                {meta.error && (
                  <ErrorField style={{ marginLeft: '5px' }}>
                    {meta.error}
                  </ErrorField>
                )}
              </Box>
            )}
          />
        </Box>
        {
          remove
          && (
            <Box flex="0.1">
              <ImageWrapper
                onClick={() => {
                  fields.remove(index);
                }}
                style={{ marginRight: '10px' }}
              >
                <img alt="delete" width="10px" src="/img/icon/delete.svg" />
              </ImageWrapper>
            </Box>
          )
        }
      </ListItem>
    </Box>
  );
};
