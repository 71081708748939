import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import get from 'lodash/get';

const useStyles: any = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  colorSecondary: {
    backgroundColor: 'white',
  },
});

const ImgWrapper = styled.img`
  width: 6em;
`;

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.colorSecondary} {...props}>
      <Box flex=".2" display="flex" justifyContent="flex-start" alignItems="center">
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      </Box>
      <Box flex=".6" display="flex" justifyContent="center" alignItems="center">
        <ImgWrapper src="/img/kards/logo.svg" alt="logo" />
      </Box>
      <Box flex=".2" display="flex" justifyContent="center" alignItems="center">
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
        >
          {get(window, 'kardsConfiguration.name')}
        </Typography>
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
