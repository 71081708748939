import React from 'react';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import styled from 'styled-components';
import { useNotify, useDataProvider } from 'react-admin';
import { PublishButtonConfirme, TransparentBtn } from 'src/layout/Buttons';
import { checkSlotUnicity } from '../accessor/checkCardSlotsUnicity';
import publishCard from '../accessor/publishCard';


const ContainerButton = styled.div`
  justify-content: ${(props) => props.justifyContent};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const EditLiveButtonWithCheck = ({ ...props }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const formState = useFormState();

  const handleClick = async () => {
    console.log(formState);
    if (!formState.valid) {
      return;
    }
    const currentSlots = get(formState, 'values.slots');
    const isUnique = await checkSlotUnicity({
      currentSlots,
      currentId: props.record.id,
    });
    if (!isUnique) {
      return notify('card.form.errors.unicity', 'warning', {
        smart_count: 1,
      });
    }

    return dataProvider
      .update('cards', {
        id: props.record.id,
        data: { ...formState.values, status: props.record.status },
      })
      .then(async () => {
        await publishCard({ cardId: props.record.id });
      }).then(() => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
      })
      .catch(() => {});
  };

  return (
    <PublishButtonConfirme
      onAccept={handleClick}
      OpenButton={(props) => (
        <ContainerButton justifyContent="center">
          <TransparentBtn variant="extended" onClick={props.handleOpen} data-cy="btn-edit-card">
            Enregistrer en direct
          </TransparentBtn>
        </ContainerButton>
      )}
    />
  );
};
