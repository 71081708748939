import firebase from 'firebase';
import get from 'lodash/get';
import { configuration } from '../../App';

export default async ({
  email,
  name,
  role,
  password,
  notify,
  redirectTo,
}) => {
  firebase.auth().onAuthStateChanged((currentUser) => {
    currentUser.getIdToken().then((idToken) => {
      const secureHeader = new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` });

      const requestConfig: any = {
        method: 'POST',
        headers: secureHeader,
        body: JSON.stringify({
          data: {
            email, name, role, password,
          },
        }),
        mode: 'cors',
      };

      const error = () => {
        redirectTo('users');
        notify('Un problème est survenu, en cas de besoin contactez l\'assistance technique', 'warning');
      };

      const request = new Request(
        `${configuration.cloudFunction}createAuthenticatedUser`,
        requestConfig,
      );

      fetch(request).then((response) => {
        response.json().then((res) => {
          // response ko
          if (res && res.error) throw error();
          const result = get(res, 'result');
          // response ok but result ko
          if (result && result.error) {
            const errorCode = get(result, 'error.errorInfo.code');
            if (errorCode === 'auth/weak-password' || errorCode === 'auth/invalid-password') {
              throw notify('user.errorPassword', 'warning');
            } else if (errorCode === 'auth/invalid-email') {
              throw notify('user.errorMail', 'warning');
            } else if (errorCode === 'auth/email-already-exists') {
              throw notify('user.errorMailExiste', 'warning');
            } else {
              throw notify('user.error', 'warning');
            }
          } else {
            // response ok
            notify('user.created', 'info', {
              smart_count: 1,
            });
            redirectTo('users');
          }
        });
      });
    }).catch((e) => {
      console.log(e);
    });
  });
};
