import React, { useEffect, useContext } from 'react';
import { DragSource, DragPreviewImage } from 'react-dnd';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { DropTarget } from '../../../dragAndDrop';
import Overlay from '../overlay';
import { getComponent } from '../../cardComponents/getComponents';

const DISPLAYED_TYPES = {
  image: 'constants.component.image',
};
const source = {
  beginDrag(props) {
    return props.item;
  },
};
function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}
function Component({ data }) {
  const c: any = getComponent(data.type);
  return c.renderEdit({ data });
}

const EditingItem = ({
  item,
  moveComponent,
  position,
  connectDragPreview,
  connectDragSource,
  isDragging,
}) => {
  // dispatch dragState at all component when drag item ( redux yet )
  const {
    currentItem,
    action: { dropComponent, setCurrentItem, removeComponent },
    dnd: { dragState },
  }: any = useContext(ContributionContext);

  useEffect(() => {
    if (isDragging === true) {
      dragState({ id: item.id, position });
    }
    if (isDragging === false) {
      dragState(null);
    }
    // eslint-disable-next-line
  }, [isDragging]);


  return (
    <>
      {isDragging && (
        <DropTarget position={position} componentDropCallback={dropComponent} />
      )}
      {!isDragging && (
        <div>
          <DragPreviewImage
            src="/img/kards/iconeNoir.svg"
            connect={connectDragPreview}
          />
          <div
            onClick={() => {
              setCurrentItem(item.id);
            }}
            style={{ position: 'relative' }}
          >
            {currentItem === item.id
              && connectDragSource(
                <div>
                  <Overlay
                    up={(e) => {
                      e.stopPropagation();
                      moveComponent({ id: item.id, position: position - 1 });
                    }}
                    down={(e) => {
                      e.stopPropagation();
                      moveComponent({ id: item.id, position: position + 1 });
                    }}
                    delete={(e) => {
                      e.stopPropagation();
                      removeComponent(item.id);
                    }}
                    translation={DISPLAYED_TYPES[item.type]}
                  />
                </div>,
              )}
            <div
              style={{
                cursor: 'pointer',
                border: currentItem === item.id ? '2px solid #F2F2F2' : '',
              }}
            >
              <Component data={item} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DragSource('Component', source, collect)(EditingItem);
