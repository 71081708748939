import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data.value} />;

const wheel = {
  icone: '/img/components/wheel.svg',
  explain: '/img/components/explain/wheel.png',
  description:
    "Ce composant permet d'ajouter une roue du hasard. Exemple: 'Qui va payer la prochaine tournée ?' ",
  nameId: 'Roue du hasard',
  type: 'wheel',
  renderEdit,
};

export default wheel;
