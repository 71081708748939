import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
`;

export const Select = ({ options, ...props }) => (
  <Box display="flex" flexDirection="column" minHeight="3.5em">
    <ReactSelect
      defaultValue={props.input.value}
      isMulti={props.isMulti}
      options={options}
      value={!props.isMulti ? options.filter((option) => option.value === props.input.value) : console.log('null')}
      onChange={(v) => props.input.onChange(!props.isMulti ? v.value : v)}
      className={props.data_cy_select}
    />
    {props.meta.error && <ErrorField>{props.meta.error}</ErrorField>}
  </Box>
);
