/* eslint-disable max-len */
import React from 'react';
import { Filter, List, CreateButton } from 'react-admin';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ImageRounded from '@material-ui/icons/ImageRounded';

import { CardLinkPicture } from 'src/layout/List/ItemPicture';
import LoadingList from 'src/layout/List/Loading';
import SearchInput from 'src/layout/Search/Bar';
import EmptyComponent from 'src/layout/Empty';

const useStyles: any = makeStyles(() => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
}));

const ImagesTitle = () => <span>Images</span>;

export const ImageFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="name" alwaysOn />
    </Filter>
  );
};

export const Action = ({ basePath }) => (
  <Toolbar>
    <CreateButton basePath={basePath} data-cy="create-image" />
  </Toolbar>
);

export const Empty = ({ basePath }) => (
  <EmptyComponent
    basePath={basePath}
    titleText="Vous n'avez pas encore d'image."
    explainText="Créer votre première image"
    Image={ImageRounded}
  />
);

const FlexH = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 75vh;
`;

const GridList = (props) => {
  const {
    data, basePath, ids, resource,
  } = props;
  return (
    <FlexH data-cy="list-image">
      {ids
        && ids.map((id) => (
          <CardLinkPicture
            key={id}
            data={data[id]}
            basePath={basePath}
            resource={resource}
          />
        ))}
    </FlexH>
  );
};

const ImageLoadableList = ({ loading, ...props }) => (!loading ? <GridList {...props} /> : <LoadingList {...props} />);

const ImagesList = (props) => {
  const tag = useSelector((state) => state.tag);
  return (
    <List
      key={tag}
      filters={<ImageFilter />}
      actions={<Action {...props} />}
      filter={{}}
      empty={<Empty {...props} />}
      title={<ImagesTitle />}
      perPage={25}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <ImageLoadableList {...props} />
    </List>
  );
};

export default ImagesList;
