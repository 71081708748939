import React from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';

const Overlay = ({ ...props }) => (
  <div className="block-overlay">
    <div className="block-actions">
      <div className="block-overlay__actions">
        <button
          onClick={props.delete}
          style={{
            borderTopLeftRadius: '8px',
            borderBottom: 'none',
          }}
          type="button"
          id="action-remove"
        >
          <DragIndicatorIcon
            style={{
              width: '0.6em',
            }}
          />
        </button>
        <button
          style={{
            borderBottom: 'none',
          }}
          onClick={props.delete}
          type="button"
          id="action-remove"
        >
          <DeleteIcon
            style={{
              width: '0.6em',
            }}
          />
        </button>
        <button
          style={{
            borderBottom: 'none',
          }}
          onClick={props.up}
          type="button"
          id="action-remove"
        >
          <ArrowUpwardIcon
            style={{
              width: '0.6em',
            }}
          />
        </button>
        <button
          style={{
            borderBottom: 'none',
            borderTopRightRadius: '8px',
          }}
          onClick={props.down}
          type="button"
          id="action-remove"
        >
          <ArrowDownwardIcon
            style={{
              width: '0.6em',
            }}
          />
        </button>
      </div>
    </div>
  </div>
);

export default Overlay;
