/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import GalleryEdit from '../../cardComponents/CategoryGallery';
import { Components } from '../../wrapperCardComponents/items';

export default {
  image: '/img/template/Gallery.png',
  name: 'Mosaïque',
  id: 'Gallery',
  imageType: 'square',
  header: {
    title: false,
    image: true,
  },
  structure: () => (
    <>
      <GalleryEdit components={() => <Components />} />
    </>
  ),
};
