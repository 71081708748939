import React from 'react';
import { CreateButton } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Empty = ({
  basePath, titleText, explainText, Image, resource = null, location = null,
}) => (
  <Box width="100%" height="70vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" m="1em" color="primary.mainLight">
      <Image style={{ height: '10em', width: '10em' }} alt="" />
    </Box>
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" m="1em" color="primary.main">
      <Typography variant="h4">
        {titleText}
      </Typography>
    </Box>
    <CreateButton style={{ backgroundColor: '#222428', color: 'white' }} basePath={basePath} data-cy="create-resource" />
  </Box>
);

export default Empty;
