import React, { useContext } from 'react';

import { ContributionContext } from 'src/cards/edit/components/context/contribution';

import { DndDropTarget } from './components/dndDropTarget';
import { dragInProgress, BOTTOM } from './helpers';

export const DropTarget = ({ position, componentDropCallback }) => (
  <DndDropTarget
    position={position}
    componentDropCallback={componentDropCallback}
  />
);

export const PositionDropTarget = ({
  position, id, direction, isLast,
}) => {
  const {
    dnd: { isDraggingComponent },
    action: { dropComponent },
  } = useContext(ContributionContext);
  const renderComponent = dragInProgress({
    isDraggingComponent, id, position, direction,
  })
    || (isDraggingComponent && isLast);
  return (
    <>
      {renderComponent ? (
        <DropTarget
          componentDropCallback={dropComponent}
          position={position}
          id={id}
          direction={direction}
        />
      ) : null}
    </>
  );
};

export const InitialDropTarget = () => {
  const {
    dnd: { isDraggingComponent },
    action: { dropComponent },
  } = useContext(ContributionContext);

  return (
    ((isDraggingComponent && isDraggingComponent.position === 'init')
      || !isDraggingComponent) && (
      <DropTarget
        componentDropCallback={dropComponent}
        position={0}
        direction={BOTTOM}
      />
    )
  );
};
