/* eslint-disable max-len */
import React from 'react';
import { useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import { updateOfferAndPublish } from '../accessor/updateOfferAndPublish';

const UpdateOffer = ({ classes, offer }) => {
  const formState = useFormState();

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    const {
      values: {
        preorder,
        instant,
      },
    } = formState;

    updateOfferAndPublish({
      offer,
      preorder,
      instant,
    });
  };

  return (
    <Button
      variant="contained"
      type="submit"
      onClick={handleClick}
      className={classes}
      data-cy="btn-confirm"
    >
      Valider
    </Button>
  );
};

export default UpdateOffer;
