import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';


import { useNotify, useRedirect, useDataProvider } from 'react-admin';
import { TransparentBtn, PublishButtonConfirme } from 'src/layout/Buttons';
import { uploadToFirebase } from 'src/helpers/base64';

export default ({
  id, resource, modal = false, basePath = null,
}) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const formState = useFormState();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!formState.valid) {
      return;
    }
    setLoading(true);
    const dataWithUrl = await uploadToFirebase({ data: formState.values });
    await dataProvider.update(resource, { id, data: dataWithUrl });
    setLoading(false);
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    redirectTo('list', basePath);
  };

  if (modal) {
    return (
      <PublishButtonConfirme
        onAccept={handleClick}
        OpenButton={(props) => (
          <TransparentBtn variant="extended" onClick={props.handleOpen} data-cy="btn-edit-and-publish-resource">
            Enregistrer
          </TransparentBtn>
        )}
      />
    );
  }

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-edit-resource">
      {loading
        ? (
          <>
            <CircularProgress
              color="inherit"
              size={18}
              thickness={5}
            />
            <span>Upload de vos images en cours</span>
          </>
        ) : <span>Enregistrer</span>}
    </TransparentBtn>
  );
};
