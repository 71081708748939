import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import Carousel from '@bit/kards.kards-components.carousel';

import { usePopulateProducts } from 'src/data/hooks';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({
  data: {
    value: { products, title, description },
  },
}) => {
  const {
    theme,
  }:any = useContext(ThemeContext);

  const { data } = usePopulateProducts({ products });
  if (!products || data.length === 0) {
    return (
      <div style={{ margin: '10px' }}>
        <Skeleton variant="rect" width="100%" height={250} />
        <Skeleton width="50%" />
        <Skeleton width="60%" />
      </div>
    );
  }

  return <Carousel.component theme={theme} data={{ products: data, title, description }} width="20vw" />;
};
