import { forEach } from 'lodash';

const updateCompiledState = (orderStatus, step, boolean) => {
  forEach(orderStatus, (status, key) => {
    if (key.includes(step)) orderStatus[key] = boolean;
  });
};

export const S1 = 'new';
export const S2 = 'scheduled';
export const S3 = 'preparing';
export const S4 = 'ready';
export const S5 = 'delivered';

export const mapStatus = {
  new: 'S1',
  scheduled: 'S2',
  preparing: 'S3',
  ready: 'S4',
  delivered: 'S5',
};

export const mapStatusInverse = {
  S1,
  S2,
  S3,
  S4,
  S5,
};

export const InitCompiledStatus = {
  S1: true,
  S2: false,
  S3: false,
  S4: false,
  S5: false,

  S1_S2: true,
  S1_S3: true,
  S1_S4: true,
  S1_S5: true,

  S2_S3: false,
  S2_S4: false,
  S2_S5: false,
  S3_S4: false,
  S3_S5: false,

  S1_S2_S3: true,
  S1_S2_S4: true,
  S1_S2_S5: true,

  S2_S3_S4: false,
  S2_S4_S5: false,
  S3_S4_S5: false,

  S2_S3_S4_S5: true,
};

export const InitCompiledStatuswithPaymentDone = {
  S1: false,
  S2: false,
  S3: true,
  S4: false,
  S5: false,

  S1_S2: false,
  S1_S3: true,
  S1_S4: false,
  S1_S5: false,

  S2_S3: true,
  S2_S4: false,
  S2_S5: false,
  S3_S4: true,
  S3_S5: true,

  S1_S2_S3: true,
  S1_S2_S4: true,
  S1_S2_S5: true,

  S2_S3_S4: true,
  S2_S4_S5: false,
  S3_S4_S5: true,

  S2_S3_S4_S5: true,
};

export const getCurrentStep = (order) => {
  let currentStep;
  forEach(order.status, (status, key) => {
    if (status === true && mapStatusInverse[key]) {
      currentStep = key;
      return (currentStep);
    }
  });

  return mapStatusInverse[currentStep];
};

const commandeFlow = () => ({
  definition: {
    comment: 'command_flow',
    startAt: S1,
    states: {
      new: {
        before: S1,
        next: S3,
      },
      scheduled: {
        before: S1,
        next: S3,
      },
      preparing: {
        before: S2,
        next: S4,
      },
      ready: {
        before: S3,
        next: S5,
      },
      delivered: {
        end: true,
      },
    },
  },
});


export const getNextStepValue = (currentStep) => commandeFlow().definition.states[currentStep].next;

export const getNextStep = (order) => {
  let currentStep;
  forEach(order.status, (status, key) => {
    if (status === true && mapStatusInverse[key]) {
      currentStep = key;
      return (currentStep);
    }
  });
  const nextStep = commandeFlow().definition.states[
    mapStatusInverse[currentStep]
  ].next;
  if (!nextStep) return;
  order.status[currentStep] = false;
  order.status[mapStatus[nextStep]] = true;
  updateCompiledState(order.status, currentStep, false);
  updateCompiledState(order.status, mapStatus[nextStep], true);

  return order;
};
