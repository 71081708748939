/* eslint-disable max-len */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import styled from 'styled-components';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dinero from 'dinero.js';
import ProductRow from './ProductRow';
import OfferRow from './OfferRow';

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

const StyledTableCell = withStyles((theme): any => ({
  root: {
    borderBottom: 0,
  },
  head: {
    backgroundColor: '#222428',
    color: theme.palette.common.white,
    padding: '0 !important',
    fontSize: '.8em',
    fontWeight: '300',
    height: '3em',
  },
  body: {
    fontSize: '1em',
  },
  footer: {
    borderTop: '2px black solid',
    padding: '0 !important',
  },
}))(TableCell);


const ProductsList = ({ items }) => {
  let total = 0;

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell><Text paddingLeft=".5em" textAlign="center">Référence</Text></StyledTableCell>
          <StyledTableCell align="right"><Text textAlign="center">Quantité</Text></StyledTableCell>
          <StyledTableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(items, ({
          id, reference, quantity, price, options, type,
        }, index) => {
          total += price * quantity;
          if (type === 'offer') {
            return (
              <OfferRow
                key={id}
                id={id}
                reference={reference}
                quantity={quantity}
                price={price}
                options={options}
                index={index}
              />
            );
          }
          return (
            <ProductRow
              key={id}
              id={id}
              reference={reference}
              quantity={quantity}
              price={price}
              options={options}
              index={index}
            />
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <StyledTableCell
            colSpan={3}
            style={{ fontWeight: 'bold' }}
            component="th"
            scope="row"
          >
            <Text size="1.5em" height="2em" weight="bold" color="black" justifyContent="space-between" whiteSpace="nowrap">
              <span>Total</span>
              <span>
                {total && `${Dinero({ amount: Math.round(total), currency: 'EUR' }).toFormat('0.00')} €`}
              </span>
            </Text>
          </StyledTableCell>
        </TableRow>
      </TableFooter>
    </>
  );
};

export default ProductsList;
