import React from 'react';
import { Box } from '@material-ui/core';
import { useFormState, Field } from 'react-final-form';
import { Number as InputNumber } from 'src/form/components/input/number';
import  Dinero from 'dinero.js'

function getHTprice(formState: any) {
  if(!isNaN(formState.values.price)){
    console.log('HERE', parseInt(formState.values.price))
    if(formState.values.tva) {
      return Dinero({ amount: parseInt(formState.values.price) }).percentage(100-(formState.values.tva*100)).toFormat('0.00')
    }
    return Dinero({ amount: parseInt(formState.values.price) })
  }
  return 0
}
function getEuroPrice(formState: any) {
  if(!isNaN(formState.values.price)){
    console.log('HERE', parseInt(formState.values.price))
    return Dinero({ amount: parseInt(formState.values.price) }).toFormat('0.00')
  }
  return 0
}
export const PriceInEuro = () => {
  const formState = useFormState();

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb="10px"
      width="auto"
      flex="flex"
    >
      Prix en euro ( € )
      <p>{getEuroPrice(formState)}€</p>
    </Box>
  );
};
export function PriceWithoutTax() {
  const formState = useFormState();

  return (
    <Box display="flex" flexDirection="column" pb="10px" width="auto" flex="flex">
      Prix hors taxe ( € )
      <Field
        name="priceHT"
        render={({ input, meta }) => (
          <InputNumber disabled input={{ ...input, value: getHTprice(formState) }} meta={meta} />
        )}
      />
    </Box>
  );
}
