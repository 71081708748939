/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import GalleryCategoryInline from '../../cardComponents/CategoryGalleryInline';
import { Components } from '../../wrapperCardComponents/items';

export default {
  image: '/img/template/Inline.png',
  name: 'Galerie',
  id: 'GalleryInline',
  imageType: 'rectangleInline',
  header: {
    title: false,
    image: true,
  },
  structure: () => (
    <>
      <GalleryCategoryInline components={() => <Components />} />
    </>
  ),
};
