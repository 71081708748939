import React from 'react';
import { Create } from 'react-admin';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';
import { Back } from 'src/layout/Buttons';
import Form from '../components/form';
import { CreateToolbar } from '../components/bottomBar/index';

const ProductTitle = () => <span>Créer un produit</span>;

const ProductCreate = (props) => {
  const tag = useSelector((state) => state.tag);
  const id = uuid();
  return (
    <>
      <Back resource="/images" />
      <Create title={<ProductTitle />} {...props}>
        <Form {...props} initialValues={{ tag, id }} Toolbar={CreateToolbar} />
      </Create>
    </>
  );
};

export default ProductCreate;
