import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  FunctionField,
} from 'react-admin';
import { map } from 'lodash';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Back } from 'src/layout/Buttons';

const ContainerProducts = styled.div`
  border-radius: 5px;
  margin: 0.5em 1em;
  width: 50vw;
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ShowOrderTitle = ({ record }) => (
  <span>
    Commande /
    {record.key}
  </span>
);

const OrderShow = (props) => (
  <>
    <Back resource="/orders" />
    <Show {...props} title={<ShowOrderTitle />}>
      <SimpleShowLayout>
        <TextField data-cy="text-id-order" label="Numéro de commande" source="key" />
        <DateField data-cy="text-date-order" label="Date de commande" source="created_at" />
        <FunctionField
          label="Commande"
          source="items"
          render={({ items }) => <RenderOrder items={items} />}
        />
      </SimpleShowLayout>
    </Show>
  </>
);

const RenderOrder = ({ items }) => {
  const useStyles = makeStyles();
  const classes = useStyles();
  let calculeTotal = 0;
  return (
    <ContainerProducts>
      <Table size="small" styles={classes.table} data-cy="table-order">
        <TableHead>
          <TableRow>
            <StyledTableCell>ref.</StyledTableCell>
            <StyledTableCell align="right">qte.</StyledTableCell>
            <StyledTableCell align="right">pu.</StyledTableCell>
            <StyledTableCell align="right">pt.</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(items, ({
            id, reference, number, price,
          }, index) => {
            calculeTotal += parseFloat(price) * number;
            return (
              <TableRow
                data-cy="table-order-item"
                key={id}
                style={{ background: index % 2 === 0 ? 'white' : '#e2e2e2' }}
              >
                <StyledTableCell component="th" scope="row">
                  {reference}
                </StyledTableCell>
                <StyledTableCell align="right">{` x${number}`}</StyledTableCell>
                <StyledTableCell align="right">{`${price} €`}</StyledTableCell>
                <StyledTableCell align="right">
                  {`${price
                    * number} €`}
                </StyledTableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <StyledTableCell
              style={{ fontWeight: 'bold' }}
              component="th"
              scope="row"
            >
              Total
            </StyledTableCell>
            <StyledTableCell align="right" />
            <StyledTableCell align="right" />
            <StyledTableCell
              style={{ fontWeight: 'bold' }}
              align="right"
            >
              {`${calculeTotal} €`}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ContainerProducts>
  );
};

export default OrderShow;
