import React, { useContext } from 'react';
import Category from '@bit/kards.kards-components.categories-header';
import CategoryText from '@bit/kards.kards-components.categories-header-text';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

const CategoryList = () => {
  const {
    cardData,
    currentCategory,
    action: {
      setCurrentCategory,
    },
  }: any = useContext(ContributionContext);
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <Category.component
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
    />
  );
};

export const CategoryListWithText = () => {
  const {
    cardData,
    currentCategory,
    action: {
      setCurrentCategory,
    },
  }: any = useContext(ContributionContext);
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <CategoryText.component
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
    />
  );
};

export default CategoryList;
