import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import arrayMove from 'array-move';
import Typography from '@material-ui/core/Typography';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Field } from 'react-final-form';
import {
  AddListOffer,
} from 'src/layout/Buttons';
import { usePopulateOffer } from 'src/data/hooks';

const ListItem = styled.li`
  align-items: center;
  width:80%;
  background: white;
  display: flex;
  flex: 1;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 5px;
  flex-direction: row;
  height: 2.5em;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
`;


const ContainerItem = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 10px;
  width: 100%;
`;

const TitleZone = styled.div`
  flex: 0.9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContainerButton = styled.div`
  margin-top: 10px;
`;

const Item = ({ name }) => (
  <ListItem>
    <TitleZone>
      <Typography>{name}</Typography>
    </TitleZone>
  </ListItem>
);

const OfferComponent = ({ value, pop }) => {
  const { data } = usePopulateOffer({ offer: value });
  return (
    <>
      {data && data.reference && (
        <ContainerItem>
          <Item name={data.reference} pop={pop} />
        </ContainerItem>
      )}
    </>
  );
};

const ListOffer = ({
  name, many, title, subtitle,
}) => (
  <Field
    name={name}
    render={({ input }) => (
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        width="100%"
        pb="2em"
      >
        <ContainerButton>
          <AddListOffer
            title={title}
            subtitle={subtitle}
            many={many}
            initialData={input.value}
            onAccept={(itemsToAdd) => {
              input.onChange(itemsToAdd);
            }}
            OpenButton={(props) => (
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleOpen}
                data-cy="btn-add-products"
              >
                Ajouter une offre
              </Button>
            )}
          />
        </ContainerButton>
        <OfferComponent
          value={input.value}
        />
      </Box>
    )}
  />
);

export default ListOffer;
