import React from 'react';
import { Edit } from 'react-admin';
import { Back } from 'src/layout/Buttons';
import Form from '../components/form';
import { EditToolbar } from '../components/bottomBar/index';

const ProductTitle = ({ record }) => (
  <span>
    Images /
    {' '}
    {record.reference}
  </span>
);

const ProductEdit = (props) => (
  <>
    <Back resource="/images" />
    <Edit title={<ProductTitle {...props} />} {...props}>
      <Form initialValues={props.record} {...props} Toolbar={EditToolbar} />
    </Edit>
  </>
);

export default ProductEdit;
