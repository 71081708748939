/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

const ContainerGraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 25em;
  width: 100%;
`;

const CardGraph = styled.div`
  display: flex;
  flex: .65;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  margin: .5em;
  height: 100%;
  justify-content: space-between;
  background-color: white;
`;

const CardInformation = styled.div`
  display: flex;
  flex: .3;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.h3`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  font-size: 1em;
  flex-direction: column;
  padding: 1em;
`;


const GraphWrapper = (props) => {
  const commands = get(props, 'data');
  const [colorsGraph, setColors] = useState([]);
  const informationPosition = get(props, 'informationPosition');
  const nbCommande = commands.length;

  if (informationPosition === 'right') {
    return (
      <ContainerGraphWrapper>
        <CardGraph>
          <Title>{props.title}</Title>
          {React.cloneElement(<props.graph />, {
            setColors, legendActive: props.legendActive, data: props.data, unit: props.unit, filterKey: props.filterKey,
          })}
        </CardGraph>

        <CardInformation>
          {React.cloneElement(<props.informations />, {
            colorsGraph, total: props.total, unit: props.unit, nbCommande,
          })}
        </CardInformation>
      </ContainerGraphWrapper>
    );
  }
  if (informationPosition === 'left') {
    return (
      <ContainerGraphWrapper>
        <CardInformation>
          {React.cloneElement(<props.informations />, {
            colorsGraph, total: props.total, unit: props.unit, nbCommande,
          })}
        </CardInformation>

        <CardGraph>
          <Title>{props.title}</Title>
          {React.cloneElement(<props.graph />, {
            setColors, legendActive: props.legendActive, data: props.data, unit: props.unit, filterKey: props.filterKey,
          })}
        </CardGraph>
      </ContainerGraphWrapper>
    );
  }
  return null;
};

export default GraphWrapper;
