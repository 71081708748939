import firebase from 'firebase';
import get from 'lodash/get';
import map from 'lodash/map';
import { parseFireStoreDocument } from 'src/helpers/function';

export const getOrderByDate = async ({ startDate, endDate, status, setSearchDate }) => {
  console.log('Log: getOrderByDate -> startDate, endDate, status', startDate, endDate, status)
  const defaultFirestore = firebase.app().firestore();
  defaultFirestore
    .collection('orders')
    .where('created_at', '>', startDate)
    .where('created_at', '<', endDate)
    .where(`status.${status}`, '==', true)
    .get()
    .then((res) => {
      const result = [];
      if (get(res, 'docs') && get(res, 'docs').length > 0) {
        map(get(res, 'docs'), (doc) => {
          const docParsed = parseFireStoreDocument(doc);
          if (docParsed) result.push(docParsed);
        });
      }
      setSearchDate(result);
    })
    .catch((error) => {
      console.log('error', error);
    });
};
