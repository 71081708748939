import React from 'react';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Delete } from 'src/layout/Modal/components/Delete';

const actions = [
  { icon: <FileCopyIcon />, name: 'Dupliquer' },
  { icon: <DeleteIcon />, name: 'Supprimer' },
];

const useStyles = makeStyles(() => createStyles({
  speedDial: {

  },
}));

export const SpeedDialWithAction = ({
  resource,
  duplicateData = null,
  id,
  children,
  direction = 'down',
}:any) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const duplicate = duplicateData ? duplicateData() : null;
  const handleClickDuplicate = () => {
    console.log(duplicate, resource)

    dataProvider.create(resource, { data: duplicate }).then(() => {
    notify('ra.notification.created', 'info', { smart_count: 1 });
    refresh();
  });
}
  const handleClickDelete = () => dataProvider.delete(resource, { id }).then(() => {
    notify('ra.notification.deleted', 'info', { smart_count: 1 });
    refresh();
  });

  return (
    <>
      <SpeedDial
        ariaLabel="action button"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        className={classes.speedDial}
        direction={direction}
        data-cy="btn-speed-dial"
      >
        {actions.map((action) => (action.name === 'Supprimer' ? (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => setOpenDeleteModal(true)}
            data-cy="btn-speed-dial-delete"
          />
        ) : action.name === 'Dupliquer' && duplicateData && (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClickDuplicate}
            data-cy="btn-speed-dial-duplicate"
          />
        )))}
      </SpeedDial>
      <Delete
        IconConfirm={<DeleteIcon />}
        onClose={() => setOpenDeleteModal(false)}
        onAccept={handleClickDelete}
        textButton="Supprimer"
        openFromParent={openDeleteModal}
      >
        {children}
      </Delete>
    </>
  );
};
