import React from 'react';
import TextField from '@material-ui/core/TextField';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { getOneTable } from '../accessor/getOneTable';

const SearchInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#cacaca',
        color: '#cacaca',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& input': {
      color: 'white',
    },
    '& input:valid + fieldset': {
      borderColor: 'white',
      borderWidth: 2,
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderColor: 'white',
      padding: '4px !important', // override inline-style
    },
  },
})(TextField);


export const GetOneTable = ({ setSearch }) => {
  const onSearch = async (id) => {
    getOneTable(id, setSearch);
  };

  const handleChange = (event) => onSearch(get(event, 'target.value'));

  return (
    <SearchInput
      onChange={handleChange}
      label="Numéro de table"
      variant="outlined"
      data-cy="input-search-table"
    />
  );
};
