import { omit } from 'lodash';
import { upload } from '../data/accessor/uploadImage';
import { configuration } from '../App';

export const uploadToFirebase = async ({ data }) => {
  const dataWithUrl = { ...data };
  if (dataWithUrl.edit && dataWithUrl.edit.original) {
    await upload({ imageId: dataWithUrl.id, imagetoUpload: { base64: dataWithUrl.edit.original, crop: omit(dataWithUrl.edit, 'original') } });
    dataWithUrl.edit.original = `https://firebasestorage.googleapis.com/v0/b/${configuration.projectId}.appspot.com/o/images%2F${dataWithUrl.id}%2Foriginal?alt=media&token=${Date.now()}`;
  }
  return dataWithUrl;
};
