import React from 'react';
import { useWatchPreparatorOrder } from 'src/data/hooks/watchPreparatorOrders';
import ListOrders from './list/ListOrders';

const PreparatorView = ({ entitieWatch, low = false, preparing }) => {
  const data = useWatchPreparatorOrder({ entitieWatch, low, preparing });
  return <ListOrders data={data} low={low} preparing={preparing} />;
};

export default PreparatorView;
