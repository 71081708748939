import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import Status from 'src/layout/Status';

import {
  TextInputField,
  InputTimeField,
  PaimentInputField,
  DeliveryInputField,
  DebitInputField,
  NoOrderInputField,
} from 'src/form/components/field/index';

import 'moment/min/locales';

const StyledCategory = styled.h3`
  color: #b5b5b5;
  font-size: 1.2em;
  margin-bottom: 1em;
`;

const StyledInformation = styled.h4`
  color: #d8d8d8;
  font-size: 0.9em;
  margin: 1em 0;
`;

const StyledText = styled.p`
  color: #ffffff;
  font-size: 1em;
  padding: 0;
`;

export default ({ record }) => (
  <Box display="flex" flexDirection="row" flex={1}>
    <Box flex={2} padding="1em" overflow="scroll" height="75vh">
      <TextInputField
        required
        name="title"
        label="TITRE"
        data_cy_input="input-name-card"
        pb="1em"
        mb="2em"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
      <InputTimeField
        pb="1em"
        mb="2em"
        required
        name="slots"
        label="AJOUTER UN CRENEAUX DE VISIBILITÉ"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
      <PaimentInputField
        pb="1em"
        mb="2em"
        validate={(v) => !v.card && !v.cash}
        name="payment_mode"
        label="MODE DE PAIEMENT"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
      <DeliveryInputField
        pb="1em"
        mb="2em"
        validate={(v) => !v.delivered && !v.onplace}
        name="delivery"
        label="MODE DE RÉCUPÉRATION DE COMMANDE"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
      <NoOrderInputField
        pb="1em"
        mb="2em"
        name="order_mode"
        label="MODE DE COMMANDES"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
      <DebitInputField
        pb="1em"
        mb="2em"
        name="debit_mode"
        label="GESTION DU TEMPS DE PRÉPARATION"
        borderBottom="solid 2px #5bfcc2"
        labelWeight="bold"
        labelSize="1.2em"
      />
    </Box>
    <Box flex={0.7} display="flex" flexDirection="column" padding="20px">
      <Box
        flex={1}
        padding="20px"
        bgcolor="primary.main"
        display="flex"
        flexDirection="column"
        borderRadius="borderRadius"
        boxShadow="0 4px 16px rgba(0,0,0,.12);"
      >
        <StyledCategory>INFORMATIONS</StyledCategory>
        {record.lastupdate && (
          <Box display="flex" alignItems="flex-start" flexDirection="column" padding="1em">
            <StyledInformation>Dernière mise à jour :</StyledInformation>
            <StyledText>{moment(record.lastupdate).format('DD.MM.YY à HH:mm')}</StyledText>
          </Box>
        )}
        <Box display="flex" alignItems="flex-start" flexDirection="column" padding="1em">
          <StyledInformation>Créée le :</StyledInformation>
          <StyledText>{moment(record.createdate).format('DD.MM.YY à HH:mm')}</StyledText>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column" padding="1em">
          <StyledInformation>Créée par : </StyledInformation>
          <StyledText>{record.createdby}</StyledText>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column" padding="1em">
          <StyledInformation>Statut : </StyledInformation>
          <div style={{ marginLeft: '10px' }}>
            <Status status={record.status} />
          </div>
        </Box>
      </Box>
    </Box>
  </Box>
);
