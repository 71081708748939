import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Switch as MaterialSwitch } from '@material-ui/core';

const MaterialSwitchCustom = withStyles({
  root: {
    width: '4em',
  },
  switchBase: {
    padding: '6px 4px',
    color: 'rgb(240, 83, 83)',
    '&$checked': {
      color: 'white',
      padding: '6px 12px',
    },
    '&$checked + $track': {
      backgroundColor: '#6da993',
    },
  },
  thumb: {
    width: '1.1em',
    height: '1.1em',
    boxShadow: 'none',
  },
  checked: {},
  track: {
    height: '1em',
    width: '3em',
    borderRadius: '20px',
  },
})(MaterialSwitch);

export const Switch = ({ ...props }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <MaterialSwitchCustom
      size="medium"
      checked={props.checked}
      onChange={props.onChange}
    />
  </Box>
);
