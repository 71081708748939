import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: ${(props) => (props.mobile ? '80%' : '40%')};
  border-radius: 5px;
`;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(1),
    color: 'white',
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.secondary,
    },
  },
  paper: {
    width: '70%',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Delete = ({
  onClose,
  onAccept,
  children = null,
  OpenButton = null,
  IconConfirm = null,
  mobile = false,
  textButton,
  openFromParent,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    return setOpen(false);
  };

  const handleAccept = () => {
    if (onAccept) onAccept();
    return setOpen(false);
  };

  return (
    <>
      {OpenButton && React.cloneElement(OpenButton, { handleOpen })}
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={OpenButton ? open : openFromParent}
        onClose={handleClose}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <>
          <ModalWrapper mobile={mobile}>
            <Box>
              <img alt="triangle" src="/img/utils/triangle.svg" width="80px" />
            </Box>
            <Box
              margin="20px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              {children}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Button
                variant="contained"
                data-cy="btn-modal-confirme"
                onClick={handleAccept}
                className={classes.error}
                startIcon={IconConfirm}
              >
                {textButton}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClose}
              >
                Fermer
              </Button>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
};
