import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import Top from '../cardEdit/components/view/cardTemplate/Top';
import TopWithText from '../cardEdit/components/view/cardTemplate/TopWithText';

import Gallery from '../cardEdit/components/view/cardTemplate/Gallery';
import GalleryInline from '../cardEdit/components/view/cardTemplate/GalleryInline';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgcolor};
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 17vw;
  height: 40vh;
`;

export default ({ onChange, ...props }) => (
  <Box display="flex" flex=".9" flexWrap="wrap" justifyContent="flex-start" alignItems="center" flexDirection="row">
    <StyledBox
      onClick={() => {
        onChange(Top.id);
      }}
      color={props.value === Top.id ? 'secondary.main' : 'primary.main'}
      bgcolor={props.value === Top.id ? 'primary.main' : 'secondary.main'}
    >
      <div>
        <Box padding="1em" fontWeight="400">{Top.name}</Box>
      </div>
      <div>
        <img alt="top_template" style={{ cursor: 'pointer', height: '30vh', width: '17vw' }} src={Top.image} />
      </div>
    </StyledBox>
    <StyledBox
      onClick={() => { onChange(Gallery.id); }}
      color={props.value === Gallery.id ? 'secondary.main' : 'primary.main'}
      bgcolor={props.value === Gallery.id ? 'primary.main' : 'secondary.main'}
    >
      <div>
        <Box padding="1em" fontWeight="400">{Gallery.name}</Box>
      </div>
      <div>
        <img alt="galery_template" style={{ cursor: 'pointer', height: '30vh', width: '17vw' }} src={Gallery.image} />
      </div>
    </StyledBox>
    <StyledBox
      onClick={() => { onChange(GalleryInline.id); }}
      color={props.value === GalleryInline.id ? 'secondary.main' : 'primary.main'}
      bgcolor={props.value === GalleryInline.id ? 'primary.main' : 'secondary.main'}
    >
      <div>
        <Box padding="1em" fontWeight="400">{GalleryInline.name}</Box>
      </div>
      <div>
        <img alt="inline_template" style={{ cursor: 'pointer', height: '30vh', width: '17vw' }} src={GalleryInline.image} />
      </div>
    </StyledBox>
    <StyledBox
      onClick={() => { onChange(TopWithText.id); }}
      color={props.value === TopWithText.id ? 'secondary.main' : 'primary.main'}
      bgcolor={props.value === TopWithText.id ? 'primary.main' : 'secondary.main'}
    >
      <div>
        <Box padding="1em" fontWeight="400">{TopWithText.name}</Box>
      </div>
      <div>
        <img alt="inline_template" style={{ cursor: 'pointer', height: '30vh', width: '17vw' }} src={TopWithText.image} />
      </div>
    </StyledBox>
  </Box>
);
