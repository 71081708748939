import firebase from 'firebase';
import get from 'lodash/get';
import { getNextStep, getCurrentStep } from 'src/orders/flow';

export const updateStepOrder = async (order) => {
  const defaultFirestore = firebase.app();
  const updateOrderStep = getNextStep(order);
  const nextStep = getCurrentStep(updateOrderStep);

  if (nextStep === 'delivered') updateOrderStep.delivered_at = Date.now();
  await defaultFirestore
    .firestore()
    .collection('orders')
    .doc(get(order, 'id'))
    .update(updateOrderStep);
  return updateOrderStep;
};

export const updateOrder = async (order) => {
  const defaultFirestore = firebase.app();

  await defaultFirestore
    .firestore()
    .collection('orders')
    .doc(get(order, 'id'))
    .update(order);
  return order;
};
