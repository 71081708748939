/* eslint-disable max-len */
import firebase from 'firebase';
import { forEach, map } from 'lodash';
import { parseFireStoreDocument } from '../../helpers/function';

export function compareSlot({ cards, currentSlots, currentId }) {
  const today = new Date();
  let slotAvailable = true;
  // curent array date slot start and end
  const currentDateSlot = map(
    currentSlots, (slot) => {
      const startHours = slot.start.split(':')[0];
      const startMin = slot.start.split(':')[1];
      const endHours = slot.end.split(':')[0];
      const endMin = slot.end.split(':')[1];
      return {
        start: new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(startHours, 10), parseInt(startMin, 10), 1),
        end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(endHours, 10), parseInt(endMin, 10), 1),
      };
    },
  );

  // registered array date slot start and end
  const registeredDateSlot = [];
  forEach(cards, (card) => {
    if (card.id !== currentId || currentId === 'create') {
      forEach(card.slots, (slot) => {
        const startHours = slot.start.split(':')[0];
        const startMin = slot.start.split(':')[1];
        const endHours = slot.end.split(':')[0];
        const endMin = slot.end.split(':')[1];
        const slotStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(startHours, 10), parseInt(startMin, 10), 1);
        const slotEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(endHours, 10), parseInt(endMin, 10), 1);
        registeredDateSlot.push({ start: slotStart, end: slotEnd });
      });
    }
  });

  forEach(currentDateSlot, (current) => {
    forEach(registeredDateSlot, (registered) => {
      if (registered.start > registered.end) {
        if ((current.start < registered.end || current.end < registered.end)
          || (current.start > registered.start || current.end > registered.start)
        ) {
          slotAvailable = false;
        }
      } else if (
        (current.start >= registered.start && current.start <= registered.end)
        || (current.end >= registered.start && current.end <= registered.end)
      ) {
        slotAvailable = false;
      }
    });
  });
  return slotAvailable;
}

export const checkSlotUnicity = async ({ currentId, currentSlots }) => {
  const defaultFirestore = firebase.app();
  const dataCards = await defaultFirestore
    .firestore()
    .collection('cards')
    .get();
  const cards = map(dataCards.docs, (val) => parseFireStoreDocument(val));
  return compareSlot({ cards, currentId, currentSlots });
};
