
import firebase from 'firebase';
import get from 'lodash/get';
import { parseFireStoreDocument } from 'src/helpers/function';

export const getProducts = async () => firebase
  .app()
  .firestore()
  .collection('products').get()
  .then((res) => {
    const data = [];
    res.docs.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  });
