import DashboardIcon from '@material-ui/icons/MapOutlined';
import list from './list';
import create from './create';
import edit from './edit';
// import '../../client/theme/variables.css';

export default {
  list,
  create,
  edit,
  icon: DashboardIcon,
};
