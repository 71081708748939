import React from 'react';
import { FunctionField } from 'react-admin';
import styled from 'styled-components';
import { getCurrentStep } from '../flow';

const Status = styled(FunctionField)`
  padding: 0.2em 1em;
  border-radius: 12px;
  background: lightgray;
`;

const StatusField = ({ record, label }) => {
  const currentStatus = getCurrentStep(record);
  return record ? <Status label={label} render={() => currentStatus} /> : null;
};

export default StatusField;
