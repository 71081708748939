/* eslint-disable no-nested-ternary */
import React, { useMemo, useContext, useState } from 'react';

import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';

import DraggableComponent from '../../dragAndDrop/components/draggable';
import ComponentList from '../../view/cardComponents/SimpleList';
import Separator from '../../view/cardComponents/Separator';
import Carousel from '../../view/cardComponents/Carousel';
import Product from '../../view/cardComponents/Product';
import Image from '../../view/cardComponents/Image';
import Video from '../../view/cardComponents/Video';
import Wheel from '../../view/cardComponents/Wheel';
import ImageSlides from '../../view/cardComponents/ImageSlides';
import Social from '../../view/cardComponents/Social';
import Offer from '../../view/cardComponents/Offer';


const IconeWrapper = styled.div`
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 4px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const ContainerList = styled.div`
  height: 65vh;
  overflow: scroll;
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  cursor: move;
  cursor: grab;
  :active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

const ImageExplain = styled.img`
  width: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  padding: 10px;
`;

const ComponentItem = styled.div`
  cursor: move;
  cursor: grab;
  display: flex;
  align-items: center;
  :active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;
const ExpansionPanelWrapper = styled(ExpansionPanel)`
  margin: 0 2px;
`;

export default () => {
  const {
    dnd: { dragState },
  }: any = useContext(ContributionContext);
  const [expanded, setExpanded] = useState('false');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const componentList = useMemo(
    () => [Carousel, Product, ComponentList, Separator, Image, Video, Wheel, ImageSlides, Social, Offer],
    [],
  );

  return (
    <ContainerList>
      {' '}
      {componentList.map((component) => (
        <ExpansionPanelWrapper
          key={component.nameId}
          expanded={expanded === component.nameId}
          onChange={handleChange(component.nameId)}
          data-cy={`draggable-component-${component.type}`}
        >
          <ExpansionPanelSummary
            key={component.id}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <DraggableComponent
              key={component.type}
              dragState={dragState}
              type={component.type}
              initValue={component.init}
            >
              <ComponentItem>
                <div style={{ flex: '0.15' }}>
                  <img alt="component" src={component.icone} width="25" />
                </div>
                <div style={{ flex: '0.85' }}>
                  <Typography>{component.nameId}</Typography>
                </div>
                <IconeWrapper onClick={() => handleChange(component.nameId)}>
                  <img alt="question" width="10px" src="/img/icon/question.svg" />
                </IconeWrapper>
              </ComponentItem>
            </DraggableComponent>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
            {component.description}
            <DraggableComponent
              key={component.type}
              dragState={dragState}
              type={component.type}
              initValue={component.init}
            >
              <ImageWrapper>
                <ImageExplain src={component.explain} />
              </ImageWrapper>
            </DraggableComponent>
          </ExpansionPanelDetails>
        </ExpansionPanelWrapper>
      ))}
    </ContainerList>
  );
};
