import React from 'react';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ListOffer } from './OfferList';
import Modal from './Modal';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export const AddListOffer = ({
  OpenButton,
  onAccept,
  initialData,
  title,
  subtitle,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onAccept(selected);
    setSelected([]);
    setOpen(false);
  };

  return (
    <>
      {OpenButton && React.cloneElement(OpenButton, { handleOpen })}
      <Modal
        handleClose={handleClose}
        open={open}
        title={title}
        subtitle={subtitle}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          width="100%"
          overflow="hidden"
        >
          <ListOffer
            initialData={initialData}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.button}
            onClick={handleConfirm}
            data-cy="btn-validate-products"
          >
            Valider
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={classes.button}
            onClick={handleClose}
          >
            Fermer
          </Button>
        </Box>
      </Modal>
    </>
  );
};
