export const ADMIN = 'admin';
export const PREPARATOR = 'preparator';
export const PRODUCTOR = 'productor';
export const RUNNER = 'runner';

export const getRoles = () => [
  ADMIN,
  PREPARATOR,
  PRODUCTOR,
  RUNNER,
];
