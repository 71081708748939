/* eslint-disable max-len */
import React, { useState } from 'react';
import List from '@material-ui/core/List';
import {
  ListItem, ListItemIcon, ListItemText, ListItemAvatar,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useRedirect } from 'react-admin';
import { map, get } from 'lodash';
import styled from 'styled-components';
import { getUrlImage } from 'src/helpers/image';

import { GetImagesList } from 'src/data/injector/GetImage';

const ListWrapper = styled(List)`
  overflow: scroll;
  width: 70%;
  flex: .9;
`;

const ListItemWrapper = styled(ListItem)`
  flex-direction: column;
  align-items: flex-end !important;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ListItemIconWrapper = styled(ListItemIcon)`
  min-width: 0 !important;
`;

const ButtonRedirect = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8em;
  cursor: pointer;
  padding: .2em;
  &:hover{
    color: ${(props) => (props.hover ? props.hover : 'red')};
  }
`;

export const ListImage = ({
  images, handleToggle, selected, type, redirectTo,
}) => {
  if (images && images.length > 0) {
    return map(
      images,
      (image, index) => {
        const labelId = `checkbox-list-label-${image.id}`;
        const { id } = image;
        return (
          <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
            <ListItemWrapper data-cy="item-image-to-add" key={index} button onClick={() => handleToggle(image)}>
              <Row>
                <ListItemAvatar>
                  <Avatar alt="image" src={getUrlImage({ id, type: 'original', image })} />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={image.name} />
                <ListItemIconWrapper>
                  {
                    image.id
                      && selected
                      && selected.indexOf(getUrlImage({ id, type, image })) !== -1
                      ? <CheckBox color="primary" />
                      : <CheckBoxOutlineBlankIcon color="disabled" />
                  }
                </ListItemIconWrapper>
              </Row>
              <Divider light width="93%" />
            </ListItemWrapper>
            {type !== 'original' && !get(image, `read.${type}`)
              && (
              <ButtonRedirect
                onClick={() => redirectTo('edit', '/images', id)}
              >
                <ErrorOutlineIcon />
                <Box p=".2em">
                  Non optimisé
                </Box>
              </ButtonRedirect>
              )}
          </Box>
        );
      },
    );
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p="4em 0">
      {'Vous n\'avez pas encore d\'image, pour en créer une cliquez ici:'}
      <Box p=".3em">
        <Button
          onClick={() => redirectTo('create', '/images')}
          size="small"
          variant="outlined"
          color="primary"
        >
          Nouvelle image
        </Button>
      </Box>
    </Box>
  );
};

export const ImageList = ({
  many, selected, setSelected, type,
}) => {
  const [search, setSearch] = useState([]);
  const redirectTo = useRedirect();
  const handleToggle = (image) => {
    const { id } = image;
    if (many) {
      const currentIndex = selected.indexOf(getUrlImage({ id, type, image }));
      const newChecked = [...selected];
      if (currentIndex === -1) {
        newChecked.push(getUrlImage({ id, type, image }));
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelected(newChecked);
    } else {
      setSelected(getUrlImage({ id, type, image }));
    }
  };

  return (
    <>
      <GetImagesList setSearch={setSearch} />
      <ListWrapper data-cy="list-image-to-add">
        <ListImage images={search} handleToggle={handleToggle} selected={selected} type={type} redirectTo={redirectTo} />
      </ListWrapper>
    </>
  );
};
