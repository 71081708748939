/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { FormWithRedirect } from 'react-admin';
import { Box } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';

import MainScreen from 'src/layout/Screen/Main';
import { FielMiddayNight, ArrayContractor } from 'src/form/components/field/index';
import get from 'lodash/get';
import { Tooltip } from 'src/layout/Tooltip';
import Toolbar from '../bottomBar/index';

// 'open.monday.midday.start' = props.record.open.monday.midday.start
export default ({ ...props }) => {
  const initialeValues = {
    contractors: get(props, 'record.contractors'),
    open: {
      monday: {
        midday: {
          start: get(props, 'record.open.monday.midday.start') || '10:00',
          end: get(props, 'record.open.monday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.monday.night.start') || '17:00',
          end: get(props, 'record.open.monday.night.end') || '22:00',
        },
      },
      tuesday: {
        midday: {
          start: get(props, 'record.open.tuesday.midday.start') || '10:00',
          end: get(props, 'record.open.tuesday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.tuesday.night.start') || '17:00',
          end: get(props, 'record.open.tuesday.night.end') || '22:00',
        },
      },
      wednesday: {
        midday: {
          start: get(props, 'record.open.wednesday.midday.start') || '10:00',
          end: get(props, 'record.open.wednesday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.monday.night.start') || '17:00',
          end: get(props, 'record.open.monday.night.end') || '22:00',
        },
      },
      thursday: {
        midday: {
          start: get(props, 'record.open.thursday.midday.start') || '10:00',
          end: get(props, 'record.open.thursday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.thursday.night.start') || '17:00',
          end: get(props, 'record.open.thursday.night.end') || '22:00',
        },
      },
      friday: {
        midday: {
          start: get(props, 'record.open.friday.midday.start') || '10:00',
          end: get(props, 'record.open.friday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.friday.night.start') || '17:00',
          end: get(props, 'record.open.friday.night.end') || '22:00',
        },
      },
      saturday: {
        midday: {
          start: get(props, 'record.open.saturday.midday.start') || '10:00',
          end: get(props, 'record.open.saturday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.saturday.night.start') || '17:00',
          end: get(props, 'record.open.saturday.night.end') || '22:00',
        },
      },
      sunday: {
        midday: {
          start: get(props, 'record.open.sunday.midday.start') || '10:00',
          end: get(props, 'record.open.sunday.midday.end') || '15:00',
        },
        night: {
          start: get(props, 'record.open.sunday.night.start') || '17:00',
          end: get(props, 'record.open.sunday.night.end') || '22:00',
        },
      },
    },
  };

  return (
    <FormWithRedirect
      key={props.version}
      initialValues={initialeValues}
      render={({
        form,
      }) => (
        <form>
          <MainScreen
            data_cy_title="title-edit-application"
            data_cy_subtitle="subtitle-edit-application"
            title="Configuration générale"
            subtitle="Réglages de l'application"
            flex={1}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              borderBottom="solid #5bfcc2"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="90%"
                fontSize="1.2em"
                fontWeight="400"
                maxWidth="1000px"
              >
                Réglage des créneaux horaires d'ouverture
                <Tooltip
                  text="Ces creneaux servent a 2 choses, premierement définir les creneaux de retrait pour les precommandes, deuxiemement definir les heures de disponibilité de vos produit sur place"
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="90%"
                margin="2em"
                maxWidth="1000px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  pr="0px"
                  pl="0px"
                  pb=".5em"
                  mb=".5em"
                  width="100%"
                  alignItems="center"
                  justifyContent="flex-start"
                  borderBottom="solid 2px #222428"
                >
                  <Box flex=".1" />
                  <Box flex=".9" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box flex=".5" display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400">Midi</Box>
                    <Box flex=".5" display="flex" alignItems="center" justifyContent="center" fontSize="1.2em" fontWeight="400">Soir</Box>
                  </Box>
                </Box>
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.monday"
                  label="Lundi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.tuesday"
                  label="Mardi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.wednesday"
                  label="Mercredi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.thursday"
                  label="Jeudi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.friday"
                  label="Vendredi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.saturday"
                  label="Samedi"
                />
                <FielMiddayNight
                  pb=".5em"
                  mb=".5em"
                  name="open.sunday"
                  label="Dimanche"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              borderBottom="solid #5bfcc2"
            >
              <Box
                width="90%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                fontSize="1.2em"
                fontWeight="400"
                maxWidth="1000px"
              >
                Vos fournisseurs
                <Tooltip
                  text="Vos fournisseurs pourons être ajoutés aux paramètres de vos produits. Cette donnée sera utilisée dans l'export de donnée de l'écran “Tableau de bord“."
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="90%"
                margin="2em"
                maxWidth="1000px"
              >
                <ArrayContractor
                  name="contractors"
                  label="Ajouter un fournisseur"
                />
              </Box>
            </Box>
          </MainScreen>
          <Box
            bgcolor="primary.main"
            flex={0.05}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar resource={props.resource} />
          </Box>
        </form>
      )}
    />
  );
};
