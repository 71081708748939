import React from 'react';
import {
  IonList,
  IonItem,
  IonText,
  IonCol,
  IonModal,
  IonRow,
  IonTitle,
  IonButton,
  IonToolbar,
} from '@ionic/react';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';

const IonListWrapper = styled(IonList)`
  margin: 1.5em;
  border-radius: 10px;
  box-shadow: ${(props) => props.boxShadow};
`;

const IonItemWrapper = styled(IonItem)`
  padding: 5px;
  flex: 1;
`;
const IonTextPrice = styled(IonText)`
  border: ${(props) => colors[props.color]} solid 1px;
  border-radius: 15px;
  padding: 0 7px;
  font-size: 0.8em;
`;

const IonbuttonWrapper = styled(IonButton)`
  --padding-start: 0;
  --padding-end: 0;
  width: 45%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  height: 100vh;
`;
const Header = styled.header`
  width: 100%;
  height: 7%;
  background-color: white;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  height: 83%;
`;

const Footer = styled.footer`
  width: 100%;
  height: 10%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const ModalResumeOrder = ({ order, handleClose, open }) => {
  let total = 0;
  return (
    <IonModal
      isOpen={open}
      showBackdrop={false}
    >
      <MainContainer>
        <Header>
          <IonToolbar mode="ios">
            <IonTitle>
              {' '}
              N° &nbsp;
              {' '}
              {get(order, 'key')}
            </IonTitle>
          </IonToolbar>
        </Header>
        <MainContent>
          <IonListWrapper boxShadow="0px 0px 20px 0px rgba(10,10,10,.15)" lines="none">
            {map(get(order, 'items'), ({
              price, id, number, reference,
            }) => {
              total += price * number;
              return (
                <IonItemWrapper
                  key={id}
                  lines="none"
                >
                  <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                    <IonText>{reference}</IonText>
                  </IonCol>
                  <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                    <IonRow className="ion-justify-content-end">
                      <IonText>
                        x
                        {number}
                      </IonText>
                    </IonRow>
                  </IonCol>
                  <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                    <IonRow className="ion-justify-content-end">
                      <IonTextPrice>
                        {price}
                        {' '}
                        €
                      </IonTextPrice>
                    </IonRow>
                  </IonCol>
                </IonItemWrapper>
              );
            })}
            <IonItemWrapper>
              <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                <IonText style={{ fontWeight: 'bold' }}>Total</IonText>
              </IonCol>
              <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                <IonRow className="ion-justify-content-end">
                  <IonText style={{ fontWeight: 'bold' }}>{`${total} €`}</IonText>
                </IonRow>
              </IonCol>
            </IonItemWrapper>
          </IonListWrapper>
        </MainContent>
        <Footer>
          <IonbuttonWrapper color="dark" onClick={handleClose}>
            FERMER
          </IonbuttonWrapper>
        </Footer>
      </MainContainer>
    </IonModal>
  );
};
