import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

export const GetListProduct = ({
  children, tag, setError, search, setTag, setSearch
}) => {
  const dataProvider = useDataProvider();
  const [products, setProduct] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);

  useEffect(() => {
    const confSearch: any = {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'reference', order: 'ASC' },
    };
    // eslint-disable-next-line
    
    if (tag && tag !== 'all') {
      setSearch(null)
      confSearch.filter = { tag }
    }

    if (search) {
      setTag('all')
      confSearch.filter = { reference: search }
    }

    if ((!tag || tag === 'all') &&  !search) {
      confSearch.filter = { }
    }

    dataProvider
      .getList('products', confSearch)
      .then(({ data }) => {
        setProduct(data);
        setFilterProduct([]);
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line
  }, [tag, search]);

  return React.cloneElement(children, { products, filterProduct });
};

export default GetListProduct;
