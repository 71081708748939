import React from 'react';

import CreateUser from 'src/data/injector/CreateUser';

export const EditButton = () => <CreateUser />;

export function Toolbar({ Save, ...props }) {
  return (
    <>
      <div />
      <Save {...props} />
      <div />
    </>
  );
}
