/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import get from 'lodash/get';
import { getCurrentPublishedCard } from './getCurrentPublishedCard';
import publishCard from './publishCard';

export const updateProductAndPublish = async ({
  preorder,
  instant,
  product,
  stock,
  disponibility,
  setStock,
}) => {
  const productId = get(product, 'id');
  if (!stock.unlimited) stock.unlimited = false;

  product.disponibility = disponibility || null;
  product.stock = stock;
  product.preorder = preorder || false;
  product.instant = instant || false;

  const defaultFirestore = firebase.app();
  try {
    const pwa = await getCurrentPublishedCard();
    await defaultFirestore
      .firestore()
      .collection('products')
      .doc(productId)
      .update(product);

    setStock(stock);
    if (get(pwa, 'cardId')) {
      await publishCard({ cardId: pwa.cardId });
    }
  } catch (e) {
    console.log(e)
  }
};
