/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { InputProps } from 'ra-core';
import styled from 'styled-components';
import { useInput } from 'react-admin';

const InputWrapper = styled.div`
  display: flex;
  background: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  padding-left: 10px;
  margin-bottom: 5px;
  justify-content: flex-start;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 1em;
  align-items: center;
`;

const Input = styled.input`
  padding: 11px;
  width: 90%;
  background: white;
  font-size: 1em;
  border: none;
  :focus {
    outline-width: 0;
  }
`;
export const BoundedTextField = (props) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  return (
    <InputWrapper>
      <img alt="search" width="5%" src="/img/icon/search.svg" />
      <Input
        placeholder="Recherche..."
        required={isRequired}
        data-cy="search"
        helperText={touched && error}
        error={!!(touched && error)}
        name={name}
        onChange={onChange}
        label={props.label}
        {...props}
      />
    </InputWrapper>
  );
};

const SearchInput: FunctionComponent<InputProps<TextFieldProps> &
Omit<TextFieldProps, 'label' | 'helperText'>> = ({
  classes: classesOverride,
  ...props
}) => <BoundedTextField source={props.source} />;

export default SearchInput;
