import React from 'react';

import {
  Delete,
  CropImage,
  Publish,
  AddListProducts,
  AddListImages,
  Confirm,
  Stock,
  SettingOffer,
  AddListOffer,
} from './components';

export const ModalStock = (props) => <Stock {...props} />;

export const ModalSetting = (props) => <SettingOffer {...props} />;

export const ModalConfirm = (props) => <Confirm {...props} />;

export const ModalDelete = (props) => <Delete {...props} />;

export const ModalPublish = (props) => <Publish {...props} />;

export const ModalAddListProducts = (props) => <AddListProducts {...props} />;

export const ModalAddListImages = (props) => <AddListImages {...props} />;

export const ModalAddListOffer = (props) => <AddListOffer {...props} />;

export const ModalCropImage = (props) => <CropImage {...props} />;
