/* eslint-disable max-len */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Box from '@material-ui/core/Box';
import Dinero from 'dinero.js';

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

const renderRowOptions = (options) => {
  const items = [];
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(options)) {
    items.push(
      <Box key={key} component="span" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
        <Box fontWeight="400" pl=".5em" style={{ wordBreak: 'break-all' }}>
          {`${key}:`}
          &nbsp;
        </Box>
        {typeof value === 'object' ? map(value, (v) => <Box>{`${v}`}</Box>) : <Box>{`${value}`}</Box>}
      </Box>,
    );
  }
  return items;
};

const ProductRow = ({
  reference, quantity, price, index, options, id,
}) => {
  const StyledTableCell = withStyles(() => ({
    root: {
      borderBottom: 0,
      border: 0,
      marginBottom: '-1px',
      marginRight: '-1px',
      padding: '0 !important',
      minHeight: '3em',
    },
    body: {
      fontSize: '1em',
    },
  }))(TableCell);


  const StyledTableRow = styled(TableRow)`
    height: 3em;
    border-bottom: ${(props) => (props.display ? '' : 'solid 1px black')};
  `;

  const [checked, setChecked] = useState(false);
  const [display, setDisplay] = useState(false);

  return (
    <>
      <StyledTableRow
        key={id}
        style={{ background: checked ? '#5bfcc2' : index % 2 === 0 ? 'white' : '#e2e2e2' }}
        display={display}
      >
        <StyledTableCell component="th" scope="row" onClick={() => setChecked(!checked)}>
          <Text paddingLeft=".5em" weight="bold">
            {reference}
          </Text>
        </StyledTableCell>
        <StyledTableCell align="right" onClick={() => setChecked(!checked)}>
          <Text weight="bold">
            {` x${quantity}`}
          </Text>
        </StyledTableCell>
        <StyledTableCell align="center">
          <AssignmentIcon style={{ width: '1em' }} onClick={() => setDisplay(!display)} />
        </StyledTableCell>
      </StyledTableRow>
      {
        display
        && (
          <>
            {
              options
              && (
                <TableRow
                  style={{ background: checked ? '#5bfcc2' : index % 2 === 0 ? 'white' : '#e2e2e2' }}
                  onClick={() => setChecked(!checked)}
                >
                  <StyledTableCell colSpan={3}>
                    {renderRowOptions(options)}
                  </StyledTableCell>
                </TableRow>
              )
            }
            <TableRow
              style={{ background: checked ? '#5bfcc2' : index % 2 === 0 ? 'white' : '#e2e2e2', borderTop: 'solid 1px #222428' }}
              onClick={() => setChecked(!checked)}
            >
              <StyledTableCell colSpan={3}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">

                  <Box fontWeight="400" pl=".5em">
                    Prix Unitaire :
                    &nbsp;
                  </Box>
                  <Box>
                    {price && `${Dinero({ amount: parseInt(price, 10) }).toFormat('0.00')} €`}
                  </Box>

                </Box>
              </StyledTableCell>
            </TableRow>
            <TableRow
              style={{ background: checked ? '#5bfcc2' : index % 2 === 0 ? 'white' : '#e2e2e2', borderBottom: 'solid 1px black' }}
              onClick={() => setChecked(!checked)}
            >
              <StyledTableCell colSpan={3}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                  <Box fontWeight="400" pl=".5em">
                    Prix Total :
                    &nbsp;
                  </Box>
                  <Box>
                    {price && `${Dinero({ amount: parseInt(price, 10) * parseInt(quantity, 10) }).toFormat('0.00')} €`}
                  </Box>
                </Box>
              </StyledTableCell>
            </TableRow>
          </>
        )
      }

    </>
  );
};

export default ProductRow;
