import React, { useEffect, useState } from 'react';

import * as d3 from 'd3';
import { forEach } from 'lodash';
import Dinero from 'dinero.js';

const d3Format = {
  hour: '%d %H:%M',
  month: '%B',
  day: '%a %d',
};

const buildgraph = (filterkey, type, legendActive, setColors) => {
  // {
  //   "date": "1581444000000",
  //   "card": 32635,
  //   "cash": 62538,
  // }, {
  //   "date": "1581433200000",
  //   "card": 32635,
  //   "cash": 62538,
  // },
  const margin = {
    top: 20, right: 20, bottom: 70, left: 40,
  };
  const width = 700 - margin.left - margin.right;
  const height = 300 - margin.top - margin.bottom;
  const x = d3.scaleBand().rangeRound([0, width - 100], 0.05).padding(0.1);
  const y = d3.scaleLinear().range([height, 0]);

  // set the colors
  const z = d3.scaleOrdinal()
    .range(d3.schemeSet2);

  const xAxis = d3.axisBottom()
    .scale(x)
    .tickFormat(d3.timeFormat(d3Format[filterkey.format]));

  const yAxis = d3.axisLeft()
    .scale(y)
    .ticks(10);
  const svg = d3.select(`#${type}-graph`).append('svg')
    .attr('class', `${type}-graph`)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('width', width - 100 + margin.left + margin.right)
    .attr('height', height - 100 + margin.top + margin.bottom)
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Prep the tooltip bits, initial display is hidden
  const g = svg.append('g').attr('class', 'bar');
  const tooltip = d3.select('#tooltip-order');

  svg.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${height})`)
    .call(xAxis)
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-.8em')
    .attr('dy', '-.55em')
    .attr('transform', 'rotate(-90)');

  svg.append('g')
    .attr('class', 'y axis')
    .call(yAxis)
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 6)
    .attr('dy', '.71em')
    .style('text-anchor', 'end')
    .text('Value ($)');

  function update(data, unit, format) {
    // Prep the tooltip bits, initial display is hidden
    const keys = [];
    // eslint-disable-next-line
    for (const key in data) {
      forEach(data[key], (v, k) => {
        if (k !== 'date' && !keys.includes(k)) {
          keys.push(k);
        }
      });
    }


    data.forEach((d) => {
      d.total = 0;
      keys.forEach((k) => {
        if (d[k] !== undefined) d.total += d[k];
      });
    });
    data.sort((a, b) => a.date - b.date);

    x.domain(data.map((d) => d.date));
    y.domain([0, d3.max(data, (d) => d.total)]).nice();
    z.domain(keys);

    const stacked = d3.stack().keys(keys)(data);

    g.selectAll('g')
      .remove()
      .exit()
      .data(data);

    g.append('g')
      .selectAll('g')
      .data(stacked)
      .enter()
      .append('g')
      .attr('fill', (d) => z(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.data.date))
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth())
      .on('mouseover', function (d) {
        d3.select(this)
          .style('opacity', 0.6);
        tooltip.transition()
          .duration(200)
          .style('opacity', 1);
        console.log('HERE', d[1] - d[0]);
        const name = unit === 'euro' ? `${unit}: ${Dinero({ amount: Math.round(d[1] - d[0]) }).toFormat('0.00')} €` : d[1] - d[0];

        tooltip
          .html(name)
          .style('left', `${d3.event.pageX - 20}px`)
          .style('top', `${d3.event.pageY - 28}px`);
      })
      .on('mouseout', function () {
        d3
          .select(this)
          .style('opacity', 1);
        tooltip
          .transition()
          .duration(200)
          .style('opacity', 0);
      });

    svg.selectAll('#legend')
      .remove()
      .exit()
      .data(data);


    if (legendActive) {
      const legend = svg.append('g')
        .attr('id', 'legend')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .attr('text-anchor', 'end')
        .attr('transform', 'translate(20, -10)')
        .selectAll('g')
        .data(keys.slice().reverse())
        .enter()
        .append('g')
        .attr('transform', (d, i) => `translate(0,${i * 20})`);


      legend.append('rect')
        .attr('x', width - 19)
        .attr('width', 19)
        .attr('height', 19)
        .attr('fill', z);
      legend.append('text')
        .attr('x', width - 24)
        .attr('y', 9.5)
        .attr('dy', '0.32em')
        .text((d) => d);
    } else {
      setColors(keys.slice().reverse().map((k) => ({
        color: z(k),
        name: k,
      })));
    }

    // left axis
    svg.select('.y')
      .call(yAxis);

    xAxis.tickFormat(d3.timeFormat(d3Format[format]));

    svg.select('.x')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', () => 'rotate(-65)');
  }

  return update;
};

export default ({
  data, filterKey, type, unit, legendActive = true, setColors = null,
}) => {
  const [graphUpdate, setgraphUpdate]: any = useState();

  useEffect(() => {
    const returnFn = buildgraph(filterKey, type, legendActive, setColors);
    setgraphUpdate(() => returnFn);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (graphUpdate && data) {
      graphUpdate(data, unit, filterKey.format);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div id={`${type}-graph`}>
      <div
        id="tooltip-order"
        style={{
          top: 0,
          opacity: 0,
          position: 'absolute',
          textAlign: 'left',
          width: '5vw',
          height: '3vh',
          padding: '5px',
          font: '12px sans-serif',
          background: 'lightsteelblue',
          border: '0',
          borderRadius: '4px',
          pointerEvents: 'none',
          backgroundColor: 'white',
          color: 'grey',
          boxShadow: '0 0 5px 0 rgba(133,133,133,0.38)',
        }}
      />
    </div>
  );
};
