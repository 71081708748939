import React from 'react';
import CreateButton from 'src/data/injector/CreateUploadButton';
import EditButton from 'src/data/injector/EditUploadButton';

export function EditToolbar({ id, resource, basePath = null }) {
  return (
    <>
      <div />
      <EditButton basePath={basePath} id={id} resource={resource} />
      <div />
    </>
  );
}
export function CreateToolbar({ basePath, resource }) {
  return (
    <>
      <div />
      <CreateButton resource={resource} basePath={basePath} />
      <div />
    </>
  );
}
