/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { findIndex, orderBy } from 'lodash';
// import moment from 'moment';
const countAudio = new Audio('/sounds/score.mp3');

export const useWatchPreparatorOrderCount = ({ entitieWatch }) => {
  let [count, setCount] = useState(0);

  useEffect(() => {
    // scor.src =
    const defaultFirestore = firebase.app();
    const subscribe = defaultFirestore
      .firestore()
      .collection('orders')
      .where(`status.${entitieWatch}`, '==', true)
      .orderBy('created_at', 'desc')
      .onSnapshot({}, async (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const doc = await change.doc.data();
          if (doc && change.type === 'added') {
            count++;
            countAudio.play();
            setCount(count);
          }
          if (doc && change.type === 'removed') {
            count--;
            setCount(count);
          }
        });
      }, (e) => console.log('error', e));

    return () => subscribe;
    // eslint-disable-next-line
  }, []);

  return count;
};

export const useWatchPreparatorOrder = ({
  entitieWatch, low, preparing, dateToShow = null,
}) => {
  const [data, setData] = useState([]);
  let limite = 6;
  if (low) {
    limite = 50;
  }
  const limiteDate = dateToShow || (Date.now() + (4 * 60 * 60 * 1000));

  useEffect(() => {
    const defaultFirestore = firebase.app();
    const subscribe = defaultFirestore
      .firestore()
      .collection('orders')
      .where('created_at', '<', limiteDate)
      .where(`status.${entitieWatch}`, '==', true)
      .orderBy('created_at', 'desc')
      .limitToLast(limite)
      .onSnapshot({}, async (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const doc = await change.doc.data();
          if (doc && change.type === 'added') {
            // filter for low dashboard first onglet
            if (low && !doc.parameters.deliveryTime) {
              data.push(doc);
            }
            // filter for normal dashboard
            if (!low && !preparing) {
              data.push(doc);
            }
            // filter for low dashboard sec onglet
            if (preparing && doc.parameters.deliveryTime) {
              data.push(doc);
            }
          }
          if (doc && change.type === 'removed') {
            const id = findIndex(data, (o) => o.key === doc.key);
            data.splice(id, 1);
          }
          if (doc && change.type === 'modified') {
            const id = findIndex(data, (o) => o.key === doc.key);
            if (low
              && doc.parameters.deliveryTime) {
              data.splice(id, 1);
            } else {
              data[id] = doc;
            }
          }
          setData([...data]);
        });
      }, (e) => console.log('error', e));

    return () => subscribe;
    // eslint-disable-next-line
  }, []);

  if (!low) {
    const dataMap = data.map((d) => {
      if (d.parameters.deliveryTime) {
        d.sortKey = parseInt(
          `${d.parameters.deliveryTime.split(':')[0]}
        ${d.parameters.deliveryTime.split(':')[1]}`,
          10,
        );
      }
      return d;
    });
    return orderBy(dataMap, ['sortKey'], ['asc']);
  }

  return data;
};
