import React from 'react';
import styled from 'styled-components';
import { Box, InputBase, Button } from '@material-ui/core';
import { Field, useFormState, useForm } from 'react-final-form';
import ReactSelect from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray } from 'react-final-form-arrays';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import ListProduct from 'src/cards/edit/components/form/steps/cardEdit/components/editBoxComponent/commons/product';

const IconButtonStyled = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(IconButton);

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
  position: absolute;
  bottom: -25px;
  white-space: nowrap;
  left: 0;
`;

const InputbaseWrapper = styled(InputBase)`
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: 'bold';
  font-size: '1em';
`;

const ListItem = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 12px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 1px 6px -1px rgba(0,0,0,0.2);
`;

const Label = styled.label`
  font-size: 0.8em;
`;

const options = [
  { value: 'input', label: 'Champs libre' },
  { value: 'select', label: 'Choix multiple' },
];


export const TypeOffer = ({ name, index, fields }) => {
  const keys = [];
  const form = useForm();
  const formState = useFormState();

  return (
    <Box display="flex" key={keys[index]} alignItems="center">
      <ListItem>
        <Box flex="0.25" padding=".5em" display="flex" flexDirection="column">
          <Label>Label du champs</Label>
          <Field
            name={`${name}.label`}
            render={({ input, meta }) => (
              <Box display="flex" flexDirection="column">
                <InputbaseWrapper
                  onChange={input.onChange}
                  defaultValue={input.value}
                  input={input}
                  meta={meta}
                />
                {meta.error && <ErrorField>{meta.error}</ErrorField>}
              </Box>
            )}
          />
        </Box>
        <Box flex="0.20" padding=".5em" display="flex" flexDirection="column" alignItems="center">
          <Label>Obligatoire</Label>
          <Field
            name={`${name}.required`}
            render={({ input }) => (
              <Checkbox
                color="primary"
                onChange={(e) => {
                  input.onChange(e.target.checked);
                }}
                checked={input.value}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            )}
          />
        </Box>
        <Box flex="0.4" padding=".5em" display="flex" flexDirection="row">
          <Box flex="0.7" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Box display="flex" flexDirection="column">
              <Label> Ajouter vos produits </Label>
            </Box>
            <Box margin=".5em 0">
              <ListProduct many name={`${name}.products`} />
            </Box>
            <FieldArray name={`${name}.options`}>
              {({ fields }) => (
                <>
                  {fields.map((name) => (
                    <Box display="flex" flex="0.6" alignItems="center" pb="10px">
                      <Field
                        name={`${name}.value`}
                        render={({ input, meta }) => (
                          <Box display="flex" flexDirection="column">
                            <InputbaseWrapper
                              onChange={input.onChange}
                              defaultValue={input.value}
                              input={input}
                              meta={meta}
                            />
                            {meta.error && <ErrorField>{meta.error}</ErrorField>}
                          </Box>
                        )}
                      />
                    </Box>
                  ))}
                </>
              )}
            </FieldArray>
          </Box>
          <Box flex="0.3" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" textAlign="center">
            <Label> Nombre de choix possible ? </Label>
            <Field
              name={`${name}.optionsNumber`}
              render={({ input, meta }) => (
                <InputbaseWrapper
                  onChange={(e) => {
                    if (e.target.value < 1) {
                      e.target.value = 1;
                      input.onChange(e);
                    }
                    input.onChange(e);
                  }}
                  defaultValue={input.value || 1}
                  input={input}
                  type="number"
                  min="1"
                  meta={meta}
                />
              )}
            />
          </Box>
        </Box>
        <Box flex="0.1" justifyContent="center" display="flex" alignItems="start">
          <IconButtonStyled
            onClick={() => {
              fields.remove(index);
            }}
          >
            <DeleteForeverIcon fontSize="large" />
          </IconButtonStyled>
        </Box>
      </ListItem>
    </Box>
  );
};
