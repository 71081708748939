import React from 'react';
import { FormWithRedirect, sanitizeEmptyValues, useTranslate } from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';

import arrayMutators from 'final-form-arrays';
import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
  TextAreaInputField,
  NumberInputField,
  ImagesField,
  SelectInputField,
  ArrayProduct,
} from 'src/form/components/field/index';
import { productsResource } from 'src/App';
import { useApplication } from 'src/data/hooks';
// import { SearchProductCashRegister } from 'src/cash-register';
import { PriceWithoutTax , PriceInEuro} from './PriceWithoutTax';
import { TabPanel } from './TabPanel';

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const options = [
  { value: '0.10', label: 'TVA 10%' },
  { value: '0.20', label: 'TVA 20%' },
  { value: '0.055', label: 'TVA 5.5%' },
];

const getTVAFromZelty = (tva: number) => {
  const index = options.findIndex((option) => Number(option.value) * 10000 === tva);
  return options[index];
};

export default function Form({ Toolbar, ...props }) {
  const translate = useTranslate();
  const [value, setValue] = React.useState('one');
  const optionsResource = productsResource.map((r) => ({ value: r, label: translate(`pos.menu.product.${r}`) }));
  const { data }: any = useApplication();

  const submit = (values) => {
    props.save(sanitizeEmptyValues(props.record, values));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fillValueFromCashRegister = (form) => (value) => {
    form.mutators.setValue('reference', value.name);
    // form.mutators.setValue('tag', { label: value.categoryProduct.name, value: value.categoryProduct.id });
    form.mutators.setValue('description', value.description);
    form.mutators.setValue('price', value.price);
    form.mutators.setValue('tva', getTVAFromZelty(value.tva));
    form.mutators.setValue('productIdCashRegister', value.id);
  };

  console.log(props);

  return (
    <FormWithRedirect
      subscription={defaultSubscription}
      keepDirtyOnReinitialize
      onSubmit={submit}
      key={props.version}
      initialValues={props.initialValues || props.record}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ form }) => (
        <>
          <MainScreen
            data_cy_title="title-create-product"
            data_cy_subtitle="subtitle-create-product"
            title="Produit"
            subtitle="Renseignez les informations de votre produit"
            flex={0.95}
          >
            <Box display="flex" flexDirection="column" padding="0 1em">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab value="one" label="Informations générales" />
                <Tab value="two" label="Détails du produit" />
              </Tabs>
              <TabPanel value={value} index="one">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Box flex="0.4">
                    {/* {props.hasCreate && <SearchProductCashRegister onChange={fillValueFromCashRegister(form)} />} */}
                    <TextInputField
                      required
                      name="reference"
                      label="Titre"
                      data_cy_input="input-name-product"
                    />
                    <SelectInputField
                      data_cy_select="select-tag-product"
                      label="Catégorie"
                      name="tag"
                      options={optionsResource}
                    />
                    {
                      data
                      && data.contractors
                      && (
                        <SelectInputField
                          data_cy_select="select-tag-product"
                          label="Fournisseur"
                          name="contractors"
                          options={data.contractors}
                          isMulti
                        />
                      )
                    }
                    <TextAreaInputField
                      required
                      name="description"
                      label="Description"
                      data_cy_input="input-description-product"
                    />
                    <NumberInputField
                      required
                      name="price"
                      label="Prix en centimes ( TTC )"
                      data_cy_number="input-price-product"
                    />
                    <PriceInEuro />
                    <SelectInputField
                      data_cy_select="select-tva-product"
                      label="TVA"
                      name="tva"
                      options={options}
                      required
                    />
                    <PriceWithoutTax />
                  </Box>
                  <Box flex="0.4">
                    <ImagesField
                      type="rectangle"
                      name="picture"
                      title="Image du produit"
                      subtitle="Sélectionnez une image pour votre produit"
                      onAccept={(picture) => form.mutators.setValue('picture', picture)}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index="two">
                <Box flex="1">
                  <ArrayProduct name="settingFields" label="Ajouter un champ" />
                </Box>
              </TabPanel>
            </Box>
          </MainScreen>
          <Box bgcolor="primary.main" flex={0.05} display="flex" justifyContent="space-between" alignItems="center">
            <Toolbar resource={props.resource} id={props.id} basePath={props.basePath} />
          </Box>
        </>
      )}
    />
  );
}
