import React from 'react';
import {
  List, Datagrid, TextField, ChipField,
  CreateButton,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingList from 'src/layout/List/Loading';

const UserTitle = () => <span>Utilisateurs</span>;

export const Action = ({ basePath }) => <Toolbar><CreateButton data-cy="create-user" basePath={basePath} /></Toolbar>;

const UserListLoaded = (props) => (
  <Datagrid {...props} hasBulkActions={false}>
    <TextField source="name" label="Nom" data-cy="item-list-user-name" />
    <TextField source="email" label="Mail" />
    <ChipField source="role" label="Rôle" />
  </Datagrid>
);

const VisitorList = (props, { loading }) => (
  <List
    data-cy="list-users"
    {...props}
    perPage={25}
    title={<UserTitle />}
    actions={<Action {...props} />}
  >
    {!loading ? (
      <UserListLoaded loading={props.loading} />
    ) : (
      <LoadingList {...props} />
    )}
  </List>
);

export default VisitorList;
