import Dinero from 'dinero.js';
import {
  forEach, map, get,
} from 'lodash';

const headersStripe = {
  product: 'PRODUITS'.replace(/,/g, ''),
  quantity: 'QUANTITÉ'.replace(/,/g, ''),
  ttc: 'TTC ( € )'.replace(/,/g, ''),
  ht: 'HT ( € )'.replace(/,/g, ''),
  tvaamount: 'TVA ( € )'.replace(/,/g, ''),
  tva: 'TVA ( % )'.replace(/,/g, ''),
};

const headerContractors = {
  productName: 'NOM',
  quantity: 'QUANTITÉ',
  contractors: 'FOURNISSEUR',
};

const reduceByProduct = (arr) => {
  const concatArray = {};
  forEach(arr, (product) => {
    const productValue = Object.values(product)[0];
    if (concatArray[Object.keys(product)[0]]) {
      concatArray[Object.keys(product)[0]].quantity += productValue.quantity;
      concatArray[Object.keys(product)[0]].ttc += productValue.ttc;
      concatArray[Object.keys(product)[0]].ht += productValue.ht;
      concatArray[Object.keys(product)[0]].tvaamount += productValue.tvaamount;
      concatArray[Object.keys(product)[0]].tva = productValue.tva;
    } else {
      concatArray[Object.keys(product)[0]] = {
        quantity: productValue.quantity,
        ttc: productValue.ttc,
        ht: productValue.ht,
        tvaamount: productValue.tvaamount,
        tva: productValue.tva,
      };
    }
  });
  const formatedArray = [];
  forEach(concatArray, (v, k) => {
    formatedArray.push({
      product: k.replace(/,/g, ''),
      quantity: parseInt(v.quantity, 10),
      ttc: Dinero({ amount: Math.round(v.ttc) }).toFormat('0.00'),
      ht: Dinero({ amount: Math.round(v.ht) }).toFormat('0.00'),
      tvaamount: Dinero({ amount: Math.round(v.tvaamount) }).toFormat('0.00'),
      tva: v.tva,
    });
  });

  return formatedArray;
};

export const getQuantity = (item) => (item.quantity ? item.quantity : item.number);

function toDineroFormat(item) {
  return {
    productName: item.productName,
    quantity: item.quantity,
    ttc: Dinero({ amount: Math.round(item.ttc) }).toFormat('0.00'),
    ht: Dinero({ amount: Math.round(item.ht) }).toFormat('0.00'),
    tvaamount: Dinero({ amount: Math.round(item.tvaamount) }).toFormat('0.00'),
    tva: item.tva,
  };
}

function getByContractors(orders, products) {
  const prod = {};
  orders.flatMap((order) => map(order.items, (item) => {
    if (item.type === 'product') {
      const product = products.find((p) => p.id === item.id);
      if (prod[item.reference]) {
        prod[item.reference].quantity += getQuantity(item);
        prod[item.reference].contractors = map(get(product, 'contractors'), (e) => e.label).join('|');
      } else {
        prod[item.reference] = {};
        prod[item.reference].quantity = getQuantity(item);
        prod[item.reference].contractors = map(get(product, 'contractors'), (e) => e.label).join('|');
      }
    }
  }));

  const stackedProd = map(prod, (value, key) => (
    {
      productName: key,
      quantity: value.quantity,
      contractors: value.contractors,
    }
  ));

  stackedProd.unshift(headerContractors);
  return stackedProd;
}

function getByProduct(data) {
  const totalExportStripe = {
    productName: ['TOTAL'.replace(/,/g, '')],
    quantity: 0,
    ttc: 0,
    ht: 0,
    tvaamount: 0,
    tva: '',
  };

  const tva20ExportStripe = {
    productName: `${['TVA 20.00%'.replace(/,/g, '')]}`,
    quantity: 0,
    ttc: 0,
    ht: 0,
    tvaamount: 0,
    tva: '',
  };

  const tva10ExportStripe = {
    productName: `${['TVA 10.00%'.replace(/,/g, '')]}`,
    quantity: 0,
    ttc: 0,
    ht: 0,
    tvaamount: 0,
    tva: '',
  };

  const tva055ExportStripe = {
    productName: `${['TVA 5.50%'.replace(/,/g, '')]}`,
    quantity: 0,
    ttc: 0,
    ht: 0,
    tvaamount: 0,
    tva: '',
  };

  const s = [];

  forEach(data, (item) => {
    forEach(item.items, (i) => {
      let totalProduct = 0;
      totalProduct = getQuantity(i) * i.price;
      const tvaamount = i.tva ? parseFloat(i.tva) * totalProduct : 0;
      const ht = i.tva ? totalProduct - parseFloat(i.tva) * totalProduct : totalProduct;

      totalExportStripe.quantity += getQuantity(i);
      totalExportStripe.ttc += totalProduct;
      totalExportStripe.ht += ht;
      totalExportStripe.tvaamount += tvaamount;

      if (i.tva === '0.20' || i.tva === '0.2') {
        tva20ExportStripe.quantity += getQuantity(i);
        tva20ExportStripe.ttc += totalProduct;
        tva20ExportStripe.ht += ht;
        tva20ExportStripe.tvaamount += tvaamount;
      }

      if (i.tva === '0.10' || i.tva === '0.1') {
        tva10ExportStripe.quantity += getQuantity(i);
        tva10ExportStripe.ttc += totalProduct;
        tva10ExportStripe.ht += ht;
        tva10ExportStripe.tvaamount += tvaamount;
      }

      if (i.tva === '0.055') {
        tva055ExportStripe.quantity += getQuantity(i);
        tva055ExportStripe.ttc += totalProduct;
        tva055ExportStripe.ht += ht;
        tva055ExportStripe.tvaamount += tvaamount;
      }

      s.push({
        [i.reference]: {
          quantity: getQuantity(i),
          ttc: totalProduct,
          ht,
          tvaamount,
          tva: `${parseFloat(i.tva) * 100}%` || 0,
        },
      });
    });
  });

  const stripe = reduceByProduct(s);

  stripe.unshift({});
  stripe.unshift(toDineroFormat(totalExportStripe));
  if (tva055ExportStripe.quantity > 0) stripe.unshift(toDineroFormat(tva055ExportStripe));
  if (tva10ExportStripe.quantity > 0) stripe.unshift(toDineroFormat(tva10ExportStripe));
  if (tva20ExportStripe.quantity > 0) stripe.unshift(toDineroFormat(tva20ExportStripe));
  stripe.unshift({});
  stripe.unshift(headersStripe);
  stripe.push({});
  stripe.push({});
  return stripe;
}

export const formatExportData = (data, products) => {
  const toExport = {
    stripe: getByProduct(data),
    contractors: getByContractors(data, products),
  };

  return toExport;
};
