import { get } from 'lodash';

export const TOP = 'top';
export const BOTTOM = 'bottom';

const getPosition = ({ Dragposition, currentPosition }) => {
  if (currentPosition === Dragposition) return false;
  if (currentPosition > Dragposition) return BOTTOM;
  if (currentPosition < Dragposition) return TOP;
};

export const dragInProgress = ({
  isDraggingComponent,
  id,
  position,
  direction,
}) => (get(isDraggingComponent, 'id') !== id
    && getPosition({
      Dragposition: get(isDraggingComponent, 'position'),
      currentPosition: position,
    }) === direction)
  || (direction === TOP
    && isDraggingComponent && isDraggingComponent.position === 'init');
