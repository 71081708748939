import { componentType } from '@bit/kards.kards-components.helpers';

import Carousel from './Carousel';
import Offer from './Offer';
import Product from './Product';
import SimpleList from './SimpleList';
import Separator from './Separator';
import Image from './Image';
import Video from './Video';
import Wheel from './Wheel';
import ImageSlides from './ImageSlides';
import Social from './Social';

export const getBox = (type) => {
  switch (type) {
    case componentType.Carousel:
      return Carousel;
    case componentType.Offer:
      return Offer;
    case componentType.Product:
      return Product;
    case componentType.List:
      return SimpleList;
    case componentType.Separator:
      return Separator;
    case componentType.Image:
      return Image;
    case componentType.Video:
      return Video;
    case componentType.Wheel:
      return Wheel;
    case componentType.Slide:
      return ImageSlides;
    case componentType.Social:
      return Social;
    default:
  }
};
