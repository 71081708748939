import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { linkToRecord } from 'ra-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { SpeedDialWithAction } from 'src/data/injector/SpeedDial';
import moment from 'moment';
import 'moment/min/locales';
import Status from '../Status';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 240,
    margin: '20px',
    boxShadow: '0 4px 16px rgba(0,0,0,.12)',
    position: 'relative',
  },
  button: {
    margin: theme.spacing(1),
  },
  media: {
    height: 160,
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  error: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    background: theme.palette.error.main,
  },
  success: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    background: theme.palette.success.main,
  },
}));

export const CardLinkPicture = ({
  data,
  basePath,
  resource,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} data-cy={`item-list-${data.id}`}>
      {data.status && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            margin: '10px',
          }}
        >
          <Status status={data.status} />
        </div>
      )}

      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <SpeedDialWithAction
          resource={resource}
          id={data.id}
        >
          <Box margin="20px" textAlign="center">
            Voulez vous vraiment supprimer cet element?
            <br />
            {resource === 'products'
              && 'Attention celà peut avoir un impact si le produit est présent dans l\'une de vos cartes'}
          </Box>
        </SpeedDialWithAction>
      </div>
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={linkToRecord(basePath, data.id)}
      >
        <CardMedia
          className={classes.media}
          image={get(data, 'edit.original')}
        />
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography gutterBottom variant="h6" component="h2">
              {data.name}
            </Typography>
            <Typography
              style={{ color: 'rgb(200,200,200)' }}
              variant="body2"
              component="p"
              gutterBottom
            >
              Dernière mise à jour le
              {' '}
              {moment(data.lastupdate).format('DD.MM.YY')}
            </Typography>
          </div>
        </CardContent>
      </Link>
    </Card>
  );
};
