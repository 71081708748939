/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  map, get, sortBy, forEach, isEmpty,
} from 'lodash';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.lettreSpacing};
  display: flex;
  justify-content: ${(props) => props.justifyContent};;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '95%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  container: {
    height: '92vh',
  },
  quantity: {
    width: '14%',
  },
  reference: {
    width: '72%',
  },
  status: {
    width: '14%',
  },
  options: {
    borderTop: 'solid 2px #222428',
    padding: '5px',
  },
  optionsEmptySlot: {
    padding: '5px',
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#222428',
    color: 'white',
    fontSize: '1.3em',
  },
  body: {
    height: '2em',
    fontSize: '1.3em',
  },
}))(TableCell);

const ProductsList = (props) => {
  const [products, setProducts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const sort = sortBy(get(props, 'data'), (i) => i.created_at);
    const a = [];
    let index = 0;
    forEach(sort, ({ items }) => {
      forEach(items, (item) => {
        item.index = index;
        a.push(item);
        index++;
      });
    });
    setProducts(a);
    // eslint-disable-next-line
  }, [get(props, 'data')]);
  return (


    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.quantity} align="center">
                <Text size=".8em" weight="300" justifyContent="center">
                  Quantité
                </Text>
              </StyledTableCell>
              <StyledTableCell className={classes.reference}>
                <Text size=".8em" weight="300" justifyContent="flex-start">
                  Référence
                </Text>
              </StyledTableCell>
              <StyledTableCell className={classes.status}>
                <Text size=".8em" weight="300" justifyContent="center">
                  Statut
                </Text>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              map(products, ({
                key, reference, number, index, options,
              }) => (
                <ProductRow
                  key={key}
                  reference={reference}
                  number={number}
                  options={options}
                  index={index}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const renderRowOptions = (options) => {
  const items = [];
  for (const [key, value] of Object.entries(options)) {
    items.push(
      <Box component="span" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
        <Text justifyContent="flex-start" weight="400" size=".8em">
          {`${key}: `}
          &nbsp;
        </Text>
        <Text justifyContent="flex-start" size=".8em">
          {`${value}`}
        </Text>
      </Box>,
    );
  }
  return items;
};

const ProductRow = ({
  reference, number, index, options,
}) => {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();
  return (
    <>
      <TableRow
        hover
        data-cy={`row-product-${index}`}
        style={{
          background: checked ? (index % 2 === 0 ? '#5bfcc2' : '#5edcb1') : index % 2 === 0 ? 'white' : '#e2e2e2',
        }}
        onClick={() => setChecked(!checked)}
      >
        <StyledTableCell className={classes.quantity} align="center">
          <Text weight="bold" justifyContent="center">
            {`x${number}`}
          </Text>
        </StyledTableCell>
        <StyledTableCell className={classes.reference} scope="row">
          <Text weight="300" justifyContent="flex-start" size="1.1em">
            {reference}
          </Text>
        </StyledTableCell>
        <StyledTableCell className={classes.status} align="center">
          <Text weight="bold" justifyContent="center">
            {checked ? 'PRÊT' : ''}
          </Text>
        </StyledTableCell>
      </TableRow>
      {
        options
        && !isEmpty(options)
        && (
          <TableRow
            hover
            style={{
              background: checked ? (index % 2 === 0 ? '#5bfcc2' : '#5edcb1') : index % 2 === 0 ? 'white' : '#e2e2e2',
            }}
            onClick={() => setChecked(!checked)}
          >
            <StyledTableCell className={classes.optionsEmptySlot} colSpan={1} />
            <StyledTableCell className={classes.options} colSpan={1}>
              {options && renderRowOptions(options)}
            </StyledTableCell>
            <StyledTableCell className={classes.optionsEmptySlot} colSpan={1} />
          </TableRow>
        )
      }
    </>
  );
};

export default ProductsList;
