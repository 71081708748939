import frenchMessages from 'ra-language-french';

export default {
  ...frenchMessages,
  autosave_in_progress: 'Enregistrement en cours...',
  notification: {
    uploadInProgress: 'Upload de vos images en cours ...',
  },
  card: {
    form: {
      errors: {
        unicity: 'Une carte est déjà publiée avec le même creneaux horaire.',
      },
    },
  },
  user: {
    created: 'Utilisateur créé.',
    error: "Une erreur s'est produite!",
    errorPassword: 'Mot de passe trop court.',
    errorMail: 'Le format du mail est incorrect.',
    errorMailExiste: 'Le mail est déjà utilisé.',
  },
  pos: {
    menu: {
      user: 'Utilisateurs',
      dashboard: 'Tableau de bord',
      images: 'Galerie d\'image',
      offer: 'Formules',
      card: 'Cartes',
      application: 'Mon établissement',
      orders: 'Commandes',
      product: {
        product: 'Produits',
        mainCategory: 'Categorie',
        subCategory: 'Sous Categorie',
        main: 'Mes items',

        all: 'Tous',
        'delivery': 'Précommande',
        'alcool': 'Alcool',
        glocery: 'Epicerie',
        'red_wine': 'Vin Rouge',
        'white_wine': 'Vin Blanc',
        'pink_wine': 'Vin Rosé',
        soft: 'Softs',
        beer: 'Bières',
        aperitif: 'Apéritifs',
        shooters: 'Shooters',
        cocktail: 'Cocktails',
        wine: 'Vins',
        hot: 'Boissons chaudes',
        spirit: 'Digestifs & Spiritueux',
        tapas: 'Tapas',
        starter: 'Entrées',
        dish: 'Plats',
        cheese: 'Fromages',
        desert: 'Desserts',
      },
    },
  },
  resources: {
    products: {
      tabs: {
        image: 'Ajouter une image a votre produit',
        details: 'Preciser les details sur ce produit',
        description: 'Ajouter une description',
      },
      empty: 'Vous n\'avez pas encore de produit.',
      invite: 'Créer votre premier produit.',
    },
    cards: {
      empty: 'Vous n\'avez pas encore de carte.',
      invite: 'Créer votre première carte.',
    },
    images: {
      empty: 'Vous n\'avez pas encore d\'image.',
      invite: 'Créer votre première image.',
    },
  },
  cashRegister: {
    choiseTitle: 'Choisissez votre caisse enregistreuse',
    noCashRegister: 'Pas de caisse enregistreuse',
    formTitle: 'Entrez vos cle de votre caisse',
  },
};
