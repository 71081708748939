import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const slides = {
  icone: '/img/components/slides.svg',
  explain: '/img/components/explain/slides.png',
  description: "Ce composant permet d'ajouter un slide d'images",
  nameId: "Slide d'images",
  type: 'slide',
  renderEdit,
};

export default slides;
