import React from 'react';
import styled from 'styled-components';
import { Box, InputBase, TextareaAutosize } from '@material-ui/core';

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
`;

const StyledInputField = styled(InputBase)`
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: bold;
  font-size: 1em;
`;


const TextareaAutosizeStyled = styled(TextareaAutosize)`
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: bold;
  font-size: 1em;
  font: inherit;
  color: currentcolor;
`;

export const Text = ({ ...props }) => (
  <Box display="flex" flexDirection="column" height="3.5em">
    <StyledInputField
      type={props.type}
      disabled={props.disabled}
      defaultValue={props.input.value}
      value={props.input.value}
      data-cy={props.data_cy_input}
      onChange={props.input.onChange}
    />
    {props.meta.error && <ErrorField>{props.meta.error}</ErrorField>}
  </Box>
);

export const TextAreaInput = ({ ...props }) => (
  <Box display="flex" flexDirection="column" height="3.5em">
    <TextareaAutosizeStyled
      rowsMin={4}
      data-cy={props.data_cy_input}
      aria-label="maximum height"
      placeholder="Entre la description de votre produit"
      value={props.input.value}
      onChange={props.input.onChange}
      defaultValue={props.input.value}
    />
    {props.meta.error && <ErrorField>{props.meta.error}</ErrorField>}
  </Box>
);
