/* eslint-disable no-unused-vars */
import React, { createContext, useState } from 'react';
import arrayMove from 'array-move';
import { find, cloneDeep, get } from 'lodash';

import {
  deepUpdate, deepDrop, deepMove, deepRemove,
} from './function';

export const ContributionContext = createContext({});

function ContributionProvider({
  children, data, onChange, template,
}) {
  const [currentCategory, setCurrentCategory] = useState(get(template, 'name') === 'Vitrine' ? (data[0].id ? data[0].id : null) : null);
  const [currentItem, setCurrentItem] = useState();
  const [dragState, setDragState] = useState(null);

  // Action
  const updateComponent = (value, id) => {
    const { components } = find(data, (c) => c.id === currentCategory);
    deepUpdate(components, id, value);
    onChange(cloneDeep(data));

    setCurrentItem(null);
  };

  const removeComponent = (id) => {
    const { components } = find(data, (c) => c.id === currentCategory);
    deepRemove(components, id);
    setCurrentItem(null);
    onChange(cloneDeep(data));
  };

  const moveBydragAndDropCategory = (oldIndex, newIndex) => {
    onChange(arrayMove(data, oldIndex, newIndex));
  };

  // const addCategory = ({ name, picture }) => {
  //   const templateCategory = {
  //     name,
  //     picture,
  //     id: uuid(),
  //     components: [],
  //   };
  //   data.push(templateCategory);
  //   onChange(cloneDeep(data));
  // };

  const popCategory = (index) => {
    setCurrentCategory(data[0].id);
    data.splice(index, 1);
    onChange(cloneDeep(data));
  };

  const moveBydragAndDropComponent = ({ item, position }) => {
    const { components } = find(data, (c) => c.id === currentCategory);
    const { newUuid } = deepDrop({
      components,
      item,
      newPosition: { index: position },
    });
    onChange(cloneDeep(data));
    if (newUuid) {
      setCurrentItem(newUuid);
    }
  };

  const moveByArrowComponent = ({ id, position }) => {
    const { components } = find(data, (c) => c.id === currentCategory);
    deepMove({ components, id, position });
    onChange(cloneDeep(data));
  };

  const action = {
    // addCategory,
    setCurrentCategory,
    updateComponent,
    removeComponent,
    dropComponent: moveBydragAndDropComponent,
    moveComponent: moveByArrowComponent,
    setCurrentItem,
    resetCurrentItem: () => setCurrentItem(null),
  };

  const dnd = {
    dragState: setDragState,
    isDraggingComponent: dragState,
  };
  const value = {
    moveBydragAndDropCategory,
    popCategory,
    cardData: data,
    currentItem,
    currentCategory,
    action,
    dnd,
    template,
  };

  return (
    <ContributionContext.Provider value={value}>
      {children}
    </ContributionContext.Provider>
  );
}
export default ContributionProvider;
