/* eslint-disable max-len */
import React from 'react';
import { useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import { updateProductAndPublish } from '../accessor/updateProductAndPublish';

const UpdateStockProduct = ({ classes, product, setStock }) => {
  const formState = useFormState();

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    const {
      values: {
        unlimited,
        stockNumber,
        disponibility,
        preorder,
        instant,
      },
    } = formState;

    updateProductAndPublish({
      product,
      disponibility,
      preorder,
      instant,
      stock:
      {
        unlimited,
        stockNumber: parseInt(stockNumber, 10),
      },
      setStock,
    });
  };

  return (
    <Button
      variant="contained"
      type="submit"
      onClick={handleClick}
      className={classes}
      data-cy="btn-confirm"
    >
      Valider
    </Button>
  );
};

export default UpdateStockProduct;
