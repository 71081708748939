/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';
import { useLocation } from 'react-router';
import { menuItemClick } from 'src/redux/actions';
import { productsResource } from 'src/App';
import { AppState } from 'src/types';

import CardsIcon from '@material-ui/icons/MapOutlined';
import ProductsIcon from '@material-ui/icons/ShoppingBasket';
import ApplicationIcon from '@material-ui/icons/Settings';
import OfferIcon from '@material-ui/icons/MenuBook';
import OrdersIcon from '@material-ui/icons/Tablet';
import UsersIcon from '@material-ui/icons/People';
import ImagesIcon from '@material-ui/icons/ImageRounded';

import SubMenu from './SubMenu';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}


const useStyles: any = makeStyles((theme) => ({
  root: {
    background: '#585c65',
    borderLeft: 'solid 3px #5bfcc2',
    transition: 'border-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
}));

const SubMenuWithoutSvg: any = SubMenu;

const Menu = ({ onMenuClick, dense, logout }) => {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = useState({
    menuProduct: location.pathname.includes('/products'),
  });
  const [active, setActive] = useState(location.pathname + location.search);
  const translate = useTranslate();
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const dispatch = useDispatch();
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  useEffect(() => {
    setActive(location.pathname + location.search);
  }, [location]);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div data-cy="menu-item">
      {' '}
      <DashboardMenuItem
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/dashboard');
        }}
        sidebarIsOpen={open}
        classes={active === '/dashboard' ? classes : null}
      />
      <MenuItemLink
        data-cy="btn-nav-cards"
        to="/cards"
        primaryText={translate('pos.menu.card', {
          smart_count: 2,
        })}
        leftIcon={<CardsIcon />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/cards');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/cards' ? classes : null}
      />
      <MenuItemLink
        to="/products"
        primaryText={translate("pos.menu.product.product", {
          smart_count: 2,
        })}
        data-cy="btn-nav-products"
        leftIcon={<ProductsIcon style={{ color: '#5bfcc2' }} />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/products');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/products' ? classes : null}
      />
      <MenuItemLink
        to="/offer"
        data-cy="btn-nav-offer"
        primaryText={translate('pos.menu.offer', {
          smart_count: 2,
        })}
        leftIcon={<OfferIcon />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/offer');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/offer' ? classes : null}
      />
      <MenuItemLink
        to="/images"
        data-cy="btn-nav-images"
        primaryText={translate('pos.menu.images', {
          smart_count: 2,
        })}
        leftIcon={<ImagesIcon />}
        sidebarIsOpen={open}
        dense={dense}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/images');
        }}
        classes={active === '/images' ? classes : null}
      />
      <MenuItemLink
        to="/orders"
        data-cy="btn-nav-orders"
        primaryText={translate('pos.menu.orders', {
          smart_count: 2,
        })}
        leftIcon={<OrdersIcon />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/orders');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/orders' ? classes : null}
      />
      <MenuItemLink
        to="/users"
        data-cy="btn-nav-users"
        primaryText={translate('pos.menu.user', {
          smart_count: 2,
        })}
        leftIcon={<UsersIcon />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/users');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/users' ? classes : null}
      />
      <MenuItemLink
        to="/application/pwa"
        data-cy="btn-nav-app"
        primaryText={translate('pos.menu.application', {
          smart_count: 2,
        })}
        leftIcon={<ApplicationIcon />}
        onClick={(e) => {
          if (onMenuClick) onMenuClick(e);
          setActive('/application/pwa');
        }}
        sidebarIsOpen={open}
        dense={dense}
        classes={active === '/application/pwa' ? classes : null}
      />
      <div style={{ height: '6em' }} />
    </div>
  );
};

export default Menu;
