/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import {
  ListItem, ListItemIcon, ListItemText, ListItemAvatar, Box,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useRedirect, useTranslate } from 'react-admin';
import { map } from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';
import GetListProduct from 'src/data/injector/GetListProduct';
import { BoundedTextField } from 'src/layout/Search/Bar';

import { productsResource } from '../../../App';


const SelectWrapper = styled(Select)`
  width: 100%;
`;

const ListWrapper = styled(List)`
  overflow: scroll;
  width: 70%;
`;

const ListItemWrapper = styled(ListItem)`
  flex-direction: column;
  align-items: flex-end !important;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ListItemIconWrapper = styled(ListItemIcon)`
  min-width: 0 !important;
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 1em;
`;

const WrapperSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: .4;
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  background: white;
  font-size: 1em;
  font-weight: 200;
  border: none;
  :focus {
    outline-width: 0;
  }
`;

const ListProduct = ({
  // eslint-disable-next-line
  handleToggle, products, filterProduct, selected
}) => map(
  filterProduct.length > 0 ? filterProduct : products,
  ({ reference = null, id = null, picture }) => {
    const labelId = `checkbox-list-label-${id}`;

    return (
      <ListItemWrapper data-cy="item-product-to-add" key={id} button onClick={() => handleToggle(id)}>
        <Row>
          <ListItemAvatar>
            <Avatar alt="image" src={picture} />
          </ListItemAvatar>
          <ListItemText id={labelId} primary={reference} />
          <ListItemIconWrapper>
            {id && selected && selected.indexOf(id) !== -1 ? (
              <CheckBox color="primary" />
            ) : (
                <CheckBoxOutlineBlankIcon color="disabled" />
              )}
          </ListItemIconWrapper>
        </Row>
        <Divider light width="93%" />
      </ListItemWrapper>
    );
  },
);

export const ListProducts = ({
  many, initialData, selected, setSelected,
}) => {
  const [tag, setTag] = useState();
  const [search, setSearch] = useState();
  const translate = useTranslate();
  const options = productsResource.map((r) => ({
    value: r,
    label: translate(`pos.menu.product.${r}`),
  }));
  options.push({
    value: 'all',
    label: 'Tous'
  })
  useEffect(() => { setSelected(initialData) }, [])

  const redirectTo = useRedirect();
  // eslint-disable-next-line
  const [error, setError] = useState();
  const handleToggle = (value) => {
    // value(type)
    if (many) {
      const currentIndex = selected.indexOf(value);
      const newChecked = [...selected];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelected(newChecked);
    } else {
      setSelected(value);
    }
  };

  return (
    <>
      <Box
        justifyContent="center"
        display="flex"
        alignItems="center"
        flexDirection="row"
        flex=".1"
      >
        <WrapperSearch>
          <SelectWrapper
            placeholder="Rechercher par categorie"
            options={options}
            onChange={(v) => {
              setSearch(null)
              setTag(v.value)
            }} />
        </WrapperSearch>
        <p style={{ margin: '0 10px' }}>ou</p>
        <WrapperSearch>
          <InputWrapper>
            <img alt="search" width="1.2em" src="/img/icon/search.svg" />
            <Input
              placeholder="Recherche par nom"
              data-cy="search"
              onChange={(e) => {
                setTag(null)
                setSearch(e.target.value)
              }}
            />
          </InputWrapper>
        </WrapperSearch>
      </Box>
      <Box
        overflow="scroll"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flex=".9"
      >
        <ListWrapper data-cy="list-products-to-add">
          <GetListProduct search={search} tag={tag} setTag={setTag} setSearch={setSearch} initialData={initialData} setError={setError}>
            <ListProduct handleToggle={handleToggle} selected={selected} many={many} redirectTo={redirectTo} />
          </GetListProduct>
        </ListWrapper>
      </Box>
    </>
  );
};
