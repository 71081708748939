import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/react';
import { GetOneOrder } from 'src/data/injector/GetOneOrder';
import { GetOneTable } from 'src/data/injector/GetOneTable';
import ListOrders from '../list/ListOrders';

const Container = styled.div`  
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const IonSegmentWrapper = styled(IonSegment)`
  width: 80%;
  margin: 1em 0;
`;

const IonSegmentButtonWrapper = styled(IonSegmentButton)`
  font-size: 1em;
  font-family: sans-serif;
  --color: #5bfcc2 !important;
  --color-checked: black !important;
`;

const ContentCategory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: .1;
  width: 100%;
`;
const ContentInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: .15;
  width: 100%;
`;
const ContentList = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex: .75;
  overflow: scroll;
  width: 90%;
`;

const SearchOrder = () => {
  const [search, setSearch] = useState();
  const [index, setIndex] = React.useState('table');
  const handleChange = (event) => {
    setIndex(event.detail.value);
  };
  return (
    <Container>
      <ContentCategory>
        <IonSegmentWrapper mode="ios" onIonChange={handleChange} value={index}>
          <IonSegmentButtonWrapper data-cy="btn-tab-table" value="table">
            <IonLabel>Table</IonLabel>
          </IonSegmentButtonWrapper>
          <IonSegmentButtonWrapper data-cy="btn-tab-number" value="order">
            <IonLabel>Commande</IonLabel>
          </IonSegmentButtonWrapper>
        </IonSegmentWrapper>
      </ContentCategory>
      <ContentInput>
        {index === 'table' && <GetOneTable setSearch={setSearch} />}
        {index === 'order' && <GetOneOrder setSearchById={setSearch} arrayMode />}
      </ContentInput>
      <ContentList data-cy="list-orders-search">
        <ListOrders data={search} />
      </ContentList>
    </Container>
  );
};

export default SearchOrder;
