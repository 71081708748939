import React from 'react';

import Edit from './Edit';

const renderEdit = ({ data }) => <Edit data={data} />;

const offer = {
  icone: '/img/components/offer.svg',
  explain: '/img/components/explain/product.svg',
  description: "Ce composant permet d'ajouter une offre",
  nameId: 'Formule',
  type: 'offer',
  init: { offer: null },
  renderEdit,
};

export default offer;
