/* eslint-disable max-len */
import React from 'react';
import { Create } from 'react-admin';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';
import { Back } from 'src/layout/Buttons';
import Form from '../components/form';
import { CreateToolbar } from '../components/bottomBar/index';

const ProductTitle = () => <span>Créer un produit</span>;

const defaultChecked = {
  monday: { midday: true, night: true },
  tuesday: { midday: true, night: true },
  wednesday: { midday: true, night: true },
  thursday: { midday: true, night: true },
  friday: { midday: true, night: true },
  saturday: { midday: true, night: true },
  sunday: { midday: true, night: true },
};

const defaultUnchecked = {
  monday: { midday: false, night: false },
  tuesday: { midday: false, night: false },
  wednesday: { midday: false, night: false },
  thursday: { midday: false, night: false },
  friday: { midday: false, night: false },
  saturday: { midday: false, night: false },
  sunday: { midday: false, night: false },
};

const ProductCreate = (props) => {
  const tag = useSelector((state) => state.tag);
  const id = uuid();
  return (
    <>
      <Back resource="/products" />
      <Create title={<ProductTitle />} {...props}>
        <Form
          {...props}
          initialValues={{
            tag,
            id,
            tva:"0.2",
            stock: { unlimited: true, stockNumber: 0 },
            instant: defaultChecked,
            preorder: defaultUnchecked,
          }}
          Toolbar={CreateToolbar}
        />
      </Create>
    </>
  );
};

export default ProductCreate;
