import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Field, useFormState, useForm } from 'react-final-form';
import { Box, Button, FormLabel } from '@material-ui/core';
import { forEach } from 'lodash';
import CheckboxMaterial from '@material-ui/core/Checkbox';
import { FieldArray } from 'react-final-form-arrays';
import { ModalCropImage, ModalAddListImages } from 'src/layout/Modal';

import { Tooltip } from 'src/layout/Tooltip';
import uuid from 'uuid/v4';
import { Text, TextAreaInput } from '../input/text';
import Cropper from '../input/cropper';
import { Number } from '../input/number';
import { Payment } from '../input/payment';
import { Debit } from '../input/debit';
import { ModeNoOrder } from '../input/orderMode';
import { PaimentActive } from '../input/paiementActive';

import { Delivery } from '../input/delivery';
import { Select } from '../input/select';
import { Switch } from '../input/switch';
import { InputTime } from '../input/time';
import { TypeProduct } from '../input/typeProduct';
import { TypeContractor } from '../input/typeContractor';
import { TypeOffer } from '../input/typeOffer';
import { required as fieldRequire } from '../../validator';

const StyledLabel = styled(FormLabel)``;

const ErrorField = styled.span`
  color: red;
  font-size: 0.9em;
`;

// FINAL FORM CONTAINER FINAL FORM
const FieldWithLabel = ({
  name,
  label,
  validate,
  children,
  pb = '5px',
  mb = '',
  labelWeight = '',
  labelSize = '',
  borderBottom = '',
  flex,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  ...props
}) => (
    <Box display="flex" flexDirection="column" pb={pb} mb={mb} borderBottom={borderBottom} width="auto" flex={flex}>
      <Box pb=".5em" display="flex" flexDirection="row" alignItems="center">
        {label && (
          <Box fontSize={labelSize} fontWeight={labelWeight}>
            {label}
          </Box>
        )}
        {tooltip && <Tooltip text={tooltipText} secondary={tootlipSecondary} />}
      </Box>
      <Field
        name={name}
        validate={validate && validate}
        render={({ input, meta }) =>
          React.cloneElement(children, {
            input,
            meta,
            ...props,
          })
        }
      />
    </Box>
  );

// FINAL FORM CONTAINER FINAL FORM
const FieldTypeWithLabel = ({
  type,
  name,
  label,
  validate,
  children,
  flex,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  pb = '10px',
  mb = '',
  ...props
}) => (
    <Box display="flex" flexDirection="column" pr="20px" pl="20px" pb={pb} mb={mb} width="auto" flex={flex}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {label && <StyledLabel>{label}</StyledLabel>}
        {tooltip && <Tooltip text={tooltipText} secondary={tootlipSecondary} />}
      </Box>
      <Field
        type={type}
        name={name}
        validate={validate && validate}
        render={({ input, meta }) => React.cloneElement(children, { input, meta, ...props })}
      />
    </Box>
  );

const uniqFilterAccordingToProp = function (prop, array) {
  const countArray = [];
  forEach(array, (val) => {
    if (val[prop]) countArray.push(val[prop]);
  });
  return countArray.length === new Set(countArray).size;
};

// FINAL FORM CONTAINER FINAL FORM
const FielArraydWithLabel = ({
  name,
  label,
  children,
  flex,
  pb = '10px',
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
}) => {
  const form = useForm();
  const formState = useFormState();
  const [status, setStatus] = useState(formState.values.status);
  const [slots, setSlots] = useState(formState.values.slots);

  useEffect(() => {
    setStatus(formState.values.status);
    setSlots(formState.values.slots);
  }, [formState.values]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      pr="0px"
      pl="0px"
      pb={pb}
      mb={mb}
      width="auto"
      borderBottom={borderBottom}
      flex={flex}
    >
      <Box>
        <Box fontWeight={labelWeight} fontSize={labelSize}>ACTIVEZ LA DIFFUSION DE VOTRE CARTE</Box>
        <br />
        {"(si vous ne disposez que d'une carte PROGRAMMÉ peut importe le creneaux renseigné, elle sera visible 24h/24)"}
        <br />
        <Field
          name="status"
          render={({ input }) => (
            <CheckboxMaterial
              color="secondary"
              checked={input.value === 'PLANNED' || input.value === 'PUBLISH'}
              inputProps={{
                'aria-label': 'primary checkbox',
                onChange: () => {
                  if (status === 'PUBLISH') {
                    if (input.value === 'PUBLISH') {
                      input.onChange('DRAFT');
                      form.mutators.setValue('slots', []);
                    } else {
                      input.onChange('PUBLISH');
                      form.mutators.setValue('slots', slots);
                    }
                  }
                  if (status === 'PLANNED') {
                    if (input.value === 'PLANNED') {
                      input.onChange('DRAFT');
                      form.mutators.setValue('slots', []);
                    } else {
                      input.onChange('PLANNED');
                      form.mutators.setValue('slots', slots);
                    }
                  }
                  if (status === 'DRAFT') {
                    if (input.value === 'PLANNED') {
                      input.onChange('DRAFT');
                    } else {
                      input.onChange('PLANNED');
                    }
                  }
                },
              }}
            />
          )}
        />
      </Box>

      {(formState.values.status === 'PLANNED' || formState.values.status === 'PUBLISH') && (
        <FieldArray
          name={name}
          validate={(values) => {
            const unique = uniqFilterAccordingToProp('start', values);
            if (!unique) {
              return 'Deux créneaux ne peuvent pas avoir le même horaire';
            }
            if (values && values.length === 0) return 'Ajouter au moins un creneaux';
          }}
        >
          {({ fields, meta: { error } }) => (
            <>
              <Box display="flex" flex="0.6" alignItems="center" pb="10px">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    form.mutators.push(name, {
                      start: '12:00',
                      end: '12:00',
                    });
                  }}
                >
                  {label}
                </Button>
              </Box>
              {fields.map((name, index) => React.cloneElement(children, { name, index, fields }))}
              {error && <ErrorField>{error}</ErrorField>}
            </>
          )}
        </FieldArray>
      )}
    </Box>
  );
};

const FieldWithButton = ({ name, validate, children, ...props }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding="10px">
    <Field
      name={name}
      validate={validate && validate}
      render={({ input, meta }) => React.cloneElement(children, { input, meta, ...props })}
    />
  </Box>
);

const FieldWithButtonNoFlex = ({ name, validate, children, ...props }) => (
  <Field
    name={name}
    validate={validate && validate}
    render={({ input, meta }) => React.cloneElement(children, { input, meta, ...props })}
  />
);

export const InputTimeField = ({
  required = null,
  validate = null,
  name,
  label,
  index = null,
  pb,
  mb = '',
  borderBottom = '',
  labelSize = '',
  labelWeight = '',
  ...props
}) => (
    <FielArraydWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      index={index}
      label={label}
      pb={pb}
      mb={mb}
      borderBottom={borderBottom}
      labelSize={labelSize}
      labelWeight={labelWeight}
      {...props}
    >
      <InputTime />
    </FielArraydWithLabel>
  );

export const CropperField = ({
  required = null,
  validate = null,
  name,
  label = null,
  index = null,
  flex = null,
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      index={index}
      label={label}
      flex={flex}
      {...props}
    >
      <Cropper />
    </FieldWithLabel>
  );

export const ImageProductField = ({
  original = false,
  required = null,
  validate = null,
  name,
  form = false,
  label = null,
  needKeepBase = false,
  children = null,
  newItem = false,
  add = null,
  subComponent = null,
  upload = true,
  type = 'card',
  description = null,
}) => (
    <FieldWithButtonNoFlex validate={required ? fieldRequire : validate} name={name} label={label}>
      <ModalCropImage
        originalLoaded={original}
        type={type}
        description={description}
        subComponent={subComponent}
        add={add}
        form={form}
        newItem={newItem}
        needKeepBase={needKeepBase}
        children={children}
        name={name}
        upload={upload}
      />
    </FieldWithButtonNoFlex>
  );

export const ImagesField = ({
  required = null,
  validate = null,
  onAccept = null,
  type,
  name,
  many = false,
  subComponent = null,
  title = null,
  subtitle = null,
  children = null,
}) => (
    <FieldWithButton validate={required ? fieldRequire : validate} name={name}>
      <ModalAddListImages
        onAccept={onAccept}
        name={name}
        title={title}
        subtitle={subtitle}
        many={many}
        subComponent={subComponent}
        type={type}
        children={children}
      />
    </FieldWithButton>
  );

export const TextInputField = ({
  required = null,
  validate = null,
  name,
  label,
  disabled = false,
  data_cy_input = null,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  type = 'text',
  pb = '5px',
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      props={props}
      tooltip={tooltip}
      tooltipText={tooltipText}
      tootlipSecondary={tootlipSecondary}
      pb={pb}
      mb={mb}
      labelWeight={labelWeight}
      labelSize={labelSize}
      borderBottom={borderBottom}
    >
      <Text disabled={disabled} data_cy_input={data_cy_input} type={type} />
    </FieldWithLabel>
  );

export const CheckboxField = ({ name, label, tooltip = false, tooltipText = null, tootlipSecondary = false }) => (
  <Box component="label" display="flex" flexDirection="row" alignItems="center">
    <Field name={name} type="checkbox">
      {(props) => (
        <CheckboxMaterial
          color="primary"
          checked={props.input.checked}
          name={props.input.name}
          value={props.input.value}
          onChange={props.input.onChange}
        />
      )}
    </Field>
    {label && label}
    {tooltip && <Tooltip text={tooltipText} secondary={tootlipSecondary} />}
  </Box>
);

export const TextAreaInputField = ({
  required = null,
  validate = null,
  name,
  label,
  data_cy_input = null,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      props={props}
      tooltip={tooltip}
      tooltipText={tooltipText}
      tootlipSecondary={tootlipSecondary}
    >
      <TextAreaInput data_cy_input={data_cy_input} />
    </FieldWithLabel>
  );

export const SelectInputField = ({
  options,
  required = null,
  validate = null,
  name,
  label,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  data_cy_select = null,
  isMulti = false,
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      data_cy_select={data_cy_select}
      tooltip={tooltip}
      tooltipText={tooltipText}
      tootlipSecondary={tootlipSecondary}
      props={props}
    >
      <Select isMulti={isMulti} options={options} />
    </FieldWithLabel>
  );

export const NumberInputField = ({
  required = null,
  validate = null,
  name,
  label,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  data_cy_number = null,
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      tooltip={tooltip}
      tooltipText={tooltipText}
      tootlipSecondary={tootlipSecondary}
      props={props}
    >
      <Number data_cy_number={data_cy_number} {...props} />
    </FieldWithLabel>
  );

export const PaimentInputField = ({
  required = null,
  validate = null,
  name,
  label,
  pb,
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      borderBottom={borderBottom}
      label={label}
      pb={pb}
      mb={mb}
      labelWeight={labelWeight}
      labelSize={labelSize}
      props={props}
    >
      <Payment />
    </FieldWithLabel>
  );

export const ActivePaimentInputField = ({ required = null, validate = null, name, label, pb, ...props }) => (
  <FieldWithLabel validate={required ? fieldRequire : validate} name={name} label={label} pb={pb} props={props}>
    <PaimentActive />
  </FieldWithLabel>
);

export const DebitInputField = ({
  required = null,
  validate = null,
  name,
  label,
  pb,
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      borderBottom={borderBottom}
      pb={pb}
      mb={mb}
      labelWeight={labelWeight}
      labelSize={labelSize}
      props={props}
    >
      <Debit />
    </FieldWithLabel>
  );

export const NoOrderInputField = ({
  required = null,
  validate = null,
  name,
  label,
  pb,
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      borderBottom={borderBottom}
      pb={pb}
      mb={mb}
      labelWeight={labelWeight}
      labelSize={labelSize}
      props={props}
    >
      <ModeNoOrder />
    </FieldWithLabel>
  );

export const DeliveryInputField = ({
  required = false,
  validate = null,
  name,
  label,
  pb,
  mb = '',
  borderBottom = '',
  labelWeight = '',
  labelSize = '',
  ...props
}) => (
    <FieldWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      borderBottom={borderBottom}
      label={label}
      pb={pb}
      mb={mb}
      labelWeight={labelWeight}
      labelSize={labelSize}
      props={props}
    >
      <Delivery />
    </FieldWithLabel>
  );

export const SwitchInputField = ({
  required = null,
  validate = null,
  name,
  label,
  tooltip = false,
  tooltipText = null,
  tootlipSecondary = false,
  data_cy_switch = null,
}) => (
    <FieldTypeWithLabel
      validate={required ? fieldRequire : validate}
      name={name}
      label={label}
      type="checkbox"
      tooltip={tooltip}
      tooltipText={tooltipText}
      tootlipSecondary={tootlipSecondary}
    >
      <Switch data_cy_switch={data_cy_switch} />
    </FieldTypeWithLabel>
  );


const FielArrayProduct = ({
  name, label, children, flex, errorText = 'Deux champs ne peuvent avoir le meme label',
}) => {
  const form = useForm();
  const validate = (values) => {
    const errors = '';
    if (values) {
      const labels = [];
      values.forEach((field) => {
        labels.push(field.label);
      });
      if (labels.length !== new Set(labels).size) {
        return errorText;
      }
    }
    return errors;
  };
  return (
    <Box display="flex" flexDirection="column" pr="0px" pl="0px" pb="10px" width="auto" flex={flex}>
      <FieldArray validate={validate} name={name}>
        {({ fields, meta: { error } }) => (
          <>
            <Box flexDirection="column" display="flex" flex="0.6" alignItems="center" p=".5em">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  form.mutators.push(name, {
                    label: 'Entrées',
                    type: 'input',
                  });
                }}
              >
                {label}
              </Button>
              {error && <ErrorField>{error}</ErrorField>}
            </Box>
            <Box overflow="scroll" padding=".1em" height="55vh">
              {fields.map((name, index) => React.cloneElement(children, { name, index, fields }))}
            </Box>
          </>
        )}
      </FieldArray>
    </Box>
  );
};

export const ArrayProduct = ({ name, label, index = null, ...props }) => (
  <FielArrayProduct name={name} index={index} label={label} {...props}>
    <TypeProduct />
  </FielArrayProduct>
);

const FielArrayContractor = ({ name, label, children, flex }) => {
  const form = useForm();

  return (
    <Box
      display="flex"
      flexDirection="column"
      pr="0px"
      pl="0px"
      pb="10px"
      width="100%"
      flex={flex}
    >
      <FieldArray name={name}>
        {({ fields, meta: { error } }) => (
          <>
            <Box flexDirection="column" display="flex" flex="0.6" alignItems="center" p=".5em">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  form.mutators.push(name, {
                    label: '',
                    id: uuid(),
                  });
                }}
              >
                {label}
              </Button>
              {error && <ErrorField>{error}</ErrorField>}

            </Box>
            <Box>
              {fields.map((name, index) => React.cloneElement(children, { name, index, fields }))}
            </Box>
          </>
        )}
      </FieldArray>
    </Box>
  );
};

export const ArrayContractor = ({
  name,
  label,
  index = null,
  ...props
}) => (
    <FielArrayContractor
      name={name}
      index={index}
      label={label}
      {...props}
    >
      <TypeContractor />
    </FielArrayContractor>
  );


export const ArrayProductOffer = ({
  name,
  label,
  index = null,
  ...props
}) => (
    <FielArrayProduct
      name={name}
      index={index}
      label={label}
      {...props}
    >
      <TypeOffer />
    </FielArrayProduct>
  );


// FINAL FORM CONTAINER FINAL FORM

export const FielMiddayNight = ({ name, label, pb = '10px', mb = '0px' }) => (
  <Box
    display="flex"
    flexDirection="row"
    pr="0px"
    pl="0px"
    pb={pb}
    mb={mb}
    width="100%"
    alignItems="center"
    justifyContent="flex-start"
    borderBottom="solid 2px #222428"
  >
    <Box flex=".1" fontSize="1.2em" fontWeight="400">
      {label}
    </Box>
    <Box height="3em" bgcolor="#5bfcc2" width="3px" />
    <Box flex=".9" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
      <InputTime name={`${name}.midday`} remove={false} label="Midi" />
      <Box height="3em" bgcolor="#5bfcc2" width="3px" />
      <InputTime name={`${name}.night`} remove={false} label="Soir" />
    </Box>
  </Box>
);

const FielArraySlotdWithLabel = ({ name, label, children, flex, pb = '10px' }) => {
  const form = useForm();
  const formState = useFormState();
  const [status, setStatus] = useState(formState.values.status);
  const [slots, setSlots] = useState(formState.values.slots);

  useEffect(() => {
    setStatus(formState.values.status);
    setSlots(formState.values.slots);
  }, [formState.values]);

  return (
    <Box display="flex" flexDirection="column" pr="0px" pl="0px" pb={pb} width="auto" flex={flex}>
      <Box>Gerer les creneaux de votre livraison à emporter</Box>
      <FieldArray
        name={name}
        validate={(values) => {
          const unique = uniqFilterAccordingToProp('start', values);
          if (!unique) {
            return 'Deux créneaux ne peuvent pas avoir le même horaire';
          }
        }}
      >
        {({ fields, meta: { error } }) => (
          <>
            <Box display="flex" flex="0.6" alignItems="center" pb="10px">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  form.mutators.push(name, {
                    start: '12:00',
                    end: '12:00',
                  });
                }}
              >
                {label}
              </Button>
            </Box>
            {fields.map((name, index) => React.cloneElement(children, { name, index, fields }))}
            {error && <ErrorField>{error}</ErrorField>}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

export const InputSloatField = ({ required = null, validate = null, name, label, index = null, pb, ...props }) => (
  <FielArraySlotdWithLabel
    validate={required ? fieldRequire : validate}
    name={name}
    index={index}
    label={label}
    pb={pb}
    {...props}
  >
    <InputTime />
  </FielArraySlotdWithLabel>
);
