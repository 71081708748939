import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { TextInputField } from '../field';

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-size: 1em;
  font-weight: bold;
`;

const Image = styled.img`
  width: 65px;
  height: 65px;
`;

const Description = styled.p`
  text-align: left;
`;

const ErrorField = styled.span`
  color: red;
  font-size: 0.9em;
`;

export function Delivery(props) {
  const [state, setState] = React.useState(props.input.value);
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    props.input.onChange({ ...state, [name]: event.target.checked });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box flex=".48" padding="1em">

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/waiter.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Checkbox
                  color="secondary"
                  checked={state.onplace}
                  onChange={handleChange('onplace')}
                  value="onplace"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Serveur</Label>
                <Description>
                  Sélectionnez ce mode pour que les utilisateurs puissent choisir le service à table.
                </Description>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex=".48" padding="1em">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding=".5em"
            >
              <ImageWrapper onClick={() => { }}>
                <Image src="/img/utils/location.svg" />
              </ImageWrapper>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
              <Box>
                <Checkbox
                  color="secondary"
                  checked={state.delivered}
                  onChange={handleChange('delivered')}
                  value="delivered"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
              <Box>
                <Label>Point de rendez-vous</Label>

                <Description>
                  Sélectionnez ce mode pour que les utilisateurs puissent choisir d'aller
                  chercher leur commande en point de retrait.
                </Description>
              </Box>
            </Box>
            {state.delivered
              && (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                  <TextInputField name="deliveryInstruction" label="Donner une indication a vos clients" data_cy_input="input-instruction-delivery" />
                  <TextInputField name="deliveryInstructionAdresse" label="Associer un lien maps ?" data_cy_input="input-instruction-delivery" />
                </div>
              )}
          </Box>
        </Box>
      </Box>
      {props.meta.error && <ErrorField>Selectionnez au moins un de ces éléments.</ErrorField>}
    </Box>
  );
}
