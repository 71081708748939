import React from 'react';
import styled from 'styled-components';
import { Fab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ModalConfirm, ModalDelete, ModalPublish, ModalAddListProducts, ModalStock, ModalAddListOffer, ModalSetting,
} from '../Modal';

export const TransparentBtn = styled(Fab)`
  box-shadow: none !important;
  background-color: transparent !important;
  width: inherit !important;
  color: white !important;
  justify-content: ${(props) => props.justifyContent} !important;
  padding: 0 !important;
`;

export const BorderBtn = styled.button`
  border: 1px solid rgb(200, 200, 200);
  color: #222428;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 1em;
`;

const BackBtn = styled(Link)`
  align-items: center;
  color: #222428;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: .2em;
  text-decoration: none;
  width: 10%;
  margin-top: 10px;
  font-weight: 500;
`;

const Nav = styled(NavigateBeforeIcon)`
  margin-right: 3px;
`;

export const Back = ({ resource }) => (
  <BackBtn to={resource} data-cy="back-button">
    <Nav />
    retour
  </BackBtn>
);

export const StockButton = ({
  onAccept,
  onClose = null,
  OpenButton = null,
  explain = null,
  textButton = 'Confirmer',
  ...props
}) => (
  <ModalStock
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
    IconConfirm={<DeleteIcon />}
    explain={explain}
    textButton={textButton}
    {...props}
  />
);

export const SettingOfferButton = ({
  onAccept,
  onClose = null,
  OpenButton = null,
  explain = null,
  textButton = 'Confirmer',
  ...props
}) => (
  <ModalSetting
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
    IconConfirm={<DeleteIcon />}
    explain={explain}
    textButton={textButton}
    {...props}
  />
);

export const DeleteButton = ({
  onAccept,
  onClose = null,
  OpenButton = null,
  explain = null,
  textButton = 'Supprimer',
}) => (
  <ModalDelete
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
    IconConfirm={<DeleteIcon />}
    explain={explain}
    textButton={textButton}
  >
    Voulez-vous vraiment supprimer cet élément ?
  </ModalDelete>
);

export const ConfirmButton = ({
  onAccept,
  onClose = null,
  OpenButton = null,
  explain = null,
  textButton = 'Confirmer',
  ...props
}) => (
  <ModalConfirm
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
    explain={explain}
    textButton={textButton}
    {...props}
  >
    Voulez-vous vraiment continuer ?
  </ModalConfirm>
);

export const LogoutButtonConfirme = ({
  onAccept,
  onClose = null,
  OpenButton = null,
  explain = null,
  textButton = 'Déconnexion',
  ...props
}) => (
  <ModalDelete
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
    IconConfirm={<PowerSettingsNewIcon />}
    explain={explain}
    textButton={textButton}
    {...props}
  >
    Voulez-vous vraiment vous déconnecter ?
  </ModalDelete>
);

export const PublishButtonConfirme = ({
  onAccept,
  onClose = null,
  OpenButton = null,
}) => (
  <ModalPublish
    onAccept={onAccept}
    onClose={onClose}
    OpenButton={<OpenButton />}
  >
    Voulez vous apporter des modifications à la carte actuellement publiée ?
  </ModalPublish>
);

export const UnPublishButtonConfirme = ({
  onAccept,
  onClose = null,
  OpenButton = null,
}) => (
  <ModalDelete
    onAccept={onAccept}
    onClose={onClose}
    textButton="Dépublier"
    OpenButton={<OpenButton />}
  >
    Etes vous sur de vouloir dépublier votre carte ?
  </ModalDelete>
);

export const AddListproducts = ({
  many,
  title,
  subtitle,
  initialData,
  onAccept,
  onClose = null,
  OpenButton = null,
}) => (
  <ModalAddListProducts
    many={many}
    title={title}
    subtitle={subtitle}
    onAccept={onAccept}
    initialData={initialData}
    onClose={onClose}
    OpenButton={<OpenButton />}
  />
);


export const AddListOffer = ({
  many,
  title,
  subtitle,
  initialData,
  onAccept,
  onClose = null,
  OpenButton = null,
}) => (
  <ModalAddListOffer
    many={many}
    title={title}
    subtitle={subtitle}
    onAccept={onAccept}
    initialData={initialData}
    onClose={onClose}
    OpenButton={<OpenButton />}
  />
);
