import React from 'react';
import styled from 'styled-components';

const STATUS_COLOR = {
  PUBLISH: '#5bfcc2',
  PLANNED: '#4287f5',
  DRAFT: '#FCB900',
};

const STATUS_TRADUCTION = {
  PUBLISH: 'Publié',
  DRAFT: 'Brouillon',
  PLANNED: 'Programmé',
};

const Status = styled.div`
  background: ${(props) => props.color};
  padding: 5px;
  font-size: 0.8em;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ status }) => (
  <Status color={STATUS_COLOR[status]}>{STATUS_TRADUCTION[status]}</Status>
);
