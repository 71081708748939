import React from 'react';
import styled from 'styled-components';
import { TextInputField } from 'src/form/components/field/index';

const EditWindow = () => (
  <>
    <FlexContainer>
      <TextInputField label="Url Facebook" name="facebook" />
      <TextInputField label="Url Twitter" name="twitter" />
      <TextInputField label="Url Instagram" name="instagram" />
    </FlexContainer>
  </>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
