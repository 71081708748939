import React from 'react';

import { DragSource } from 'react-dnd';

const source = {
  beginDrag(props) {
    props.dragState({ position: 'init' });
    return { type: props.type, initValue: props.initValue };
  },

  endDrag(props) {
    props.dragState(null);
  },
};

function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const DraggableComponent = ({
  children,
  connectDragSource,
  connectDragPreview,
  type,
}) => (
  connectDragPreview
    && connectDragSource
    && connectDragPreview(
      connectDragSource(
        <div className={`type-${type}`} style={{ flex: '1' }}>
          {children}
        </div>,
      ),
    )
);
export default DragSource('Component', source, collect)(DraggableComponent);
