/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useFormState } from 'react-final-form';

import { useRedirect, useNotify, useDataProvider } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TransparentBtn } from '../../layout/Buttons';
import { uploadToFirebase } from '../../helpers/base64';

export default ({ resource, basePath }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const formState = useFormState();

  const handleClick = async () => {
    if (!formState.valid && !loading) {
      return;
    }
    setLoading(true);
    const dataWithUrl = await uploadToFirebase({ data: formState.values });
    await dataProvider.create(resource, { data: dataWithUrl });
    setLoading(false);
    notify('ra.notification.created', 'info', {
      smart_count: 1,
    });
    redirectTo('list', basePath);
  };

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-create-resource">
      {loading
        ? (
          <>
            <CircularProgress
              color="inherit"
              size={18}
              thickness={5}
            />
            <span>Upload de vos images en cours</span>
          </>
        ) : <span>Enregistrer</span>}

    </TransparentBtn>
  );
};
