import React from 'react';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect, useDataProvider } from 'react-admin';
import { get } from 'lodash';
import { TransparentBtn, PublishButtonConfirme } from 'src/layout/Buttons';

export default ({
  id, resource, modal = false, basePath = null,
}) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const formState = useFormState();
  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    const settingFields = get(formState, 'values.settingFields');
    console.log('Log: handleClick -> settingFields', settingFields)
    if (settingFields && settingFields.length < 1) {
      formState.values.settingFields = null;
    }

    // eslint-disable-next-line consistent-return
    return dataProvider
      .update(resource, { id, data: formState.values })
      .then(() => {
        notify('ra.notification.updated', 'info', {
          smart_count: 1,
        });
        if (basePath) redirectTo('list', basePath);
      });
  };

  if (modal) {
    return (
      <PublishButtonConfirme
        onAccept={handleClick}
        OpenButton={(props) => (
          <TransparentBtn variant="extended" onClick={props.handleOpen} data-cy="btn-edit-resource">
            Enregistrer
          </TransparentBtn>
        )}
      />
    );
  }

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-edit-resource">
      Enregistrer
    </TransparentBtn>
  );
};
