/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { FormWithRedirect } from 'react-admin';
import { Box } from '@material-ui/core';
import MainScreen from 'src/layout/Screen/Main';
import {
  TextInputField,
  SelectInputField,
} from 'src/form/components/field';
import {
  ADMIN, PREPARATOR, PRODUCTOR, RUNNER,
} from 'src/helpers/role';
import { EditToolbar } from '../bottomBar/index';

const options = [
  { value: ADMIN, label: 'Admin' },
  { value: PREPARATOR, label: 'Préparateur' },
  { value: PRODUCTOR, label: 'Producteur' },
  { value: RUNNER, label: 'Runner' },
];

// export default ({ Toolbar, ...props }) => {
export default ({ ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  return (
    <FormWithRedirect
      key={props.version}
      render={() => (
        <form>
          <MainScreen
            data_cy_title="title-create-user"
            data_cy_subtitle="subtitle-create-user"
            title="Utilisateur"
            subtitle="Création d'un utilisateur"
            flex={1}
          >
            <TextInputField label="Mail" name="email" required data_cy_input="input-mail-user" />
            <TextInputField label="Nom" name="name" required data_cy_input="input-name-user" />
            <TextInputField
              label="Mot de passe"
              name="password"
              required
              data_cy_input="input-password-user"
            />
            <SelectInputField
              data_cy_select="select-role-user"
              label="Rôle"
              name="role"
              options={options}
              required
              tooltip
              tooltipText="Le rôle permet de déterminer à quelle application l'utilisateur aura acces"
            />
          </MainScreen>
          <Box
            bgcolor="primary.main"
            flex={0.05}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <EditToolbar
              setValue={setValue}
              value={value}
              record={props.record}
              id={props.id}
              basePath={props.basePath}
              setLoading={setLoading}
            />
          </Box>
        </form>
      )}
    />
  );
};
