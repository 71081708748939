/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ContributionContext } from 'src/cards/edit/components/context/contribution';
import ListCardComponents from './listCardComponents';
import Category from './category';
import Theme from './theme';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonAuto({ template }) {
  const {
    popCategory,
    moveBydragAndDropCategory,
  }: any = useContext(ContributionContext);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box position="static" color="secondary.main">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{ minWidth: '33%' }}
            label="Catégories"
            data-cy="btn-edit-box-categories"
            {...a11yProps(0)}
          />
          <Tab
            style={{ minWidth: '33%' }}
            label="Composants"
            data-cy="btn-edit-box-components"
            {...a11yProps(1)}
          />
          <Tab
            style={{ minWidth: '33%' }}
            label="Thèmes"
            data-cy="btn-edit-box-theme"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Category
          template={template}
          moveBydragAndDropCategory={moveBydragAndDropCategory}
          popCategory={popCategory}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListCardComponents />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          height="69vh"
          overflow="scroll"
          style={{ background: 'white' }}
        >
          <Theme />
        </Box>
      </TabPanel>
    </>
  );
}
