import React, { useContext } from 'react';
import { Skeleton } from '@material-ui/lab';
import Social from '@bit/kards.kards-components.social';
import { ThemeContext } from 'src/cards/edit/components/context/theme';

export default ({ data: { facebook, twitter, instagram } }) => {
  const { theme }: any = useContext(ThemeContext);

  if (!facebook && !twitter && !instagram) {
    return (
      <div style={{
        margin: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row',
      }}
      >
        <Skeleton variant="rect" style={{ margin: '1em 2em', width: '2em', height: '2em' }} />
        <Skeleton variant="rect" style={{ margin: '1em 2em', width: '2em', height: '2em' }} />
        <Skeleton variant="rect" style={{ margin: '1em 2em', width: '2em', height: '2em' }} />
      </div>
    );
  }
  return (
    <Social.component
      theme={theme}
      facebook={facebook}
      twitter={twitter}
      instagram={instagram}
    />
  );
};
