import React from 'react';
import MaterialModal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const ModalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 40vh;
  width: 40vw;
  border-radius: 5px;
`;
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    background: theme.palette.error.main,
  },
  success: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '70%',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Publish = ({
  onClose,
  onAccept,
  children,
  OpenButton,
  textButton = 'Publier',
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    return setOpen(false);
  };

  const handleAccept = () => {
    if (onAccept) onAccept();
    return setOpen(false);
  };

  return (
    <>
      {React.cloneElement(OpenButton, { handleOpen })}
      <MaterialModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <>
          <ModalWrapper>
            <Box>
              <img alt="smartphone" src="/img/utils/smartphone.svg" width="80px" />
            </Box>
            <Box margin="20px" textAlign="center">
              {children}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Button
                variant="contained"
                onClick={handleAccept}
                color="secondary"
                className={classes.success}
                data-cy="btn-publish"
              >
                {textButton}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClose}
              >
                Fermer
              </Button>
            </Box>
          </ModalWrapper>
        </>
      </MaterialModal>
    </>
  );
};
