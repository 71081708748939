import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const script = document.createElement('script');
script.src = '/kardsConfiguration/index.js';
document.head.appendChild(script);
script.onload = function () {
  // do stuff with the script
  // eslint-disable-next-line
  const App = require('./App');
  ReactDOM.render(<App.default />, document.getElementById('root'));
};
