/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import get from 'lodash/get';
import { getCurrentPublishedCard } from './getCurrentPublishedCard';
import publishCard from './publishCard';

export const updateOfferAndPublish = async ({
  preorder,
  instant,
  offer,
}) => {
  const productId = get(offer, 'id');
  offer.preorder = preorder || false;
  offer.instant = instant || false;

  const defaultFirestore = firebase.app();

  try {
    const pwa = await getCurrentPublishedCard();
    await defaultFirestore
      .firestore()
      .collection('offer')
      .doc(productId)
      .update(offer);

    if (get(pwa, 'cardId')) {
      await publishCard({ cardId: pwa.cardId });
    }
  } catch (e) {
    console.log(e)
  }
};
