import React from 'react';
import styled from 'styled-components';
import { TextInputField } from 'src/form/components/field/index';
import ListProduct from '../commons/product';

const EditWindow = () => (
  <FlexContainer>
    <TextInputField label="Titre" name="title" />
    <ListProduct
      many
      name="products"
      title="Configurer votre liste de produits"
      subtitle="Sélectionnez les produits que vous souhaitez afficher dans votre liste"
    />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export default EditWindow;
