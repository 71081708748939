import React from 'react';
import styled from 'styled-components';
import { Box, InputBase, InputAdornment } from '@material-ui/core';

const ErrorField = styled.span`
  color: red;
  font-size: 0.8em;
`;

const StyledInputField = styled(InputBase)`
  border: 1px solid hsl(0,0%,80%);
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: 'bold';
  font-size: '1em';
`;

export const Number = ({ ...props }) => (
  <Box display="flex" flexDirection="column" height="3.5em">
    <StyledInputField
      disabled={props.disabled || false}
      type="number"
      step=".025"
      data-cy={props.data_cy_number}
      value={props.input.value}
      defaultValue={props.input.value}
      onChange={props.input.onChange}
      endAdornment={<InputAdornment position="end" component={() => <p>{props.pixel ? 'px' : '€'}</p>} />}
    />
    {props.meta.error && <ErrorField>{props.meta.error}</ErrorField>}
  </Box>
);
