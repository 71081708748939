import React from 'react';
import { Create } from 'react-admin';
import { Back } from 'src/layout/Buttons';
import Form from '../components/form';

const UserTitle = () => <span>Créer un utilisateur</span>;

const UserCreate = (props: any) => (
  <>
    <Back resource="/users" />
    <Create title={<UserTitle />} {...props}>
      <Form />
    </Create>
  </>
);

export default UserCreate;
