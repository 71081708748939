import React from 'react';
import { useFormState } from 'react-final-form';
import { useRedirect, useNotify } from 'react-admin';
import { TransparentBtn } from 'src/layout/Buttons';
import createAuthenticatedUser from '../accessor/createUser';

const CreateUser = () => {
  const formState = useFormState();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    const {
      values: {
        email, name, role, password,
      },
    } = formState;
    createAuthenticatedUser({
      email,
      name,
      role,
      password,
      notify,
      redirectTo,
    });
  };

  return (
    <TransparentBtn variant="extended" onClick={handleClick} data-cy="btn-create-user">
      Créer
    </TransparentBtn>
  );
};

export default CreateUser;
