/* eslint-disable max-len */
import React from 'react';
import {
  Filter,
  List,
  Pagination,
  CreateButton,
} from 'react-admin';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { get } from 'lodash';
import DashboardIcon from '@material-ui/icons/MapOutlined';
import EmptyComponent from 'src/layout/Empty';
import { CardLink } from 'src/layout/List/Item';
import LoadingList from 'src/layout/List/Loading';
import SearchInput from 'src/layout/Search/Bar';

const useStyles: any = makeStyles(() => ({
  root: {
    alignItems: 'center',
    marginTop: 0,
    minHeight: '60px',
  },
}));

const FlexH = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 75vh;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7em;
  width: 100%;
`;

const CardsTitle = () => <span>Cartes</span>;

export const CardFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="title" alwaysOn />
    </Filter>
  );
};

export const Empty = ({ basePath }) => (
  <EmptyComponent
    basePath={basePath}
    titleText="Vous n'avez pas encore de carte."
    explainText="Créer votre première carte."
    Image={DashboardIcon}
  />
);

const PostPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} color="primary" {...props} />;

export const Action = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => (
  <Toolbar>
    {filters
        && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    <CreateButton basePath={basePath} data-cy="create-card" />
  </Toolbar>
);

const duplicateData = (data) => ({
  ...data,
  components: data.components,
  template: data.template,
  title: `${data.title} Copie`,
  theme: data.theme,
  status: 'DRAFT',
  slots: [],
  id: uuid(),
});

const GridList = (props) => {
  const {
    data, basePath, ids, resource,
  } = props;
  return (
    <FlexH data-cy="list-cards">
      {
        ids
          && ids.length > 0
          ? ids.map((id) => (
            <CardLink
              key={id}
              data={data[id]}
              basePath={basePath}
              title={data[id].title}
              picture={
                get(data[id], 'components[0].picture')
                  ? get(data[id], 'components[0].picture')
                  : ''
              }
              duplicateData={() => duplicateData(data[id])}
              resource={resource}
            />
          ))
          : (
            <NoData>
              Aucune carte
            </NoData>
          )
      }
    </FlexH>
  );
};

const ProductLoadableList = ({ loading = null, ...props }) => (!loading ? <GridList {...props} /> : <LoadingList {...props} />);

const CardList = (props) => (
  <List
    filters={<CardFilter />}
    perPage={25}
    pagination={<PostPagination />}
    empty={<Empty {...props} />}
    actions={<Action {...props} />}
    sort={{ field: 'id', order: 'ASC' }}
    title={<CardsTitle />}
    {...props}
  >
    <ProductLoadableList {...props} />
  </List>
);

export default CardList;
