import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ProductList from './list';
import ProductEdit from './edit';
import ProductCreate from './create';

export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  icon: ShoppingBasketIcon,
};
