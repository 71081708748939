import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {
  Box, Button, InputBase, FormLabel,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import uuid from 'uuid/v4';
import { templateType } from '@bit/kards.kards-components.helpers';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { ImagesField, TextInputField } from 'src/form/components/field';
import {
  DeleteButton,
} from 'src/layout/Buttons';

const ListItem = styled.li`
  align-items:center;
  background: white;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  margin: 5px;
  flex: 1;
  flex-direction: row;
  height: 3em;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
`;

const ImageWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: rgba(31, 38, 62, 0.2) 0px 0px 4px;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
`;

const PictureWrapper = styled.img`
  border-radius: 50%;
  box-shadow: rgba(31, 38, 62, 0.2) 0px 0px 4px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const DragZone = styled.div`
  height: 100%;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.1;
  :active {
    cursor: grabbing;
  }
`;

const PictureZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.2;
`;

const TitleZone = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonZone = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dots = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #222428;
  box-shadow: 0px -9px 0px #222428, 0px 9px 0px #222428;
`;

const StyledLabel = styled(FormLabel)`
  padding: 7px 2px !important;
`;

const StyledInputField = styled(InputBase)`
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 1px 10px;
  font-weight: bold;
  font-size: 1em;
`;

export const Text = ({ onChange, value }) => (
  <Box display="flex" flexDirection="column">
    <StyledInputField
      defaultValue={value}
      value={value}
      type="text"
      onChange={(e) => onChange(e.target.value)}
    />
  </Box>
);

const SortableItems = SortableContainer(({ children }) => (
  <ul
    style={{
      overflow: 'scroll',
      height: '70vh',
      padding: 0,
      margin: 0,
    }}
  >
    {children}
  </ul>
));

const DragHandle = SortableHandle(() => (
  <DragZone>
    <Dots />
  </DragZone>
));

const TitleField = (name) => () => {
  return (
    <TextInputField
      name={`${name}.name`}
      label="Choisissez le titre"
    />
  );
};

const TitleNew = ({ setName, name }) => (
  <Box
    display="flex"
    flexDirection="column"
    width="auto"
    flex="flex"
  >
    <StyledLabel>Choisissez le titre</StyledLabel>
    <Text
      onChange={setName}
      value={name}
    />
  </Box>
);

const Category = ({
  position, popCategory, name, itemsLength, template,
}) => (
  <ListItem>
    <DragHandle />
    <Field
      name={`${name}.picture`}
      render={({ input }) => (
        <PictureZone>
          <PictureWrapper
            src={input.value}
          />
        </PictureZone>
      )}
    />
    <Field
      name={`${name}.name`}
      render={({ input }) => (
        <TitleZone>
          <Typography>{input.value}</Typography>
        </TitleZone>
      )}
    />
    <ButtonZone>
      {itemsLength > 1 && (
      <DeleteButton
        onAccept={() => popCategory(position)}
        OpenButton={(props) => (
          <ImageWrapper
            style={{ marginRight: '10px' }}
            onClick={props.handleOpen}
          >
            <img alt="delete" width="10px" src="/img/icon/delete.svg" />
          </ImageWrapper>
        )}
      />
      )}
      <ImageWrapper>
        <ImagesField
          name={`${name}.picture`}
          type={template.imageType}
          title="Modifier une catégorie"
          subtitle="Ajouter un titre et une image à votre catégorie"
          subComponent={TitleField(name)}
        >
          <EditBtn />
        </ImagesField>
      </ImageWrapper>
    </ButtonZone>
  </ListItem>
);

const CategoryWithText = ({
  position, popCategory, name, itemsLength,
}) => (
  <ListItem>
    <DragHandle />
    <Field
      name={`${name}.name`}
      render={({ input }) => (
        <Text onChange={input.onChange} value={input.value} />
      )}
    />
    <ButtonZone>
      {itemsLength > 1 && (
      <DeleteButton
        onAccept={() => popCategory(position)}
        OpenButton={(props) => (
          <ImageWrapper
            style={{ marginRight: '10px' }}
            onClick={props.handleOpen}
          >
            <img alt="delete" width="10px" src="/img/icon/delete.svg" />
          </ImageWrapper>
        )}
      />
      )}
    </ButtonZone>
  </ListItem>
);

const SortableItem = SortableElement(
  ({
    position, popCategory, name, itemsLength, template,
  }) => (
    template.id === templateType.TopWithText ? (
      <CategoryWithText
        itemsLength={itemsLength}
        name={name}
        position={position}
        popCategory={popCategory}
      />
    ) : (
      <Category
        itemsLength={itemsLength}
        name={name}
        position={position}
        popCategory={popCategory}
        template={template}
      />
    )
  ),
);

const EditBtn = (props) => (
  <div onClick={props.handleOpen}>
    <img width="10px" alt="edit" src="/img/icon/edit.svg" />
  </div>
);

const CreateBtn = (props) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => props.handleOpen()}
  >
    Ajouter une catégorie
  </Button>
);

export default ({ template, popCategory, moveBydragAndDropCategory }) => {
  const [name, setName] = useState('');

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) moveBydragAndDropCategory(oldIndex, newIndex);
  };

  return (
    <>
      <FieldArray name="components">
        {({ fields }) => (
          <SortableItems
            useDragHandle
            pressDelay={150}
            helperClass="sortableHelper"
            axis="y"
            onSortEnd={onSortEnd}
          >
            {template.id === templateType.TopWithText
              ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                >
                  <CreateBtn handleOpen={
                    () => {
                      const id = uuid();
                      fields.push({ name, id, components: [] });
                    }
                  }
                  />
                  {' '}

                </div>
              ) : (
                <ImagesField
                  title="Ajouter une catégorie"
                  subtitle="Ajouter un titre et une image à votre catégorie"
                  name="new.picture"
                  type={template.imageType}
                  onAccept={(picture) => {
                    const id = uuid();
                    fields.push({
                      picture, name, id, components: [],
                    });
                  }}
                  subComponent={() => TitleNew({ setName, name })}
                >
                  <CreateBtn />
                </ImagesField>
              )}
            {fields.map((name, index) => (
              <SortableItem
                itemsLength={fields.length}
                index={index}
                template={template}
                position={index}
                key={name}
                name={name}
                popCategory={popCategory}
              />
            ))}
          </SortableItems>
        )}
      </FieldArray>
    </>
  );
};
