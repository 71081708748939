import React from 'react';
import { Create } from 'react-admin';
import uuid from 'uuid/v4';
import { Back } from 'src/layout/Buttons';
import { createToolbar } from '../edit/components/bottomBar';
import CardForm from '../edit/components/form';
import { S1 } from 'src/runner/flow';

const CardTitle = () => <span>Créer une carte</span>;

const initialData = {
  title: 'Titre',
  status: 'DRAFT',
  theme: {
    header: {
      logoSizeHeight: '20px',
      logoPaddingBottom: '5px',
      logoPaddingTop: '5px',
    },
    color: {
      title: 'dark',
      subtitle: 'dark',
      background: 'white',
      productTitle: 'dark',
      productDescription: 'medium',
      productPrice: 'warning',
      backgroundPrice: 'white',
      backgroundHeader: 'white',
      productBackground: 'white',
      buttonOrder: 'dark',
      backgroundFooter: 'white',
      buttonHeaderColor: 'dark',
    },
  },
  debit: 'hight',
  template: {
    name: 'Top',
  },
  id: uuid(),
  payment_mode: {
    card: true,
  },
  slots: [],
  components: [
    {
      id: uuid(),
      name: '',
      picture: 'https://www.mobilirebecca.it/11691-large_default/ecriture-decorative-a-suspendre-welcome-noir-pour-entre.jpg',
      components: [],
    },
  ],
  delivery: {
    onplace: true,
    delivered: false,
  },
};

const CreateComponent = (props) => (
  <>
    <Back resource="/cards" />
    <Create title={<CardTitle />} {...props}>
      <CardForm
        {...props}
        Toolbar={createToolbar}
        initialData={initialData}
        initialStep={1}
      />
    </Create>
  </>
);

export default CreateComponent;