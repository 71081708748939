import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { Box } from '@material-ui/core';


export const GetImagesList = ({ setSearch }) => {
  const defaultFirestore = firebase.app().firestore();

  useEffect(() => {
    defaultFirestore
      .collection('images')
      .get()
      .then((res) => {
        let docs = null;
        if (get(res, 'docs') && get(res, 'docs').length > 0) {
          docs = res.docs.map((d) => d.data());
          const sort = sortBy(docs, (i) => get(i, 'lastupdate.seconds')).reverse()
          setSearch(sort);
        } else {
          setSearch(null);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    // eslint-disable-next-line
  }, []);


  const onSearch = async (id) => {
    defaultFirestore
      .collection('images')
      .where('name', '>=', id)
      .where('name', '<=', `${id}\uf8ff`)
      .get()
      .then((res) => {
        let docs = null;
        if (get(res, 'docs') && get(res, 'docs').length > 0) {
          docs = res.docs.map((d) => d.data());
          setSearch(docs);
        } else {
          setSearch(null);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleChange = (event) => onSearch(get(event, 'target.value'));

  return (
    <Box display="flex" width="100%" justifyContent="center" alignItems="center" margin=".5em 0" flex=".1">
      <TextField
        onChange={handleChange}
        label="Rechercher.."
        variant="outlined"
        size="small"
      />
    </Box>
  );
};
