/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { values, reduce, forEach } from 'lodash';
import styled from 'styled-components';
import Dinero from 'dinero.js';
import Graph from './Graph';
import GraphWrapper from './GraphWrapper';
import { getQuantity } from './formatExportData';

const ContainerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: .5em;
  margin-right: .5em;
`;

const ContentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  background-color: white;
`;

const Title = styled.div`
  font-size: 1em;
  width: 90%;
  padding: .5em;
  font-weight: 400;
`;

const Number = styled.div`
  font-size: 3em;
  height: 70%;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  display: flex;
`;

const ReduceXY = (arr) => values(reduce(arr, (result, obj) => {
  result[obj.date] = {
    date: obj.date,
    cmd: obj.cmd + (result[obj.date] ? result[obj.date].cmd : 0),
  };
  return result;
}, {}));

const byNumberMode = (data, unit) => {
  let numberValues = 0;
  let price = 0;
  let numberCmd = 0;

  const Cards = {
    data: [],
  };
  forEach(data, (item) => {
    const created_at = new Date(item.created_at);
    created_at.setMinutes(0);
    created_at.setSeconds(0);
    created_at.setMilliseconds(0);
    numberCmd++;
    let cmd = 0;
    forEach(item.items, ((i) => {
      price += getQuantity(i) * i.price;
      if (unit === 'euro') {
        cmd += getQuantity(i) * i.price;
        numberValues += getQuantity(i) * i.price;
      }
    }));
    if (unit === 'number') {
      cmd++;
      numberValues++;
    }
    Cards.data.push({ date: created_at.getTime(), cmd });
  });

  const reduce = ReduceXY(Cards.data);

  return { data: reduce, numberValues, average: Math.round(numberCmd ? price / numberCmd : 0) };
};
const GraphComponent = (props) => props.data && <Graph unit={props.unit} type="number" data={props.data} filterKey={props.filterKey} />;
const Informations = ({ total: { average, numberValues }, unit, nbCommande }) => (
  <ContainerInfo>
    <ContentInfo style={{ flex: '.48' }}>
      <Title>
        {'Commandes : '}
      </Title>
      <Number>
        {unit === 'euro' && numberValues ? `${Dinero({ amount: parseInt(numberValues, 10) }).toFormat('0.00')} €` : numberValues}
      </Number>

    </ContentInfo>
    <ContentInfo style={{ flex: '.48' }}>
      <Title>
        Panier moyen :
      </Title>
      {
        nbCommande
        && nbCommande > 0
        && (
          <Number>
            {average && `${Dinero({ amount: parseInt(average, 10) }).toFormat('0.00')} €`}
          </Number>
        )
      }
    </ContentInfo>
  </ContainerInfo>
);

const NbNewOrders = ({ orders, filterKey }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({

  });


  useEffect(() => {
    if (orders) {
      const { data, numberValues, average } = byNumberMode(orders, filterKey.unit);
      setData(data);
      setTotal({ numberValues, average });
    }
  }, [orders, filterKey]);

  return (
    <GraphWrapper
      unit={filterKey.unit}
      informationPosition="right"
      title="Résumé du nombre de commandes"
      graph={GraphComponent}
      data={data}
      total={total}
      filterKey={filterKey}
      informations={Informations}
    />
  );
};

export default NbNewOrders;
